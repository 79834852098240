import validate from "validate.js";
import moment from "moment";

const message = '^Some required information is missing or incomplete. Please correct your entries and try again.'

validate.extend(validate.validators, {
    // name: (value) => {
    //     if (value !== null || value !== '') {
    //         return null
    //     }
    //     else if (value && !/[A-Za-z]/.test(value)) {
    //         return "enter a valid ";
    //     }
    //     else {
    //         return null;
    //     }
    // },
    date: (value) => {
        if (value !== null || value !== '') {
            return null
        }
        else if (value && moment(value, 'MM-DD-YYYY')) {
            return "enter a valid ";
        }
        else {
            return null;
        }
    },
    boolean: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/^(true|false)$/.test(value)) {
            return "^Enter a valid ";

        }
        else {
            return null;
        }
    },

})
export const validationSchema = {
    CGLOInsuranceStart: {
        presence: {
           allowEmpty: false,
            message: message
        },
        date: true
    },
    CGLOInsuranceEnd: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    CGLAInsuranceStart: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    CGLAInsuranceEnd: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    IsCGLInsuredAnnually: {
        presence: {
           allowEmpty: false,
            message: message
        },
        boolean: true
    },
    MLOInsuredStart: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },

    MLOInsuranceEnd: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    MLAInsuranceStart: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    MLAInsuranceEnd: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    IsCMLInsuredAnnually: {
        presence: {
           allowEmpty: false,
            message: message
        },
        boolean: true
    },
    CLOInsuranceStart: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    CLOInsuranceEnd: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    CLAInsuranceStart: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    CLAInsuranceEnd: {
        presence: {
           allowEmpty: false,
           message: message
        },
        date: true
    },
    IsCLInsuredAnnually: {
        presence: {
           allowEmpty: false,
            message: message
        },
        boolean: true
    },
    MalpracticeCarrierName: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    ISMalpracticeInsured: {
        presence: {
           allowEmpty: false,
            message: message
        },
        boolean: true
    },
    CertifyField1: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    CertifyField2: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    CertifyField3: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    CertifyField4: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    AuthorizerName: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    AuthorizerTitle: {
        presence: {
           allowEmpty: false,
            message: message
        },
        // name: true
    },
    AuthorizerSignedAt: {
        presence: {
           allowEmpty: false,
           message: message
        }, 
    },
 
}

export const dataValidationSchema = {
    CGLOInsuranceStart: {
        date: true
    },
    CGLOInsuranceEnd: {
        date: true
    },
    CGLAInsuranceStart: {
        date: true
    },
    CGLAInsuranceEnd: {
        date: true
    },
    IsCGLInsuredAnnually: {
        boolean: true
    },
    MLOInsuredStart: {
        date: true
    },

    MLOInsuranceEnd: {
        date: true
    },
    MLAInsuranceStart: {
        date: true
    },
    MLAInsuranceEnd: {
        date: true
    },
    IsCMLInsuredAnnually: {
        boolean: true
    },
    CLOInsuranceStart: {
        date: true
    },
    CLOInsuranceEnd: {
        date: true
    },
    CLAInsuranceStart: {
        date: true
    },
    CLAInsuranceEnd: {
        date: true
    },
    IsCLInsuredAnnually: {
        boolean: true
    },
    ISMalpracticeInsured: {
        boolean: true
    },
    AuthorizerSignedAt: {
        date: true
    },
 
}

