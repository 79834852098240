import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import Projects from '../../components/Projects';
import { getProjects } from '../../store/facilityProfiling/action';
import { getFullNameText } from '../../util/helper';
import images from '../../assets/img'
import Header from '../../layouts/Header';

const DashboardMain = (props) => {
    const { getProjects } = props;
    const userDetails = useSelector((state) => state.auth);
    const userName = getFullNameText(userDetails?.profile);
    const d = new Date();
    const m = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = weekday[d.getDay()];
    const month = m[d.getMonth()];
    const date = d.getDate();
    const dateDetails = (day + ", " + month + " " + date);
    const [warming, setWarming] = useState("");
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const currentDate = new Date();
        const gethours = currentDate.getHours();
        if (gethours >= 12 && gethours < 17) {
            setWarming("Afternoon")
        } else if (gethours >= 17 && gethours <= 24) {
            setWarming("Evening")
        } else {
            setWarming("Morning")
        }
    }, [])

    useEffect(() => {
        async function fetchdata() {
            const res = await getProjects({$isFacility: true});
            setProjects(res)
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
        fetchdata()
    }, [])

    return (<>
        <div className="doodle-data2"><img src={images.doodle2} alt="logo" /></div>
        <section className="header-section">
            <div className="container-fluid cst-fluid">
                <div className="main-header-form-fill">
                    <Header /> <div className="m-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h4 className="date-data">{dateDetails}</h4>
                                    <h2 className="main-data">Good {warming}, {userName}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <Projects list={projects && projects.data} loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

const mapStateToProps = state => ({
    //   projectList: state && state.projects.projects
});
const mapDispatchToProps = { getProjects };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMain); 