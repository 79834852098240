import React, { useState } from 'react'
import { useEffect } from 'react';
import MaskedInput from "react-text-mask";
import _ from "lodash";

const MaskedTextInput = ({ value, placeHolder, onClick, label, field, className, handleFieldChange, isrequired = false }) => {
  const [date, setDate] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const placeholdertext =` ${label}  ${isrequired ? "*" : ""}` 
  
  useEffect(() => {
    setDate(value)
  }, [value])

  const handleChange = e => { 
    let dateRegx = /^\d{2}\/\d{2}\/\d{4}$/; 
    if (e.target.value === "") {
      handleFieldChange(null)
      return
    } 
    if (!dateRegx.test(e.target.value) && new Date(e.target.value) == "Invalid Date") {
      return
    }  
    //  setDate(e.target.value)
    //  handleFieldChange(e.target.value)
  }

  // const onBlur = () =>{
  //   if (!moment(date, "DD/MM/YYYY").isValid()) { 
  //     handleFieldChange(null)  
  //   } 
  // }
  const onBlurInput = (e) => {  
    setIsFocus(false);
  };
  return (<>
    <MaskedInput
      onClick={onClick}
      value={date || ""}
      className={className}
      // onKeyDown={e => { handleChange(e) }} 
      onChange={e => { handleChange(e) }}
      mask={[
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      placeholder={(isFocus && _.isEmpty(value)) ? placeHolder : placeholdertext}
      onFocus={() => { setIsFocus(true) }}
      onBlur={(e) => onBlurInput(e)}
    />
    <label className={`label`}>{(isFocus || !_.isEmpty(value)) ? label : ""} <span className="rd">{((isFocus && isrequired) || !_.isEmpty(value)) ? "*" : ""}</span> </label>
  </>
  )
}

export default MaskedTextInput 