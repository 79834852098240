import { Icon } from "@iconify/react"
import TextField from "../Common/TextInput"
import React, { useEffect, useRef, useState,forwardRef, useImperativeHandle,  } from 'react';
import MessageBox from "../Common/MessageBox"; 
import { MessageType } from "../../util/enum";
import schema from "./schema";
import { validate } from "validate.js";
import { formatPhoneNumber } from "../../util/helper";
import { getFacilityOwners, saveFacilityOwners } from "../../store/facilityProfiling/action";
import { connect } from "react-redux";

const AddFacilityOwner  = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
           setOwnershipDetails
        };
    });
    const {saveFacilityOwners, projectDetails, getFacilityOwners, setSaveAutoCalled} = props;
    const initialValues = {
        ID:0,
        Name:"",
        Email:'',
        Phone:'',
        Rule:'',
        Percent:''
    }
    const [values, setvalues] = useState(initialValues)
    const [error, setError] = useState({})
    const [type, setType] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const iconRef = useRef()

    const validateData = () => {
        const errors = validate(values, schema)  
        setError(errors || {});
        let valid = errors ? false : true;
        if (!valid) {
            setType(MessageType.Error);
            setMessage(errors[Object.keys(errors)[0]][0]);
        } else {
            setError({});
        }
        return valid;
    } 

    function setOwnershipDetails(data) {
        setvalues(data)
    }

    const handleCancel = () => { 
        window.$("#add-facility-owner").modal('hide');
        setvalues(initialValues)
    }

    const handleSave = async () => {  
        let isValid = validateData(); 
        if (isValid) {
            setLoading(true)
            let data = {}; 
            data.ProjectFacilityId = projectDetails &&  projectDetails.ID 
            data.FName = values.Name.split(' ')[0]
            data.MName = values.Name.split(' ').length > 1 ? values.Name.split(' ')[1]:''
            data.LName = values.Name.split(' ').length > 2 ? values.Name.split(' ')[2]:''
            data.Title = values.Rule;
            data.Phone = values.Phone
            data.Percentage = values.Percent;
            data.Email = values.Email;
            if(values?.ID>0)
            {
                data.ID = values.ID;
            }
            
            await saveFacilityOwners(data)
            await getFacilityOwners(projectDetails &&  projectDetails.ID);
            iconRef.current.click();
            setMessage("Your changes have been successfully saved!")
            setType(MessageType.Success)
            setvalues(initialValues)
            setSaveAutoCalled(initialValues);
            setLoading(false)
        }
    }

    const handleChange = (field, value) => {
        if (field === "Phone") {
            let regex = /^[0-9\b]+$/;
            if (value && value.replace(/\D/g, "").toString().length > 10) {
              return;
            }
            setvalues((prev) => ({ ...prev, [field]: value && regex.test(value) ? value : value.replace(/\D/g, ""), }));
            return;
        }
        if (field === "Percent") {
            let regex = /^[0-9\b]+$/;
            if (Number(value)>100) {
              return;
            }
            setvalues((prev) => ({ ...prev, [field]: value && regex.test(value) ? value : value.replace(/\D/g, ""), }));
            return;
        }
        setvalues({ ...values, [field]: value })
    }


    return <><MessageBox message={message} setMessage={setMessage} type={type} /><div className="modal fade" id="add-facility-owner" tabIndex={"-1"} aria-labelledby="addfacilityownerLabel" data-bs-backdrop="static" >
        <div className="modal-dialog modal-small modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Facility Ownership</h4>
                    <div className="cls"> <Icon icon={"ic:round-close"} width={14} data-bs-dismiss="modal" /></div>
                </div>
                <div className="modal-body">
                    <div className="mb-0 pb-0">
                        <TextField
                             maxLength={100}
                            required={true}
                            type="text"
                            name="Name"
                            value={values.Name}
                            error={error && error.Name}
                            className="inputformdata form-control"
                            id="Name"
                            label="Name"
                            placeholder="Name "
                            onChange={(e) => handleChange("Name", e.target.value)}
                        />
                        <TextField
                            required={true}
                            type="text"
                            name="Phone"
                            value={values.Phone && formatPhoneNumber(values.Phone)}
                            // value={values.Phone && values.Phone.length === 10 ? formatPhoneNumber(values.Phone) : values.Phone}
                            error={error && error.Phone}
                            className="inputformdata form-control"
                            id="Phone"
                            label="Phone Number"
                            placeholder="Phone Number "
                            onChange={(e) => handleChange("Phone", e.target.value)}
                            maxLength={14}
                        />
                        <TextField
                             maxLength={100}     
                            required={true}
                            type="text"
                            name="Email"
                            value={values.Email}
                            error={error && error.Email}
                            className="inputformdata form-control"
                            id="email"
                            label="Email"
                            placeholder="Email "
                            onChange={(e) => handleChange("Email", e.target.value)}
                        />
                        <TextField
                            maxLength={100}
                            required={true}
                            type="text"
                            name="rule"
                            value={values.Rule}
                            error={error && error.Rule}
                            className="inputformdata form-control"
                            id="rule"
                            label="Role/Title"
                            placeholder="Role/Title "
                            onChange={(e) => handleChange("Rule", e.target.value)}
                        />
                        <TextField
                            maxLength={100}
                            required={true}
                            type="text"
                            name="percent"
                            value={values.Percent}
                            error={error && error.Percent}
                            className="inputformdata form-control"
                            id="percent"
                            label="Percent"
                            placeholder="Percent "
                            onChange={(e) => handleChange("Percent", e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" ref={iconRef} className="btn-cst-grey" onClick={()=>handleCancel()}> <Icon fontSize={35} icon={"ion:close-circle-outline"} />Cancel</button>
                    <button type="button" className="btn-cst-blue" onClick={() => !loading && handleSave()}> <Icon fontSize={35} icon={"carbon:save"} />{'  '}
                        Save</button>
                </div>

            </div>
        </div>
    </div></>
})


const mapDispatchToProps = { saveFacilityOwners, getFacilityOwners }
const mapStateToProps = (state) => ({ 
  projectDetails: state?.facility && state?.facility?.projectDetails || null 
})
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddFacilityOwner);