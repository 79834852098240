import client from "../../services/api-client";
import { CategoryType } from "../../util/enum";
import { errorHandler } from "../error/action";
import _ from "lodash";


export const onBoarding = (data, id, skipDispatch=false) => async (dispatch) => {
  try {
    let res = {}
    if (id) {
      res = await client.service("wms-project-facility").patch(id, data);

    }
    else {
      res = await client.service("wms-project-facility").create(data);
    }
    if (res) {
      if(!skipDispatch)
      {
        dispatch({
          type: "FACILITY_UPDATE",
          data: res,
        });
      }
      return res
    };
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};


export const getStates = () => async (dispatch) => {
  const query = {
    $limit: -1,
  };
  try {
    let res = {};
    res = await client.service("states").find({ query });
    if (res) {
      dispatch({
        type: "STATES",
        data: res,
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getQuestionsServices = (query = {}) => async (dispatch) => {
  try {
    let res = {};
    res = await client.service("wms-facility-profile-questions").find({ query: { $limit: -1, WorkFlowId: 1, ...query } });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getProjectCompletion = (ProjectId) => async (dispatch) => {
  try {
    let res = {};
    
    res = await client.service("wms-projectcompletionpercentage").find({ query: { ProjectId } });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getProjectId = (id,query={}) => async (dispatch) => {
  try {
    let res = {};
    res = await client.service("wms-projects").get(id,{query});
    if (res) {
      return res;
      
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getProjects = (queryOptions ={}) => async (dispatch) => {
  const query = {
    $sort: {
      LastModifiedAt: -1,
    },
    ...queryOptions
  }
  try {
    let res = await client.service("wms-projects").find({ query });
    if (res) {
      return res
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getProjectFaciltyByPId = (id) => async (dispatch) => {
  const query = {
    $limit: 1,
    ProjectId: Number(id)
  }
  try {
    if (id) {
      let res = await client.service("wms-project-facility").find({ query });

      if (res) {
        dispatch({
          type: "PROJECT_DETAILS",
          data: res,
        });
        return res
      }
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocumentCategories = () => async (dispatch) => {
  try {
    let res = await client.service("wms-doc-category").find({ query: { CategoryType:CategoryType["Onboarding Documents"]} });
    if (res) {
      dispatch({
        type: "DOC_CATEGORY",
        data: res,
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    let query = { $limit: "-1", IsActive: 1, DocCategoryId: { $in: [1,2,3,4,5,6] }, }
    let res = await client.service("wms-doc-types").find({ query: query });
    if (res) {
      dispatch({
        type: "DOC_TYPES",
        data: res,
      });
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const uploadDocument = (data, CompletionPercentage) => async (dispatch) => {
  try {
    let res;
    data.CompletionPercentage  = CompletionPercentage;
    if (data.ID > 0) {
      res = await client.service("wms-documents").patch(data.ID, { ...data });
    } else {
      res = await client.service("wms-documents").create(data);
    }
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocuments = (id) => async (dispatch) => {
  try {
    let res = await client.service("wms-documents").find({ query: { $limit: '-1', ProjectId: id } });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDoctypesExcluded = (id) => async (dispatch) => {
  try {
    let res = await client.service("wms-doctypesexcluded").find({ query: {$limit: '-1', ProjectId: id } });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const openDocument = (DocExtURL) => async (dispatch) => {
  try {
    let res = await client.service("wms-documents").find({ query: { DocExtURL, showDoc: true } });
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getFacilityOwners = (id) => async (dispatch) => {
  try {
    let res = await client.service("wms-facility-owner").find({ query: { ProjectFacilityId: id, $limit: '-1', } });
    if (res) {
      dispatch({
        type: "FACILITY_OWNERS",
        data: res
      });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveFacilityOwners = (owner) => async (dispatch) => {
  try {
    let res = {}
    if (owner.ID > 0) {
      res = await client.service("wms-facility-owner").patch(owner.ID, { ...owner });
    } else {
      res = await client.service("wms-facility-owner").create(owner);
    }
    if (res) {
      return true
    }
    return false
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const removeFacilityOwners = (ID) => async (dispatch) => {
  try {
    let res = {}
    if (ID > 0) {
      res = await client.service("wms-facility-owner").patch(ID, { Deleted: true });
    }
    if (res) {
      return true
    }
    return false
  } catch (error) {
    errorHandler(error);
    return false;
  }
}
export const removeProjectFacilityAnswers = (ID) => async (dispatch) => {
  try {
    if (ID) {
      let res = await client
        .service("wms-facility-profile-answers")
        .remove(ID)
      return res
    } else {
      return false
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const removeAncillaryService = (ID) => async (dispatch) => {
  try {
    if (ID) {
      let res = await client
        .service("wms-ancillary-services")
        .remove(ID)
      return res
    } else {
      return false
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const clear_form = () => async (dispatch) => {
  try {
    dispatch({
      type: "CLEAR_PROJECT_DETAILS",
      data: [],
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getOptions = (queryOptions = {}) => async (dispatch) => {
  try {
    let query = {
      ...queryOptions, $limit: -1
    }

    let res = await client
      .service("wms-facility-answer-options")
      .find({ query })
    if (res)
      return res || []
    else return []
  }
  catch (error) {
    errorHandler(error);
    return false;
  }
};

export const removeDocuments = (doc) => async (dispatch) => {
  try {
    let res = {};
    res = await client.service("wms-documents").remove(doc);
    if (res) {
      return res; 
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateProjectCompletionPercentage = (CompletionPercentage) => async (dispatch) => {
  try {
    if (CompletionPercentage && CompletionPercentage[0].ID > 0) {
      const data=_.pick({
        Percentage: (CompletionPercentage[0].Percentage) || 0,
        Tab:(CompletionPercentage[0].Tab) || 6,
        ProjectId:(CompletionPercentage[0].ProjectId) || null,
      }, [
        'Percentage',
        'Tab',
        'ProjectId'
      ])
    const res=  await client.service("wms-projectcompletionpercentage").patch(CompletionPercentage[0].ID, data);
    } 
    return true
  } catch (error) {
    errorHandler(error);
    return false;
  }
};