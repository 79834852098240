import _ from "lodash";
import React, { useState } from "react";

const Select = ({
    onChange,
    error,
    value,
    label,
    placeholder,
    options,
    disabled,
    className = "",
    labelclass = "",
    required=false,
    onBlur={onBlur}
}) => {
    return (
        <div className={error?'inputContainer redborder':'inputContainer'}> 
            <select
                style={{ fontSize: "14px" }}
                className={value ? "form-select inputformdata form-control  p-15" : error ? "inputformdata form-control form-select p-15" : "inputformdata form-control form-select p-15"} 
                error={error}
                value={value||""}
                onChange={onChange}
                onBlur={onBlur}
            >
                {placeholder && label && ( <option key={""} value={""} disabled hidden>{`${placeholder} ${required? "*":""}` || label}</option>)}
                {options &&
                    options?.map((item, index) => (
                        <option key={index} value={item.value || item?.ID }>
                            {item?.Name || item?.name}
                        </option>
                    ))}
            </select>
            <label htmlFor={label} className={`label`}>{(value==0 || value == null || value=="" ) ? "" : placeholder} <span className="rd">{required && value && "*"}</span> </label>   
                   </div >
    );

};

export default Select;
