import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { MessageType } from "../../../util/enum.js";
import { Link } from "react-router-dom";

const FormValidation = (props) => {
  const { type, message, setMessage , viewFields, onClick, invalidAlert} = props;
  useEffect(() => {
    if (type === MessageType.Success) {
      setTimeout(() => {
        setMessage(null);
      }, 6000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const handleClose = () => {
    setMessage(null);
  };

  if (type === MessageType.Success && message) {
    return (
      <div className="success_box alertbox_encounter">
        <Icon icon="clarity:success-standard-solid" fontSize={20}/>
        {message}
        <button onClick={handleClose}>
          <Icon className="clsbtn" icon="codicon:chrome-close" />
        </button>
      </div>
    );
  }
  if (type === MessageType.Error && message) {
    if (invalidAlert) {
      return (<div className="validation-message" style={{ display: "block" }} id="valid-msg">
        <div className="d-flex"><Icon className="me-1" icon="mdi:warning-circle-outline" fontSize={35} style={{ color: 'white' }} />
          <p className="mt-2">{message}</p></div> 
          <Link to="" onClick={handleClose} className="ms-5" id="skip-validmsg">Skip for now</Link>
       
      </div>
      );
    }
    return (<div className="validation-message" style={{ display: "block" }} id="valid-msg">
      <div className="d-flex"><Icon className="me-1" icon="mdi:warning-circle-outline" fontSize={45} style={{ color: 'white' }} /><p style={{ marginTop: 0 }}>{message}</p></div>
      {viewFields && <div className="text-center d-flex justify-content-center"> <button onClick={onClick} className="align-self-center"><span className="iconify" data-icon="material-symbols:view-agenda-outline"></span>
        {' '}View fields</button>
      </div>
      }
      <div className="text-center d-flex justify-content-end">
        <Link to="" onClick={handleClose} className="ms-3" id="skip-validmsg">Skip for now</Link>
      </div>

    </div>
    ); 
  }
  return <></>;
};

export default FormValidation;
