import { isEmpty, hasValue, validate } from "validate.js";

const message ="^Some required information is missing or incomplete. Please correct your entries and try again."

validate.validators.array = (arrayItems, itemConstraints) => {
    const arrayItemErrors = arrayItems && arrayItems.reduce((errors, item, index)=> {
        if(index === 0 && arrayItems.length === 1 && item && !hasValue(item)){
            return {}
        }

        const error = validate(item, itemConstraints);
        if(error)error[index] = error;
        return error;
    }, {});

    return isEmpty(arrayItemErrors)? null : arrayItemErrors;
};

export const validationSchema = {
    OwnerShipAnswers: {
        array: {
            Answers: {
                presence: { allowEmpty: false, message: message}
            },
        },
    },
}

export const dataValidationSchema = {
    OwnerShipAnswers: {
        array: {
            Answers: {
                presence: { allowEmpty: true}
            },
        },
    },
}

