import { Icon } from '@iconify/react';
import { Circle } from 'rc-progress';
import React, { useEffect, useRef, useState } from 'react';
import images from '../../assets/img';
import Header from '../../layouts/Header';
import Demographics from './Tabs/Demographics/demographics';
import Insurance from './Tabs/Insurance/insurance';
import Ownership from './Tabs/Ownership/ownership';
import Services from './Tabs/Services/services';
import Documents from './Tabs/Documents/documents';
import Payroll from './Tabs/Payroll/payroll';
import FormValidation from '../../components/Common/FormValidation';
import AddFacilityOwner from '../../components/AddFacilityOwner';
import Modal from '../../components/Common/CommonModal';
import { getFacilityOwners, getStates, removeFacilityOwners, getProjectFaciltyByPId, getDocumentTypes, getDocumentCategories, getProjectId, getProjectCompletion, updateProjectCompletionPercentage } from '../../store/facilityProfiling/action';
import { connect } from 'react-redux';
import { MessageType, ProjectStatus, HealthStatus } from '../../util/enum';
import { FacilityUserForm } from '../../util/enum';
import { useLocation, useNavigate } from 'react-router';
import PdfGeneration from './PdfGeneration/pdfgeneration';
import { onBoarding, removeDocuments } from '../../store/facilityProfiling/action';


const FacilityProfile = (props) => {

  const { removeFacilityOwners, getFacilityOwners, projectDetails, getProjectFaciltyByPId, getStates, getDocumentTypes, getDocumentCategories, onBoarding, getProjectId, getProjectCompletion, project, removeDocuments, updateProjectCompletionPercentage } = props;
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [viewfields, setViewFields] = useState(false);
  const [invalidAlert, setInvalidAlert] = useState(false);
  const [tab, setTab] = useState(0);
  const [deleteOwnerID, setDeleteOwnerID] = useState(0);
  const [deleteDocID, setDeleteDocID] = useState(0);
  const [displaydays, setDisplayDays] = useState(0)
  const [progressValue, setProgressvalue] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const [progressBarvalue, setProgressBarValue] = useState(3)
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const location = useLocation();
  const ProjectId = Number(location.pathname.split('/')[2])
  const navigate = useNavigate();
  const ownershipRef = useRef()
  const addOwnershipRef = useRef()
  const demographicsRef = useRef()
  const servicesRef = useRef()
  const insuranceRef = useRef()
  const payrollRef = useRef()
  const docRef = useRef()
  const [intervalId, setIntervalId] = useState(null);
  const [totalTabsHealth, setTotalTabsHealth] = useState(0)
  const [allocatedDays, setAllocatedDay] = useState(0)
  const [completedTab, setCompletedTab] = useState({
    Demographics: "",
    Services: "",
    OwnerShip: "",
    Insurance: "",
    Payroll: "",
    Documents: ""
  })
  const [payrollYesNoSection, setPayrollYesNoSection] = useState();
  const [saveAutoCalled, setSaveAutoCalled] = useState();
  const [completionPercentage, setCompletionPercentage] = useState([])

  const ID = project?.ID || 0;

  useEffect(() => {
    async function fetch(demographicsRef, servicesRef, ownershipRef, insuranceRef, payrollRef, docRef) {
      const DemoValuesEmpty = demographicsRef && demographicsRef.current && await demographicsRef.current.hasError();
      const ServiceValuesEmpty = servicesRef && servicesRef.current && await servicesRef.current.hasError();
      const ownershipValuesEmpty = ownershipRef && ownershipRef.current && await ownershipRef.current.hasError();
      const InsuranceValuesEmpty = insuranceRef && insuranceRef.current && await insuranceRef.current.hasError();
      const PayrollValuesEmpty = payrollRef && payrollRef.current && await payrollRef.current.hasError();
      const DocValuesEmpty = docRef && docRef.current && await docRef.current.pendingUpload();

      const newTab = {
        Demographics: !DemoValuesEmpty ? "activeNav" : '',
        Services: !ServiceValuesEmpty ? "activeNav" : '',
        OwnerShip: !ownershipValuesEmpty ? "activeNav" : '',
        Insurance: !InsuranceValuesEmpty ? "activeNav" : '',
        Payroll: !PayrollValuesEmpty ? "activeNav" : '',
        Documents: !DocValuesEmpty ? "activeNav" : '',

      }
      setCompletedTab(newTab)
    }
    fetch(demographicsRef, servicesRef, ownershipRef, insuranceRef, payrollRef, docRef)
  }, [saveAutoCalled, demographicsRef])

  const [selected, setSelected] = useState({
    Demographics: "show active", DemographicsNav: true, DemoActive: "active",
    Services: "", ServicesNav: false, ServicesActive: "",
    OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
    Insurance: "", InsuranceNav: false, InsuranceActive: "",
    Payroll: "", PayrollNav: false, PayrollActive: "",
    Documents: "", DocumentsNav: false, DocumentsActive: "",
  })

  const remainingDaysPercentage = displaydays / allocatedDays;
  const remainingProfilePercentage = 100 - totalTabsHealth;
  const remainingRatio =
    (remainingDaysPercentage / remainingProfilePercentage) * 100;
  useEffect(() => {
    const fetch = async () => {
      const res = await getProjectId(ProjectId,{$isFacilityProfileDoc: true,$isDocs: true,$isFacility: true});
      if ((res?.WMS_ProjectFacility?.length > 0 && res?.WMS_ProjectFacility[0]?.Status > ProjectStatus.InProgress)) {
        navigate("/dashboard");
      }
      const date1 = new Date(res.CreatedAt);
      const date2 = new Date(res.EndDate);
      const timeDiffAllocated = Math.abs(date2.getTime() - date1.getTime());
      const daysRemainingAllocated = Math.ceil(
        timeDiffAllocated / (1000 * 3600 * 24)
      );
      setAllocatedDay(daysRemainingAllocated);
      const currentDate = new Date();
      const endDateTime = new Date(res.EndDate);
      const timeDiff = endDateTime.getTime() - currentDate.getTime();
      const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

      setDisplayDays(Math.max(daysRemaining, 0) || 0);
    };
    fetch();
  }, []);


  useEffect(() => {
    if (ProjectId) {
      getProjectFaciltyByPId(ProjectId);
      totalHealth()
    }
    getStates()
    getDocumentCategories();
    getDocumentTypes();
  }, []);

  useEffect(() => {
    if (projectDetails && projectDetails.Status > ProjectStatus.InProgress) {
      setTimeout(() => {
        navigate('/dashboard');
      }, [1000]);
    }
  }, [])



  const setBackDemographics = () => {
    setSelected({
      Demographics: "show active", DemographicsNav: true, DemoActive: "active",
      Services: "", ServicesNav: false, ServicesActive: "",
      OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
      Insurance: "", InsuranceNav: false, InsuranceActive: "",
      Payroll: "", PayrollNav: false, PayrollActive: "",
      Documents: "", DocumentsNav: false, DocumentsActive: "",
    });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }

  const setBackServices = () => {
    setSelected({
      Services: "show active", ServicesNav: true, ServicesActive: "active",
      Demographics: "", DemographicsNav: false, DemoActive: "",
      OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
      Insurance: "", InsuranceNav: false, InsuranceActive: "",
      Payroll: "", PayrollNav: false, PayrollActive: "",
      Documents: "", DocumentsNav: false, DocumentsActive: "",
    })
    if (progressValue < 20) {
      const id = setInterval(() => {
        setProgressvalue(prevNumber => prevNumber < 20 ? prevNumber + 1 : prevNumber);
      }, 100);
      setIntervalId(id);
    }
    setProgressBarValue(20)
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }

  const setBackOwnerShip = () => {
    setSelected({
      OwnerShip: "show active", OwnerShipNav: true, OwnerShipActive: "active",
      Demographics: "", DemographicsNav: false, DemoActive: "",
      Services: "", ServicesNav: false, ServicesActive: "",
      Insurance: "", InsuranceNav: false, InsuranceActive: "",
      Payroll: "", PayrollNav: false, PayrollActive: "",
      Documents: "", DocumentsNav: false, DocumentsActive: "",
    })

    setProgressBarValue(40)
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }

  const setBackPayrole = () => {
    setSelected({
      Payroll: "show active", PayrollNav: true, PayrollActive: "active",
      Demographics: "", DemographicsNav: false, DemoActive: "",
      OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
      Insurance: "", InsuranceNav: false, InsuranceActive: "",
      Services: "", ServicesNav: false, ServicesActive: "",
      Documents: "", DocumentsNav: false, DocumentsActive: "",
    })
    setProgressvalue(80)
    setProgressBarValue(80)
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);

  }

  const setBackInsurance = () => {
    setSelected({
      Insurance: "show active", InsuranceNav: true, InsuranceActive: "active",
      Demographics: "", DemographicsNav: false, DemoActive: "",
      OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
      Services: "", ServicesNav: false, ServicesActive: "",
      Payroll: "", PayrollNav: false, PayrollActive: "",
      Documents: "", DocumentsNav: false, DocumentsActive: "",
    })
    setProgressvalue(60)
    setProgressBarValue(60)
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }

  const setBackDocuments = () => {
    setSelected({
      Documents: "show active", DocumentsNav: true, DocumentsActive: "active",
      Insurance: "", InsuranceNav: false, InsuranceActive: "",
      Demographics: "", DemographicsNav: false, DemoActive: "",
      OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
      Services: "", ServicesNav: false, ServicesActive: "",
      Payroll: "", PayrollNav: false, PayrollActive: "",
    })
    setProgressvalue(100)
    setProgressBarValue(100)
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }

  const handleConfirmDelete = async () => {
    setBtnLoader(true)
    await removeFacilityOwners(deleteOwnerID);
    await getFacilityOwners(projectDetails && projectDetails.ID);
    window.$("#deleteOwnerModal").modal('hide');
    setMessage("Facility owner has been successfully deleted!")
    setType(MessageType.Success);
    setSaveAutoCalled(true)
    setTimeout(() => {
      setBtnLoader(false)
    }, 1000);
  }

  const handleConfirmDeleteDoc = async () => {
    setBtnLoader(true)
    await removeDocuments(deleteDocID)
    window.$("#_delete").modal('hide');
    setMessage("Document has been successfully deleted!")
    setType(MessageType.Success)
    docRef.current.setUploads(docRef.current.uploads.filter(c => c.ID !== deleteDocID.ID))
    let documents = docRef.current.totalUploads.filter(c => c.ID !== deleteDocID.ID);
    let CompletionPercentage = []
    const percentage = documents?.length * (100 / docRef.current.mandatoryDocuments.length);
    let res = completionPercentage?.filter((e) => e && e.Tab === FacilityUserForm.DOCS) || [];
    if (res && res.length) {
      res[0].Percentage = percentage;
      CompletionPercentage = res;
    } else {
      CompletionPercentage = [{
        Tab: FacilityUserForm.DOCS,
        Percentage: percentage,
      }];
    }
    let res1 = await updateProjectCompletionPercentage(CompletionPercentage)
    setSaveAutoCalled(deleteDocID.ID)
    totalHealth()
    setTimeout(() => {
      setBtnLoader(false)
    }, 1000);
  }


  const finalFormSubmission = async () => {
    const validateDemographicsForm = await demographicsRef.current.validateForm(true);
    const validateServicesForm = await servicesRef.current.validateForm(true);
    const validateOwnershipForm = await ownershipRef.current.validateForm(true);
    const validateInsuranceForm = await insuranceRef.current.validateForm(true);
    const validatePayrollForm = await payrollRef.current.validateForm(true);
    const validateDocuments = await docRef.current.validateForm(true);

    if (!validateDemographicsForm) {
      setTab(1)
      return false
    } else if (!validateServicesForm) {
      setTab(2)
      return false
    }
    else if (!validateOwnershipForm) {
      setTab(3);
      return false
    } else if (!validateInsuranceForm) {
      setTab(4);
      return false
    } else if (!validatePayrollForm) {
      setTab(5);
      return false
    }
    else if (!validateDocuments) {
      setTab(6);
      return false
    }
    window.$(`#facilitypreview`).modal('show');
    setShowModal(true)
    return true
  }

  const handleClick = () => {
    switch (tab) {
      case 1:
        setBackDemographics();
        return;
      case 2:
        setBackServices();
        return;
      case 3:
        setBackOwnerShip();
        return;
      case 4:
        setBackInsurance();
        return;
      case 5:
        setBackPayrole();
        return;
      case 6:
        setBackDocuments();
        return;

      default:
        break;
    }
  }

  const onSubmit = async () => {
    let isValid = await finalFormSubmission();
    if (isValid) {
      setIsLoading(true);
      const res = await onBoarding(
        {
          Tab: FacilityUserForm.DOCS,
          Status: ProjectStatus.Completed,
          VerificationStatus: "1|1|1|1|1|1",
          ProjectId: ProjectId
        },
        projectDetails?.ID)
      // console.log('res', res)
      if (res) {
        setTimeout(() => {
          window.$(`#facilitypreview`).modal('hide');
          setShowModal(false)
          navigate("/thankyou")
          setIsLoading(false)
        }, 500)
      }
    }
  }

  const totalHealth = async (CompletionPercentage) => {
    const TOTAL_TABS = 6;
    const progress = CompletionPercentage || await getProjectCompletion(ProjectId);
    setCompletionPercentage(progress);
    const sumOfPercentage = progress.reduce(
      (acc, cur) => acc + cur.Percentage,
      0
    );
    const totalHealth = TOTAL_TABS * 100;
    const Health = (sumOfPercentage / totalHealth) * 100;

    setTotalTabsHealth(Health.toFixed(2));
    let values;
    if (remainingRatio >= 0.0001 && remainingRatio < 0.5) {
      values = {
        ProjectId,
        Health: HealthStatus.ATRISK,
      };
    }
    if (remainingRatio >= 0.5 && remainingRatio < 0.8) {
      values = {
        ProjectId,
        Health: HealthStatus.REQUIESATTENTION,
      };
    }
    if (remainingRatio >= 0.8) {
      values = {
        ProjectId,
        Health: HealthStatus.ONTRACK,
      };
    }
    return values;
    // if (values && ID) {
    //   await onBoarding(values, ID, true);
    // }
  }


  return (
    <>
      <div className="doodle-data2">
        <img src={images.doodle2} alt="logo" />
      </div>
      <section className="header-section">
        <div className="container-fluid cst-fluid">
          <div className="main-header-form-fill">
            <Header showDashboardRedirection={true} />
            <div className="row ct-header cst-header">
              <div className="form-menu sticky-data">
                <div className="row">
                  <div className="col-md-9">
                    <ul
                      className="nav nav-pills mb-3 timeline"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item " role="presentation">
                        <button
                          onClick={setBackDemographics}
                          className={`nav-link ${selected.DemoActive} ${!selected.DemoActive && completedTab.Demographics
                            }`}
                          id="pills-demographic-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-demographic"
                          type="button"
                          role="tab"
                          aria-controls="pills-demographic"
                          aria-selected={selected.DemographicsNav}
                        >
                          {!selected.DemoActive &&
                            completedTab.Demographics === "activeNav" ? (
                            <span className="rnd-data cmp-data">
                              <Icon
                                icon={"mdi:tick"}
                                className="iconify cus-icon"
                              />
                            </span>
                          ) : (
                            <span className="rnd-data">1</span>
                          )}

                          <span>Demographics</span>
                        </button>
                      </li>
                      <li className="nav-item " role="presentation">
                        <button
                          onClick={setBackServices}
                          className={`nav-link ${selected.ServicesActive}  ${!selected.ServicesActive && completedTab.Services
                            }`}
                          id="pills-services-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-services"
                          type="button"
                          role="tab"
                          aria-controls="pills-services"
                          aria-selected={selected.ServicesNav}
                        >
                          {!selected.ServicesActive &&
                            completedTab.Services === "activeNav" ? (
                            <span className="rnd-data cmp-data">
                              <Icon
                                icon={"mdi:tick"}
                                className="iconify cus-icon"
                              />
                            </span>
                          ) : (
                            <span className="rnd-data">2</span>
                          )}
                          <span>Services</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={setBackOwnerShip}
                          className={`nav-link ${selected.OwnerShipActive} ${!selected.OwnerShipActive && completedTab.OwnerShip
                            }`}
                          id="pills-ownership-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-ownership"
                          type="button"
                          role="tab"
                          aria-controls="pills-ownership"
                          aria-selected={selected.OwnerShipNav}
                        >
                          {!selected.OwnerShipActive &&
                            completedTab.OwnerShip === "activeNav" ? (
                            <span className="rnd-data cmp-data">
                              <Icon
                                icon={"mdi:tick"}
                                className="iconify cus-icon"
                              />
                            </span>
                          ) : (
                            <span className="rnd-data">3</span>
                          )}{" "}
                          <span>Ownership</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={setBackInsurance}
                          className={`nav-link ${selected.InsuranceActive} ${!selected.InsuranceActive && completedTab.Insurance
                            }`}
                          id="pills-insurance-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-insurance"
                          type="button"
                          role="tab"
                          aria-controls="pills-insurance"
                          aria-selected={selected.InsuranceNav}
                        >
                          {!selected.InsuranceActive &&
                            completedTab.Insurance === "activeNav" ? (
                            <span className="rnd-data cmp-data">
                              <Icon
                                icon={"mdi:tick"}
                                className="iconify cus-icon"
                              />
                            </span>
                          ) : (
                            <span className="rnd-data">4</span>
                          )}
                          <span>Insurance</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={setBackPayrole}
                          className={`nav-link ${selected.PayrollActive} ${!selected.PayrollActive && completedTab.Payroll
                            }`}
                          id="pills-payroll-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-payroll"
                          type="button"
                          role="tab"
                          aria-controls="pills-payroll"
                          aria-selected={selected.PayrollNav}
                        >
                          {!selected.PayrollActive &&
                            completedTab.Payroll === "activeNav" ? (
                            <span className="rnd-data cmp-data">
                              <Icon
                                icon={"mdi:tick"}
                                className="iconify cus-icon"
                              />
                            </span>
                          ) : (
                            <span className="rnd-data">5</span>
                          )}
                          <span>Payroll</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          onClick={setBackDocuments}
                          className={`nav-link ${selected.DocumentsActive} ${!selected.DocumentsActive && completedTab.Documents
                            }`}
                          id="pills-documents-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-documents"
                          type="button"
                          role="tab"
                          aria-controls="pills-documents"
                          aria-selected={selected.DocumentsNav}
                        >
                          {!selected.DocumentsActive &&
                            completedTab.Documents === "activeNav" ? (
                            <span className="rnd-data cmp-data">
                              <Icon
                                icon={"mdi:tick"}
                                className="iconify cus-icon"
                              />
                            </span>
                          ) : (
                            <span className="rnd-data">6</span>
                          )}
                          <span className=''>Documents</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 prg-bar">
                    <div className="box-content">
                      <div className="box">
                        <p>Profile progress</p>
                        {/* <div className="circular-progress">
                          
                          <span className="progress-value">
                            20%
                            </span>
                        </div>  */}
                        <div className="progress-bar">
                          <Circle
                            percent={totalTabsHealth}
                            strokeWidth={10}
                            strokeColor={totalTabsHealth > 0 ? "#48B473" : "rgb(217, 217, 217)"}
                            className="custom-progress"
                            trailWidth={10}
                          />
                          <span className="custom-progress-value">
                            {Math.round(totalTabsHealth)}%{" "}
                          </span>
                        </div>
                      </div>
                      <div className="box-time">
                        <p>Time remaining</p>
                        <h2>{displaydays < 0 ? 0 : displaydays}</h2>
                        <p>Days</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              {/* <div className='custom-progress-bar-outer'>
                <Line percent={BarValue} strokeWidth={1} strokeColor="#1E823A" className='custom-progress-bar' trailWidth={1} />
              </div> */}
              {/* <div className="com-progressbar">
                <div className="progress animated-progess progress-sm" id="updating-progress">
                  <div className="progress-bar completed-progress" role="progressbar" style={{ "width": "5%" }} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="frm-maincontent">
        <div className="doodle-data">
          <img src={images.formBg} alt="bg" />
        </div>
        <div className="container-fluid cnd-fluid">
          <FormValidation
            invalidAlert={invalidAlert}
            type={type}
            message={message}
            setMessage={setMessage}
            viewFields={viewfields}
            onClick={handleClick}
          />
          <div className="main-header-form-fill-data">
            <div className="row">
              <div className="form-main">
                <div className="col-md-12">
                  <div className="form-maintab-content">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className={`tab-pane fade ${selected.Demographics}`}
                        id="pills-demographic"
                        role="tabpanel"
                        aria-labelledby="pills-demographic-tab"
                        tabIndex="0"
                      >
                        <Demographics
                          ref={demographicsRef}
                          selected={selected}
                          setSelected={setSelected}
                          setMessage={setMessage}
                          setType={setType}
                          setSaveAutoCalled={setSaveAutoCalled}
                          setViewFields={setViewFields}
                          setInvalidAlert={setInvalidAlert}
                          ProjectId={ProjectId}
                          totalHealth={totalHealth}
                        />
                      </div>
                      <div
                        className={`tab-pane fade ${selected.Services}`}
                        id="pills-services"
                        role="tabpanel"
                        aria-labelledby="pills-services-tab"
                        tabIndex="1"
                      >
                        <Services
                          ref={servicesRef}
                          selected={selected}
                          setSelected={setSelected}
                          Tab={FacilityUserForm.SERVICES}
                          setMessage={setMessage}
                          setType={setType}
                          setSaveAutoCalled={setSaveAutoCalled}
                          setViewFields={setViewFields}
                          setInvalidAlert={setInvalidAlert}
                          ProjectId={ProjectId}
                          totalHealth={totalHealth}
                        />
                      </div>
                      <div
                        className={`tab-pane fade ${selected.OwnerShip}`}
                        id="pills-ownership"
                        role="tabpanel"
                        aria-labelledby="pills-ownership-tab"
                        tabIndex="2"
                      >
                        <Ownership
                          ref={ownershipRef}
                          setDeleteOwnerID={setDeleteOwnerID}
                          setOwnershipDetails={
                            addOwnershipRef.current?.setOwnershipDetails
                          }
                          selected={selected}
                          setSelected={setSelected}
                          setMessage={setMessage}
                          setType={setType}
                          setSaveAutoCalled={setSaveAutoCalled}
                          setViewFields={setViewFields}
                          setInvalidAlert={setInvalidAlert}
                          ProjectId={ProjectId}
                          totalHealth={totalHealth}
                        />
                      </div>
                      <div
                        className={`tab-pane fade ${selected.Insurance}`}
                        id="pills-insurance"
                        role="tabpanel"
                        aria-labelledby="pills-insurance-tab"
                        tabIndex="3"
                      >
                        <Insurance
                          ref={insuranceRef}
                          selected={selected}
                          setSelected={setSelected}
                          setMessage={setMessage}
                          setType={setType}
                          setSaveAutoCalled={setSaveAutoCalled}
                          setViewFields={setViewFields}
                          setInvalidAlert={setInvalidAlert}
                          ProjectId={ProjectId}
                          totalHealth={totalHealth}
                        />
                      </div>
                      <div
                        className={`tab-pane fade ${selected.Payroll}`}
                        id="pills-payroll"
                        role="tabpanel"
                        aria-labelledby="pills-payroll-tab"
                        tabIndex="4"
                      >
                        <Payroll
                          ref={payrollRef}
                          selected={selected}
                          setSelected={setSelected}
                          setMessage={setMessage}
                          setType={setType}
                          setSaveAutoCalled={setSaveAutoCalled}
                          setViewFields={setViewFields}
                          setInvalidAlert={setInvalidAlert}
                          ProjectId={ProjectId}
                          totalHealth={totalHealth}
                        />
                      </div>
                      <div
                        className={`tab-pane fade ${selected.Documents}`}
                        id="pills-documents"
                        role="tabpanel"
                        aria-labelledby="pills-documents-tab"
                        tabIndex="5"
                      >
                        <Documents
                          ref={docRef}
                          setMessage={setMessage}
                          setType={setType}
                          setViewFields={setViewFields}
                          selected={selected}
                          setSelected={setSelected}
                          finalFormSubmission={finalFormSubmission}
                          setSaveAutoCalled={setSaveAutoCalled}
                          ProjectId={ProjectId}
                          setDeleteDocID={setDeleteDocID}
                          totalHealth={totalHealth}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddFacilityOwner
        ref={addOwnershipRef}
        setSaveAutoCalled={setSaveAutoCalled}
      />
      <Modal
        id={'_delete'}
        title={'Delete'}
        content={
          'Are you sure you want to delete the document?'
        }
        loading={btnLoader}
        submit={'Yes'}
        cancel={'No'}
        onClick={handleConfirmDeleteDoc}
      />
      <Modal
        onClick={handleConfirmDelete}
        id="deleteOwnerModal"
        title={"Delete Facility Ownership"}
        content={"Are you sure you want to delete Facility Ownership details?"}
        submit={"Yes "}
        cancel={"No"}
      />
      <div
        className="modal fade"
        id={`facilitypreview`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog model-commen modal-dialog-centered max-width-none modal-xl">
          <div className="modal-content common-modal-content common-model-header max-width-none ">
            <div
              className="modal-header"
              style={{
                position: "sticky",
                background: "#fff",
                top: 0,
                zIndex: 9999,
              }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div class="sub-data">
                      <p>Previewing</p>
                      <h3>Facility profile</h3>
                    </div>
                  </div>
                  {<div className="col-md-8">
                    <div class='sv-btn ft-right d-flex'>
                      {<button type="button" className="btn-blue cst-blue bl-dt btnNext me-3 d-flex"
                        onClick={(e) => { e.preventDefault(); onSubmit() }}>
                        {isLoading && (<div className="loader" style={{ marginTop: 4 }}></div>)}
                        {'Submit'}
                      </button>}
                      <button type="button" onClick={() => { window.$(`#facilitypreview`).modal('hide'); setShowModal(false) }} className="btn-grey cst-gry me-1">
                        <span class="iconify me-1" data-icon="mdi:close-circle-outline" data-width="16px"></span>
                        {'Cancel'}
                      </button>
                    </div></div>}

                </div>
              </div>
            </div>
            <div className="modal-body common-model-body modalHeight">
              <div>
                <PdfGeneration ref={docRef} ProjectId={ProjectId} saveAutoCalled={saveAutoCalled} showModal={showModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = { removeFacilityOwners, getFacilityOwners, getProjectFaciltyByPId, getStates, getDocumentTypes, getDocumentCategories, onBoarding, getProjectId, getProjectCompletion, removeDocuments, updateProjectCompletionPercentage }
const mapStateToProps = (state) => ({
  projectDetails: state?.facility?.projectDetails || null,
  project: state?.facility?.projectDetails || null,

})
export default connect(mapStateToProps, mapDispatchToProps)(FacilityProfile);
