import client from "../../services/api-client";
import { errorHandler } from "../error/action";



export const otpAuthenticate = (credentials) => async (dispatch) => {
  try {

    const res = await client.authenticate({
      strategy: "otp",
      ...credentials,
    });
    if (res && res.WMS_ProjectFacilityUsers) {
      dispatch({
        type: "LOGIN",
        data: res.WMS_ProjectFacilityUsers,
      });
    }
    return res;
  } catch (error) {
    let err = error;
    errorHandler(err);
    return false;
  }
};

export const isAlive = async (dispatch) => {
  // Try to authenticate using an existing token
  try {
    const token = localStorage.getItem("feathers-jwt");
    if (token) {
      const res = await client.reAuthenticate();
      if (res?.WMS_ProjectFacilityUsers) {
        return res.WMS_ProjectFacilityUsers;
      }
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem("feathers-jwt");
    localStorage.removeItem("persist:root");
    await client.logout();
    dispatch({
      type: "LOGOUT",
      data: {},
    });
    return true;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

