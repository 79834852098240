import React, { useState } from 'react'
import _ from "lodash";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@iconify/react';
import MaskedInput from '../MaskedInput';
// import { useRef } from 'react';

const DatePickerComponent = ({
  onChange,
  error,
  value,
  label,
  innerRef,
  placeholder,
  type,
  disabled,
  maxLength,
  isCalenderIcon,
  wrapperClassName = "",
  className = "",
  labelclass = "",
  endAdornment,
  onKeyPress,
  name,
  selected,
  id,
  onBlur,
  iconClass="",
  placeholderText="MM/DD/YYYY",
  futureDateOnly=false,
  required=false,
}) => { 
  const [startDate, setStartDate] = useState(new Date());
  const [isFocus, setIsFocus] = useState(false);
  const [opening, setOpen] = useState(false);
  // const datePickerRef = useRef(null);
  const onBlurInput = () => {
    setIsFocus(false);
    // setOpen(false)
  };
  const openCalendar = ()=>{
    setOpen(true);
  }
  return (<>
    <DatePicker
      selected={selected}
      onChange={onChange}
     //className={className}
     wrapperClassName={wrapperClassName}
      placeholder={' '}
      onBlur={onBlurInput}
      value={value}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      placeholderText={placeholderText}
      minDate={futureDateOnly && new Date()}
      maxDate={!futureDateOnly && new Date()} 
      customInput={
        <MaskedInput
            value={value || null}
            className={"inputformdata form-control"}
            label={label}
            placeHolder={"MM/DD/YYYY"}
            isrequired={required} 
            // field={"Date of registration"}
            handleFieldChange={(e) => { onChange(e);}} />
    }
    />
   
    <Icon
     icon={"lucide:calendar-days"}
     className={`iconify cursor-pointer  dateicon ${iconClass}`}
     />
   

  </>)
}

export default DatePickerComponent
