import { Icon } from '@iconify/react';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { validate } from 'validate.js';
import MessageBox from '../../../../components/Common/MessageBox';
import TextField from '../../../../components/Common/TextInput';
import { onBoarding, getProjectCompletion } from '../../../../store/facilityProfiling/action';
import { MessageType, corporateStatus, FacilityUserForm } from '../../../../util/enum';
import { AcceptPhoneNumbers, AcceptFEINNumbers, AcceptZipNumbers, formatDemographics, formatPhoneNumber, formatFEIN, formatCDPHLicenseNumber, AcceptFEIN, AcceptCDPH } from '../../../../util/helper';
import { validationSchema, dataValidationSchema } from './schema';
import _ from 'lodash';
import Select from '../../../../components/Common/Select';
import DatePickerComponent from '../../../../components/Common/DatePicker';
import Loader from '../../../../components/Common/SpinnerLoader'
import moment from 'moment';
import { UpdateDemographicsPercentage } from '../../../../util/progressUtil';

const Demographics = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            validateForm,
            hasError
        };
    });
    const { onBoarding, project, setType, setMessage, getProjectCompletion, states, setViewFields, setSaveAutoCalled, setInvalidAlert, ProjectId, totalHealth, selected } = props;
    const [startDate, setStartDate] = useState("")
    const [values, setvalues] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const ID = project?.ID || 0;

    useEffect(() => {
        async function fetchData(project) {
            if (project) {
                const res = await formatDemographics(project)
                const progress = await getProjectCompletion(ProjectId);
                res.CompletionPercentage = progress;
                res.ProjectId = ProjectId;
                setvalues(res || {});
                setTimeout(() => {
                    setLoading(false)
                    setSaveAutoCalled(true)
                }, 1000);
            }
        }

        fetchData(project)
    }, [project]);

    useEffect(() => {
        async function fetchData() {
            if (selected && selected.DemographicsNav) { 
                const progress = await getProjectCompletion(ProjectId); 
                setvalues({...values, CompletionPercentage:progress}); 
            }
        }
        fetchData()
    }, [selected]);

    const handleChange = (field, v) => {
        setvalues({ ...values, [field]: v });
    }

    const hasError = async () => {
        const errors = await validate(values, validationSchema);
        return errors
    }


    const validateForm = async (viewFields = false) => {
        const errors = await validate(values, validationSchema);
        setError(errors)
        if (errors) {
            const message = errors[Object.keys(errors)[0]][0];
            setType(MessageType.Error);
            setMessage(message);
            setViewFields(viewFields)
            setInvalidAlert(false)
            return false;
        } else {
            return true;
        }
    }

    const dataValidateForm = async (viewFields = false) => {
        const errors = await validate(values, dataValidationSchema);
        setError(errors)
        if (errors) {
            const message = errors[Object.keys(errors)[0]][0];
            setType(MessageType.Error);
            setMessage(message);
            setInvalidAlert(true)
            return false;
        } else {
            return true;
        }
    }

    const saveAuto = async (field) => {
        let data = { ...values }
        data = await UpdateDemographicsPercentage(values, field);
        let health = await totalHealth(data.CompletionPercentage);
        data = { ...data, ...health };
        let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.DEMOGRAPHICS).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.DEMOGRAPHICS)[0].ProjectId > 0))
        const project = await onBoarding(data, ID, skipDispatch);
        if (project) {
            setSaveAutoCalled(project);
        }
    }

    const handleSubmit = async (e) => {
        let isValid = await dataValidateForm();
        if (isValid) {
            setType && setType(MessageType.Success);
            setMessage && setMessage("Your changes have been successfully saved!");
        }
    }

    const handleNext = () => {
        props.setSelected({
            Demographics: "", DemographicsNav: false, ServicesNav: true,
            Services: "show active", DemoActive: "", ServicesActive: "active"
        })
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100);
    }
    const handleDateChange = async (date, field) => {
        setStartDate(date);
        let data = { ...values, [field]: date };
        data = await UpdateDemographicsPercentage(data, field);
        let skipDispatch = (ID > 0 && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.DEMOGRAPHICS)[0].ProjectId > 0)
        let health = await totalHealth(data.CompletionPercentage);
        data = { ...data, ...health };
        const project = await onBoarding(data, ID, skipDispatch);
        setvalues({ ...values, [field]: date });
        setStartDate(date);
        if (project) {
            setSaveAutoCalled(project);
        }
    }

    if (loading) {
        return (
            <div className='d-flex justify-content-center'>
                <Loader loading={loading} height={'20rem'} width={'20rem'} />
            </div>
        )
    }

    return (
        <div>
            <MessageBox setType={setType} setMessage={setMessage} />
            <div className="form-start">
                <div className="row">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Demographic Information</span></h6>
                    </div>
                    <div className="create-patient-form">
                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    maxLength={100}
                                    required={true}
                                    type="text"
                                    name="BusinessName"
                                    value={values.BusinessName}
                                    error={error && error.BusinessName}
                                    className="inputformdata form-control"
                                    id="firstname"
                                    label="Legal Business Name"
                                    placeholder="Legal Business Name "
                                    wrapperClassName='mr-bt-35'
                                    onChange={(e) => handleChange("BusinessName", e.target.value)}
                                    onBlur={() => saveAuto("BusinessName")}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <Select
                                    required={true}
                                    options={corporateStatus && corporateStatus.getKeyValuePairs()}
                                    value={values.CorporateStatus || null}
                                    error={error && error.CorporateStatus}
                                    onChange={(e) => handleChange("CorporateStatus", Number(e.target.value))}
                                    label="Corporate Status (LLC, Inc, Partnership)"
                                    placeholder="Corporate Status (LLC, Inc, Partnership)"
                                    className='inputformdata form-control'
                                    onBlur={() => saveAuto("CorporateStatus")}

                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    maxLength={100}
                                    type="text"
                                    name='DBAName'
                                    value={values.DBAName}
                                    error={error && error.DBAName}
                                    label="DBA Name (if applicable)"
                                    className="inputformdata form-control"
                                    id="DBA"
                                    placeholder="DBA Name (if applicable)"
                                    wrapperClassName='mr-bt-25'
                                    onChange={(e) => handleChange("DBAName", e.target.value)}
                                    onBlur={saveAuto}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    required={true}
                                    type="text"
                                    name='FEIN'
                                    value={values.FEIN && formatFEIN(values.FEIN)}
                                    error={error && error.FEIN}
                                    onChange={(e) => handleChange("FEIN", AcceptFEINNumbers(e.target.value))}
                                    label="FEIN #"
                                    className="inputformdata form-control"
                                    id="FEIN"
                                    placeholder="XX-XXXXXXX"
                                    wrapperClassName='mr-bt-25'
                                    onBlur={() => saveAuto("FEIN")}
                                    maxLength={10}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    required={true}
                                    type="text"
                                    name='NPI'
                                    value={values?.NPI}
                                    error={error && error.NPI}
                                    onChange={(e) => handleChange("NPI", AcceptFEIN(e.target.value))}
                                    label="NPI # "
                                    className="inputformdata form-control"
                                    id="NPI"
                                    placeholder="XXXXXXXXXX"
                                    wrapperClassName='mr-bt-25'
                                    onBlur={() => saveAuto("NPI")}
                                    maxLength={10}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    type="text"
                                    name='CDPH'
                                    required={true}
                                    value={values.CDPH}
                                    error={error && error.CDPH}
                                    onChange={(e) => handleChange("CDPH", AcceptCDPH(e.target.value))}
                                    label="CDPH LIC #"
                                    className="inputformdata form-control"
                                    id="CDPH"
                                    placeholder="XXXXXXXXX"
                                    wrapperClassName='mr-bt-25'
                                    onBlur={() => saveAuto("CDPH")}
                                    maxLength={9}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <div className='inputformContainer'>

                                    <DatePickerComponent
                                        name="CDPHExpiryDate"
                                        selected={startDate || ''}
                                        value={values?.CDPHExpiryDate ? moment(values?.CDPHExpiryDate).format("MM/DD/YYYY") : ""}
                                        error={error && error.CDPHExpiryDate}
                                        required={true}
                                        onChange={(date) => handleDateChange(date, "CDPHExpiryDate")}
                                        className='mr-bt-25'
                                        wrapperClassName={`inputformContainer ${error && error.CDPHExpiryDate ? "redborder" : ""}`}
                                        placeholder='Expiry Date'
                                        label="Expiry Date"
                                        onBlur={() => saveAuto("CDPHExpiryDate")}
                                        placeholderText="Expiry Date"
                                        futureDateOnly={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    maxLength={255}
                                    required={true}
                                    type="text"
                                    name='FacilityAddress'
                                    value={values.FacilityAddress}
                                    error={error && error.FacilityAddress}
                                    onChange={(e) => handleChange("FacilityAddress", e.target.value)}
                                    label="Facility Address "
                                    className="inputformdata form-control"
                                    id="facility"
                                    placeholder="Facility Address "
                                    wrapperClassName='mr-bt-25'
                                    onBlur={() => saveAuto("FacilityAddress")}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45 ">
                                <TextField
                                    maxLength={100}
                                    required={true}
                                    type="text"
                                    name='FacilityCity'
                                    value={values.FacilityCity}
                                    error={error && error.FacilityCity}
                                    onChange={(e) => handleChange("FacilityCity", e.target.value)}
                                    className="inputformdata form-control"
                                    id="City"
                                    wrapperClassName='mr-bt-25'
                                    placeholder="City"
                                    label="City"
                                    onBlur={() => saveAuto("FacilityCity")}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <Select
                                    required={true}
                                    value={values.FacilityState}
                                    error={error && error.FacilityState}
                                    onChange={(e) => handleChange("FacilityState", Number(e.target.value))}
                                    name='FacilityState'
                                    options={states}
                                    label="State"
                                    placeholder="State"
                                    className='inputformdata form-control'
                                    onBlur={() => saveAuto("FacilityState")}

                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    required={true}
                                    type="text"
                                    name='FacilityPincode'
                                    value={values.FacilityPincode}
                                    error={error && error.FacilityPincode}
                                    onChange={(e) => handleChange("FacilityPincode", AcceptZipNumbers(e.target.value))}
                                    className="inputformdata form-control"
                                    id="Zip"
                                    wrapperClassName='mr-bt-25'
                                    placeholder="Zip"
                                    label="Zip"
                                    onBlur={() => saveAuto("FacilityPincode")}

                                    maxLength={5}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    maxLength={255}
                                    // required={true}
                                    type="text"
                                    name='WebsiteUrl'
                                    value={values.WebsiteUrl}
                                    error={error && error.WebsiteUrl}
                                    onChange={(e) => handleChange("WebsiteUrl", e.target.value)}
                                    label="Website "
                                    className="inputformdata form-control"
                                    id="Web Site"
                                    placeholder="Website "
                                    wrapperClassName='mr-bt-25'
                                    onBlur={saveAuto}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    required={true}
                                    type="text"
                                    name='FacilityFax'
                                    value={values.FacilityFax && formatPhoneNumber(values.FacilityFax)}
                                    error={error && error.FacilityFax}
                                    onChange={(e) => handleChange("FacilityFax", AcceptPhoneNumbers(e.target.value))}
                                    className="inputformdata form-control"
                                    id="Fax"
                                    wrapperClassName='mr-bt-25'
                                    placeholder="Fax"
                                    label="Fax"
                                    onBlur={() => saveAuto("FacilityFax")}

                                    maxLength={14}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    required={true}
                                    type="text"
                                    name='FacilityPhone'
                                    value={values.FacilityPhone && formatPhoneNumber(values.FacilityPhone)}
                                    error={error && error.FacilityPhone}
                                    onChange={(e) => handleChange("FacilityPhone", AcceptPhoneNumbers(e.target.value))}
                                    className="inputformdata form-control"
                                    id="Phone"
                                    wrapperClassName='mr-bt-25'
                                    placeholder="Phone"
                                    label="Phone"
                                    onBlur={() => saveAuto("FacilityPhone")}

                                    maxLength={14}
                                />
                            </div>
                            <div className="col-md-4 pd-right-45">
                                <TextField
                                    maxLength={100}

                                    required={true}
                                    type="email"
                                    name='FacilityEmail'
                                    value={values?.FacilityEmail}
                                    error={error && error?.FacilityEmail}
                                    onChange={(e) => handleChange("FacilityEmail", e.target.value)}
                                    className="inputformdata form-control"
                                    id="Email"
                                    wrapperClassName='mr-bt-25'
                                    placeholder="Email"
                                    label="Email"
                                    onBlur={() => saveAuto("FacilityEmail")}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Contact Information</span></h6>
                    </div>
                    <div className="row">
                        <div className="col-md-3 pd-right-45">
                            <TextField
                                maxLength={100}

                                required={true}
                                type="text"
                                name='Contact1Name'
                                value={values.Contact1Name}
                                error={error && error.Contact1Name}
                                onChange={(e) => handleChange("Contact1Name", e.target.value)}
                                className="inputformdata form-control"
                                id="Name"
                                label="Name "
                                placeholder="Name "
                                wrapperClassName='mr-bt-25'
                                onBlur={() => saveAuto("Contact1Name")}

                            />
                        </div>
                        <div className="col-md-3 pd-right-45">
                            <TextField
                                maxLength={100}

                                required={true}
                                type="text"
                                name='Contact1Title'
                                value={values.Contact1Title}
                                error={error && error.Contact1Title}
                                onChange={(e) => handleChange("Contact1Title", e.target.value)}
                                className="inputformdata form-control"
                                id="Title"
                                label="Title "
                                placeholder="Title "
                                wrapperClassName='mr-bt-25'
                                onBlur={() => saveAuto("Contact1Title")}

                            />
                        </div>
                        <div className="col-md-3 pd-right-45">
                            <TextField
                                required={true}
                                type="text"
                                name='Contact1Mobile'
                                value={values.Contact1Mobile && formatPhoneNumber(values.Contact1Mobile)}
                                error={error && error.Contact1Mobile}
                                onChange={(e) => handleChange("Contact1Mobile", AcceptPhoneNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="Phone"
                                label="Phone"
                                placeholder="Phone"
                                wrapperClassName='mr-bt-25'
                                onBlur={() => saveAuto("Contact1Mobile")}

                                maxLength={14}
                            />
                        </div>
                        <div className="col-md-3 pd-right-45">
                            <TextField
                                maxLength={100}
                                required={true}
                                type="text"
                                name='Contact1Email'
                                value={values.Contact1Email}
                                error={error && error.Contact1Email}
                                onChange={(e) => handleChange("Contact1Email", e.target.value)}
                                className="inputformdata form-control"
                                id="Email"
                                label="Email "
                                placeholder="Email "
                                wrapperClassName='mr-bt-25'
                                onBlur={() => saveAuto("Contact1Email")}


                            />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-3 pd-right-45">
                            <TextField
                                type="text"
                                name='Contact1Mobile'
                                value={values.Contact1Mobile && formatPhoneNumber(values.Contact1Mobile)}
                                error={error && error.Contact1Mobile}
                                onChange={(e) => handleChange("Contact1Mobile", AcceptPhoneNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="Phone"
                                label="Phone"
                                placeholder="Phone"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}
                                maxLength={10}

                            />
                        </div>
                        <div className="col-md-3 pd-right-45">
                            <TextField
                                type="text"
                                name='Contact1Email'
                                value={values.Contact1Email}
                                error={error && error.Contact1Email}
                                onChange={(e) => handleChange("Contact1Email", e.target.value)}
                                className="inputformdata form-control"
                                id="Email"
                                label="Email "
                                placeholder="Email "
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div>
                    </div>
                    {/* {
                        addBtn ? (
                            <> */}
                    {/* <div className="row">
                                    <div className="col-md-4 pd-right-45">
                                        <TextField
                                            type="text"
                                            name='Contact2Name'
                                            value={values.Contact2Name}
                                            error={error && error.Contact2Name}
                                            onChange={(e) => handleChange("Contact2Name", e.target.value)}
                                            className="inputformdata form-control"
                                            id="Name"
                                            label="Name "
                                            placeholder="Name"
                                            wrapperClassName='mr-bt-25'
                                            onBlur={saveAuto}

                                        />
                                    </div>
                                    <div className="col-md-4 pd-right-45">
                                        <TextField
                                            type="text"
                                            name='Contact2Title'
                                            value={values.Contact2Title}
                                            error={error && error.Contact2Title}
                                            onChange={(e) => handleChange("Contact2Title", e.target.value)}
                                            className="inputformdata form-control"
                                            id="Title"
                                            label="Title "
                                            placeholder="Title "
                                            wrapperClassName='mr-bt-25'
                                            onBlur={saveAuto}

                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 pd-right-45">
                                        <TextField
                                            type="text"
                                            name='Contact2Mobile'
                                            value={values.Contact2Mobile && formatPhoneNumber(values.Contact2Mobile)}
                                            error={error && error.Contact2Mobile}
                                            onChange={(e) => handleChange("Contact2Mobile", AcceptPhoneNumbers(e.target.value))}
                                            className="inputformdata form-control"
                                            id="Phone"
                                            label="Phone"
                                            placeholder="Phone"
                                            wrapperClassName='mr-bt-25'
                                            onBlur={saveAuto}
                                            maxLength={10}

                                        />
                                    </div>
                                    <div className="col-md-4 pd-right-45">
                                        <TextField
                                            type="text"
                                            name='Contact2Email'
                                            value={values.Contact2Email}
                                            error={error && error.Contact2Email}
                                            onChange={(e) => handleChange("Contact2Email", e.target.value)}
                                            className="inputformdata form-control"
                                            id="Email"
                                            label="Email "
                                            placeholder="Email "
                                            wrapperClassName='mr-bt-25'
                                            onBlur={saveAuto}

                                        />
                                    </div>
                                </div>  */}
                    {/* // ) : (
                        //     <Link className="i-href" onClick={addContactFields}><svg
                        //         xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                        //         viewBox="0 0 24 24" data-icon="material-symbols:add"
                        //         className="iconify iconify--material-symbols">
                        //         <path fill="currentColor" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"></path>
                        //     </svg>Add </Link>
                        // )

                    //} */}

                </div>
                <div className="row">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Case Manager Information <label className="cs-lab">(If
                            applicable)</label></span></h6>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='CaseManagerName'
                                value={values.CaseManagerName}
                                error={error && error.CaseManagerName}
                                onChange={(e) => handleChange("CaseManagerName", e.target.value)}
                                className="inputformdata form-control"
                                id="Nursename"
                                label="Nurse name"
                                placeholder="Nurse name"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={255}
                                type="text"
                                name='CaseManagerAddress'
                                value={values.CaseManagerAddress}
                                error={error && error.CaseManagerAddress}
                                onChange={(e) => handleChange("CaseManagerAddress", e.target.value)}
                                label="Address"
                                className="inputformdata form-control"
                                id="Address"
                                placeholder="Address"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <Select
                                name='CaseManagerState'
                                value={values.CaseManagerState}
                                error={error && error.CaseManagerState}
                                onChange={(e) => handleChange("CaseManagerState", Number(e.target.value))}
                                options={states}
                                label="State"
                                placeholder="State"
                                className='inputformdata form-control'
                                onBlur={saveAuto}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                type="text"
                                name='CaseManagerPincode'
                                value={values.CaseManagerPincode}
                                error={error && error.CaseManagerPincode}
                                onChange={(e) => handleChange("CaseManagerPincode", AcceptZipNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="Zip"
                                wrapperClassName='mr-bt-25'
                                placeholder="Zip"
                                label="Zip"
                                onBlur={saveAuto}
                                maxLength={5}

                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                type="text"
                                name='CaseManagerPhone'
                                value={values.CaseManagerPhone && formatPhoneNumber(values.CaseManagerPhone)}
                                error={error && error.CaseManagerPhone}
                                onChange={(e) => handleChange("CaseManagerPhone", AcceptPhoneNumbers(e.target.value))}
                                label="Phone"
                                className="inputformdata form-control"
                                id="Phone"
                                placeholder="Phone"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}
                                maxLength={14}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                type="text"
                                name='CaseManagerFax'
                                value={values.CaseManagerFax && formatPhoneNumber(values.CaseManagerFax)}
                                error={error && error.CaseManagerFax}
                                onChange={(e) => handleChange("CaseManagerFax", AcceptPhoneNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="Fax"
                                wrapperClassName='mr-bt-25'
                                placeholder="Fax"
                                label="Fax"
                                onBlur={saveAuto}
                                maxLength={14}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='CaseManagerEmail'
                                value={values.CaseManagerEmail}
                                error={error && error.CaseManagerEmail}
                                onChange={(e) => handleChange("CaseManagerEmail", e.target.value)}
                                className="inputformdata form-control"
                                id="Email"
                                wrapperClassName='mr-bt-25'
                                placeholder="Email"
                                label="Email"
                                onBlur={saveAuto}

                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Billing Information <label className="cs-lab">(If
                            applicable)</label></span></h6>
                    </div>

                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='BillingName'
                                value={values.BillingName}
                                error={error && error.BillingName}
                                onChange={(e) => handleChange("BillingName", e.target.value)}
                                label="Name"
                                className="inputformdata form-control"
                                id="BillingName"
                                placeholder="Name"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='BillingLine1'
                                value={values.BillingLine1}
                                error={error && error.BillingLine1}
                                onChange={(e) => handleChange("BillingLine1", e.target.value)}
                                label="Address line 1"
                                className="inputformdata form-control"
                                id="BillingLine1"
                                placeholder="Address line 1"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='BillingLine2'
                                value={values.BillingLine2}
                                error={error && error.BillingLine2}
                                onChange={(e) => handleChange("BillingLine2", e.target.value)}
                                className="inputformdata form-control"
                                id="BillingLine2"
                                wrapperClassName='mr-bt-25'
                                placeholder="Address line 2"
                                label="Address line 2"
                                onBlur={saveAuto}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='BillingCity'
                                value={values.BillingCity}
                                error={error && error.BillingCity}
                                onChange={(e) => handleChange("BillingCity", e.target.value)}
                                label="City"
                                className="inputformdata form-control"
                                id="BillingCity"
                                placeholder="City"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <Select
                                name='BillingState'
                                value={values.BillingState}
                                error={error && error.BillingState}
                                onChange={(e) => handleChange("BillingState", Number(e.target.value))}
                                options={states}
                                label="State *"
                                placeholder="State"
                                className='inputformdata form-control'
                                onBlur={saveAuto}

                            />

                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                type="text"
                                name='BillingPincode'
                                value={values.BillingPincode}
                                error={error && error.BillingPincode}
                                onChange={(e) => handleChange("BillingPincode", AcceptZipNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="BillingPincode"
                                wrapperClassName='mr-bt-25'
                                placeholder="Zip"
                                label="Zip"
                                onBlur={saveAuto}
                                maxLength={5}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                type="text"
                                name='BillingPhone'
                                value={(values && values.BillingPhone && formatPhoneNumber(values.BillingPhone)) || ''}
                                error={error && error.BillingPhone}
                                onChange={(e) => handleChange("BillingPhone", AcceptPhoneNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="BillingPhone"
                                wrapperClassName='mr-bt-25'
                                placeholder="Phone"
                                label="Phone"
                                onBlur={saveAuto}
                                maxLength={14}

                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                type="text"
                                name='BillingFax'
                                value={(values && values.BillingFax && formatPhoneNumber(values.BillingFax)) || ''}
                                error={error && error.BillingFax}
                                onChange={(e) => handleChange("BillingFax", AcceptPhoneNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="BillingFax"
                                wrapperClassName='mr-bt-25'
                                placeholder="Fax"
                                label="Fax"
                                onBlur={saveAuto}
                                maxLength={14}

                            />
                        </div>

                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={100}
                                type="text"
                                name='BillingEmail'
                                value={values.BillingEmail}
                                error={error && error.BillingEmail}
                                onChange={(e) => handleChange("BillingEmail", e.target.value)}
                                className="inputformdata form-control"
                                id="BillingEmail"
                                wrapperClassName='mr-bt-25'
                                placeholder="Email"
                                label="Email"
                                onBlur={saveAuto}

                            />
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Mailing Information</span></h6>
                    </div>

                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={255}
                                required
                                type="text"
                                name='MailingLine1'
                                value={values.MailingLine1}
                                error={error && error.MailingLine1}
                                onChange={(e) => handleChange("MailingLine1", e.target.value)}
                                label="Address line 1"
                                className="inputformdata form-control"
                                id="Addressline1"
                                placeholder="Address line 1"
                                wrapperClassName='mr-bt-25'
                                onBlur={() => saveAuto("MailingLine1")}


                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={255}
                                type="text"
                                name='MailingLine2'
                                value={values.MailingLine2}
                                error={error && error.MailingLine2}
                                onChange={(e) => handleChange("MailingLine2", e.target.value)}
                                className="inputformdata form-control"
                                id="Addressline2"
                                wrapperClassName='mr-bt-25'
                                placeholder="Address line 2"
                                label="Address line 2"
                                onBlur={saveAuto}

                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                maxLength={255}
                                required
                                type="text"
                                name='MailingCity'
                                value={values.MailingCity}
                                error={error && error.MailingCity}
                                onChange={(e) => handleChange("MailingCity", e.target.value)}
                                label="City"
                                className="inputformdata form-control"
                                id="City"
                                placeholder="City"
                                wrapperClassName='mr-bt-25'
                                onBlur={() => saveAuto("MailingCity")}


                            />
                        </div>
                        <div className="col-md-4 pd-right-45">
                            <Select
                                required={true}
                                name='MailingState'
                                value={values.MailingState}
                                error={error && error.MailingState}
                                onChange={(e) => handleChange("MailingState", Number(e.target.value))}
                                options={states}
                                label="State"
                                placeholder="State"
                                className='inputformdata form-control'
                                onBlur={() => saveAuto("MailingState")}

                            />

                        </div>
                        <div className="col-md-4 pd-right-45">
                            <TextField
                                required
                                type="text"
                                name='MailingPincode'
                                value={values.MailingPincode}
                                error={error && error.MailingPincode}
                                onChange={(e) => handleChange("MailingPincode", AcceptZipNumbers(e.target.value))}
                                className="inputformdata form-control"
                                id="Zip"
                                wrapperClassName='mr-bt-25'
                                placeholder="Zip"
                                label="Zip"
                                onBlur={() => saveAuto("MailingPincode")}

                                maxLength={5}

                            />
                        </div>
                        {/* <div className="col-md-4 pd-right-45">
                            <TextField
                                required = {false}
                                type="text"
                                name='MailingSuit'
                                value={values.MailingSuit}
                                error={error && error.MailingSuit}
                                onChange={(e) => handleChange("MailingSuit", e.target.value)}
                                label="Suite #"
                                className="inputformdata form-control"
                                id="MailingSuit"
                                placeholder="Suite #"
                                wrapperClassName='mr-bt-25'
                                onBlur={saveAuto}

                            />
                        </div> */}

                    </div>
                </div>



                <div className="footer-btn">
                    <div className="row">
                        <div className="col-md-6">
                            {/* <p className="rd-clr"><span className="iconify" data-icon="icon-park-outline:attention"></span>
                                2 documents are yet to be uploaded</p> */}
                        </div>
                        <div className="col-md-6">
                            <div className="sv-btn">
                                <button onClick={handleNext} type="button" className="btn-blue cst-blue bl-dt btnNext"
                                    data-bs-toggle="modal" data-bs-target="#view-validation">
                                    <Icon icon={"material-symbols:skip-next-sharp"} style={{ fontSize: "20px" }} />Next</button>
                                <button onClick={(e) => handleSubmit(e)} type="button" className="btn-grey cst-gry" data-bs-dismiss="modal">
                                    <Icon icon={"carbon:save"} style={{ fontSize: "20px" }} />Save</button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
})

const mapDispatchToProps = { onBoarding, getProjectCompletion }
const mapStateToProps = (state) => ({
    project: state?.facility?.projectDetails || null,
    states: state.facility && state.facility.states
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Demographics);