import React from "react";
import { Navigate } from "react-router-dom";
import moduleName from 'react';
import { connect } from 'react-redux';
const ProtectedRoute = ({ userPrevillege, children, authUser }) => {
  const token = localStorage.getItem("feathers-jwt");
  // const { authUser } = store.getState().auth;
  const role = authUser?.WMS_ProjectFacilityUserRoles[0]?.WMS_Roles?.Name;
  const isAuthenticated = token != null;
  if (!isAuthenticated) {
    return (
      <Navigate to="/login" />
    );
  }
  else {
    // const userHasRequiredRole = authUser && (userPrevillege === "all" || userPrevillege.includes(role)) ? true : false;
    // if (isAuthenticated && !userHasRequiredRole) {
    //   return <Navigate to="*" />;
    // }
    return children;
  }



};
const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  authUser: state.auth && state.auth.profile
});
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
