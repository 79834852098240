import { isEmpty, validate } from "validate.js";
import hasValue from "lodash"

const message = '^Some required information is missing or incomplete. Please correct your entries and try again.'

validate.extend(validate.validators, {
    zip: (value ) => {
        if (value == null || value === ''){
            return null
        } 
        else if(value && !/^\d{6}$/.test(value)){
                return "^Please enter a valid zip code";
            }
            return null
        },
    phone: (value) => {
        if (value == null || value === ''){
            return null
        } 
        else if(value && !/^\+?\d{10}$/.test(value)){
                return "^Please enter a valid phone number";
            }
            return null
        },

    emailID: (value ) => {
        if (value === null || value === ''){
            return null
        } 
        else if(value && !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value)){
                return "^Please enter a valid email address ";
            } else {
                return null
            }
        },
    // name: (value ) => {
    //     if (value == null || value === ''){
    //         return null
    //     } 
    //     else if(value && !/[A-Za-z]/.test(value)){
    //         return "enter a valid value";
    //     }
    //     else{
    //         return null;
    //         }
    //     },
    // state:(value ) => {
    //     if (value == null || value === ''){
    //         return null
    //     } 
    //     else if(value && !/[0-100]/.test(value)){
    //         return "enter a valid ";

    //     }
    //     else{
    //         return null;
    //         }
    //     },
    FEIN: (value ) => {
        if (value == null || value === ''){
            return null
        } 
        else if(value && !/^\+?\d{9}$/.test(value)){
                return "^Please enter a valid FEIN #";
            }
            return null
        },
        // onlyDigits: (value ) => {
        //     if (value == null || value === ''){
        //         return null
        //     } 
        //     else if(value && !/^\+?\d$/.test(value)){
        //             return "Please enter a valid number";
        //         }
        //         return null
        //     },
        webSite: (value ) => {
            if (value == null || value === ''){
                return null
            } 
            else if(value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)){
                    return "^Please enter a valid website";
                }
                return null
            },
        // datepick: (value ) => {
        //     if (value == null || value === ''){
        //         return "Please enter a valid date"
        //     } 
        //     },
    })
    validate.validators.array = (arrayItems, itemConstraints) => {
        const arrayItemErrors = arrayItems && arrayItems.reduce((errors, item, index)=> {
            if(index === 0 && arrayItems.length === 1 && item && !hasValue(item)){
                return {}
            }
    
            const error = validate(item, itemConstraints);
            if(error)error[index] = error;
            return error;
        }, {});
    
        return isEmpty(arrayItemErrors)? null : arrayItemErrors;
    };



 export const validationSchema = {
    ProviderType:{
        presence:{
            allowEmpty:false,
            message: message
        },
        // onlyDigits: true,
    },
    AccreditationAgency:{
        presence:{
            allowEmpty:false,
            message: message
        },
        // name: true,
    },
    AccreditationIssueDate:{
        presence:{
            allowEmpty:false,
        },
        // datepick: true,
    },
    AccreditationExpiryDate:{
        presence:{
            allowEmpty:false,
            message: message
        },
        // datepick: true,
    },
    ProviderLevel:{
        presence:{
            allowEmpty:false,
            message: message
        },
        // onlyDigits: true
    },
    MD1Name:{
        presence:{
            allowEmpty:false,
            message: message
        },
        // name: true
    },
    MD1Phone:{
        presence:{
            allowEmpty:false,
            message: message
        },
        phone: true,
    },
    MD1Type: {
        presence: { 
            allowEmpty: false,
            message: message
        },
        // name: true
    },
    
    MD1address: {
        presence: { 
            allowEmpty: false,
            message: message
        },
        // name: true,
        
    },
    MD1FEIN: {
        presence: { 
            allowEmpty: false,
            message: message
            },
        FEIN: true
    },
    // MD2Name:{
    //     presence:{
    //         allowEmpty:true,
    //         message: "is required"
    //     },
    //     name: true
    // },
    // MD2Phone:{
    //     presence:{
    //         allowEmpty:true,
    //         message: "is required"
    //     },
    //     phone: true,
    // },
    // MD2Type: {
    //     presence: { 
    //         allowEmpty: true,
    //         message: "is required"
    //     },
    //     name: true
    // },
    
    // MD2address: {
    //     presence: { 
    //         allowEmpty: true,
    //         message: "is required"
    //     },
    //     name: true,
        
    // },
    // MD2FEIN: {
    //     presence: { 
    //         allowEmpty: true,
    //         message: "is required"
    //     },
    //     nineDigits: true
    // },
    // MD3Name:{
    //     presence:{
    //         allowEmpty:true,
    //         message: "is required"
    //     },
    //     name: true
    // },
    // MD3Phone:{
    //     presence:{
    //         allowEmpty:true,
    //         message: "is required"
    //     },
    //     phone: true,
    // },
    // MD3Type: {
    //     presence: { 
    //         allowEmpty: true,
    //         message: "is required"
    //     },
    //     name: true
    // },
    
    // MD3address: {
    //     presence: { 
    //         allowEmpty: true,
    //         message: "is required"
    //     },
    //     name: true,
        
    // },
    // MD3FEIN: {
    //     presence: { 
    //         allowEmpty: true,
    //         message: "is required"
    //     },
    //     nineDigits: true
    // },
    FacilityAgeRange: {
        presence: { 
            allowEmpty: false,
            message: message
        },
        // onlyDigits: true
    },
  

    // SpecialServiceOffSiteNote: {
    //     presence: { 
    //         allowEmpty: false,
    //     },
    //     name: true
        
    // },
    // SpecialServiceOnSiteNote: {
    //     presence: { 
    //         allowEmpty: false,
    //         message: "is required"
    //     },
    //     name: true
    // },
    // BehavioralQuestions: {
    //     presence: { 
    //         allowEmpty: false,
    //         message: "is required"
    //     },
    //     name: true
    // },
    // AdditionalQuestions: {
    //     presence: { 
    //         allowEmpty: true,
    //          message: "is required"
    //     },
    //     name: true,
    // },
}

export const datavalidationSchema = {
    MD1Phone:{
        phone: true,
    },
    MD1FEIN: {
        FEIN: true
    },
}

export const AncillarySchema = {
    AncillaryList: {
        array: {
            Type:{
                   presence: { 
                      allowEmpty: false,
                      message: message,
                   },
                   name: true
                },
                ProviderName: {
                presence: { 
                   allowEmpty: false,
                   message: message,
                },
             },
        },
    },
}

