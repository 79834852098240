import { Icon } from "@iconify/react";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { validate } from "validate.js";
import _ from "lodash";
import { validationSchema, datavalidationSchema, AncillarySchema } from "./schema";
import DatePickerComponent from "../../../../components/Common/DatePicker";
import TextField from "../../../../components/Common/TextInput";
import { onBoarding, getQuestionsServices, removeProjectFacilityAnswers, removeAncillaryService, totalHealth, getProjectCompletion } from "../../../../store/facilityProfiling/action";
import { MessageType, FacilityUserForm, AnswerType, ProviderLevel, ProviderType, } from "../../../../util/enum";
import { AcceptFEINNumbers, AcceptPhoneNumbers, formatFEIN, formatPhoneNumber, formatServices } from "../../../../util/helper";
import Select from "../../../../components/Common/Select";
import Loader from '../../../../components/Common/SpinnerLoader';
import moment from "moment";
import { UpdateServicesPercentage } from "../../../../util/progressUtil";

const Services = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      hasError,
      validateForm,

    };
  });
  const { onBoarding, project, getQuestionsServices,selected, setSelected, setType, setMessage, Tab, totalHealth, removeProjectFacilityAnswers, removeAncillaryService, setSaveAutoCalled, setViewFields, setInvalidAlert, ProjectId, getProjectCompletion } = props;
  const ID = project?.ID || 0;

  const [behavioralQuestions, setBehavioralQuestions] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const behaviorQ_IDs = [1, 2, 3, 4, 5, 6];
  const [Ancillaryservices, setAncillaryServices] = useState([{Type:'', ProviderName:'', ID:0}]);
  const [values, setvalues] = useState({});
  const [error, setError] = useState({});
  const [startDate, setStartDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [questions, setQuestions] = useState([]);
  const [inputFields, setInputFields] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getQuestionsServices({ Tab: FacilityUserForm.SERVICES });
      setQuestions(response);
    };
    fetchData();
  }, []);


  useEffect(() => {
    async function fetchData(project) {
      if (project) {
        const res = formatServices(project);
        const progress = await getProjectCompletion(ProjectId);
        res.CompletionPercentage = progress;
        res.ProjectId = ProjectId
        if (res) {
          setvalues(res);
          if (res?.FacilityProfileAnswers && res?.FacilityProfileAnswers.length) {
            setSelectedCheckboxes(res?.FacilityProfileAnswers.filter((e) => e && [7, 8].includes(e.QuestionId)));
            setBehavioralQuestions(res?.FacilityProfileAnswers.filter((e) => e && behaviorQ_IDs.includes(e.QuestionId)));
          } else {
            setSelectedCheckboxes([])
            setBehavioralQuestions([])
          }
          if (res?.AncillaryServices) {
            if (!_.isEmpty(res?.AncillaryServices)) { setAncillaryServices(res?.AncillaryServices) };
          }

          let inputF = new Array(3).fill({});
          inputF = inputF.map((e, i) => ({
            MDName: res[`MD${i + 1}Name`],
            MDPhone: res[`MD${i + 1}Phone`],
            MDType: res[`MD${i + 1}Type`],
            MDaddress: res[`MD${i + 1}address`],
            MDFEIN: res[`MD${i + 1}FEIN`],
          })).filter((e) => e.MDName || e.MDPhone || e.MDType || e.MDaddress || e.MDFEIN);

          if (inputF && inputF.length) { setInputFields(inputF); }
          else {
            setInputFields([{ MDName: "", MDPhone: "", MDType: "", MDaddress: "", MDFEIN: "", },]);
          }
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        }
      }
    }
    fetchData(project)
  }, [project]);


  useEffect(() => {
    async function fetchData() {
        if (selected && selected.ServicesNav) { 
            const progress = await getProjectCompletion(ProjectId); 
            setvalues({...values, CompletionPercentage:progress}); 
        }
    } 
    fetchData()
}, [selected]);

  const resetFields = async (response) => {
    const res = formatServices(response);
    const progress = await getProjectCompletion(ProjectId);
    res.CompletionPercentage = progress;
    res.ProjectId = ProjectId
    if (res) {
      setvalues(res);
      if (res?.FacilityProfileAnswers && res?.FacilityProfileAnswers.length) {
        setSelectedCheckboxes(res?.FacilityProfileAnswers.filter((e) => e && [7, 8].includes(e.QuestionId)));
        setBehavioralQuestions(res?.FacilityProfileAnswers.filter((e) => e && behaviorQ_IDs.includes(e.QuestionId)));
      } else {
        setSelectedCheckboxes([])
        setBehavioralQuestions([])
      }
      if (res?.AncillaryServices) {
        if (_.isEmpty(res.AncillaryServices)) { setAncillaryServices([{ Type: "", ProviderName: "", },]); } else setAncillaryServices(res?.AncillaryServices);
      }
    }
  }
  const handleAncillaryChange = (index, field, value) => {
    let tempArr = [...Ancillaryservices];
    // if (!tempArr[index].ID) {
    //   tempArr[index].ID = 0;
    // } 
    tempArr[index] = { ...tempArr[index], [field]: value };
    setAncillaryServices(tempArr);
  };
  const saveAuto = async (field) => {
    const facilityvalues = [...behavioralQuestions, ...selectedCheckboxes];
    let AncillaryservicesID = Ancillaryservices.map((item, index) => {
      if (!item.hasOwnProperty('ID')) {
        return {
          ...item,
          ID: 0
        }
      }
      return item;
    }).filter(item => !(item.ProviderName === "" && item.Type === "" && item.ID === 0));
    let data = {
      ...values,
      FacilityProfileAnswers: facilityvalues,
      AncillaryServices: AncillaryservicesID,
    };
    data = await UpdateServicesPercentage(data, field);
    let health = await totalHealth(data.CompletionPercentage);
    data = { ...data, ...health };
    let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.SERVICES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.SERVICES)[0].ProjectId > 0)
      && data.FacilityProfileAnswers.filter(d => d.ID === 0).length === 0 && data.AncillaryServices.filter(d => d.ID === 0).length === 0);
    const response = await onBoarding(data, ID, true);
    if (response) {
      if (!skipDispatch) {
        resetFields(response)
      }
      setSaveAutoCalled(response);
    }
  };

  const handleAncillaryAdd = async() => {
    const allElementsValid = Ancillaryservices.every((i) => i.ProviderName !== "" && i.Type !== "");
    if(allElementsValid){
      setAncillaryServices([...Ancillaryservices, { Type: '', ProviderName: '' }]);
    } else {
      let errors = await validate({ AncillaryList: Ancillaryservices }, AncillarySchema);
      setError(errors);
    }
  }

  const handleDelete = async (item = {}, index) => {
    let tempArr = [...Ancillaryservices]
    if (item && item.ID) await removeAncillaryService(item.ID)
    tempArr.splice(index, 1)
    tempArr && tempArr.length === 0 ? setAncillaryServices([{Type:'', ProviderName:'', ID:0}]) : setAncillaryServices([...tempArr])
  }
 

  const handleChange = (field, value) => {
    if (field === "ProviderType" || field === "ProviderLevel") {
      if (value !== "" || value !== undefined || value !== null)
        return setvalues({ ...values, [field]: Number(value) });
      else return setvalues({ ...values, [field]: null });
    }
    setvalues({ ...values, [field]: value });
    return;
  };

  const handleDateChange = async (date, field) => {
    let data = { ...values, AccreditationExpiryDate: date }
    setExpiryDate(date);
    setvalues(data);
    data = await UpdateServicesPercentage(data, field);
    let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.SERVICES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.SERVICES)[0].ProjectId > 0))
    let health = await totalHealth();
    data = { ...data, ...health };
    const res = await onBoarding(data, ID, skipDispatch);
    if (res) {
      setSaveAutoCalled(res);
    }
  };

  const handleIssueDate = async (date, field) => {
    setStartDate(date);
    let data = { ...values, [field]: date };
    setvalues(data);
    data = await UpdateServicesPercentage(data, field);
    let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.SERVICES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.SERVICES)[0].ProjectId > 0))
    let health = await totalHealth();
    data = { ...data, ...health };
    const res = await onBoarding(data, ID, skipDispatch);
    if (res) {
      setSaveAutoCalled(date);
    }
  };


  const handleFormChange = (index, field, value,) => {
    let formData = [...inputFields];
    formData[index][field] = value;
    setInputFields(formData);
    setvalues({
      ...values,
      MD1Name: inputFields[0]?.MDName,
      MD1Phone: inputFields[0]?.MDPhone,
      MD1Type: inputFields[0]?.MDType,
      MD1address: inputFields[0]?.MDaddress,
      MD1FEIN: inputFields[0]?.MDFEIN,
      MD2Name: inputFields[1]?.MDName,
      MD2Phone: inputFields[1]?.MDPhone,
      MD2Type: inputFields[1]?.MDType,
      MD2address: inputFields[1]?.MDaddress,
      MD2FEIN: inputFields[1]?.MDFEIN,
      MD3Name: inputFields[3]?.MDName,
      MD3Phone: inputFields[3]?.MDPhone,
      MD3Type: inputFields[3]?.MDPhone,
      MD3address: inputFields[3]?.MDaddress,
      MD3FEIN: inputFields[3]?.MDFEIN,
    });
  };

  const addFields = () => {
    let newfield = {
      MDName: "",
      MDPhone: "",
      MDType: "",
      MDaddress: "",
      MDFEIN: "",
    };
    setInputFields([...inputFields, newfield]);
  };
  const handleNext = () => {
    props.setSelected({
      Demographics: "",
      DemographicsNav: false,
      DemoActive: "",
      Services: "",
      ServicesNav: false,
      ServicesActive: "",
      OwnerShip: "show active",
      OwnerShipNav: true,
      OwnerShipActive: "active",
    });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  };

  const hasError = async () => {
    let errors = await validate(values, validationSchema);
    let arrayItemErrors = {}
    let questionIds = questions.map(q => q.ID);
    questionIds.map((q, i) => {
      if (i < 6) {
        if ((behavioralQuestions.filter(a => a.QuestionId === q).length === 0) || (behavioralQuestions.filter(a => a.QuestionId === q).length && _.isEmpty(behavioralQuestions.filter(a => a.QuestionId === q)[0].Answer))) {
          if (!arrayItemErrors[i]) {
            arrayItemErrors[i] = {}
          }
          arrayItemErrors[i].FacilityProfileAnswers = ["Please fill in the required fields"]
        } else {
          arrayItemErrors[i] = {}
        }
      }
    })
    let allEmpty = Object.keys(arrayItemErrors).every(function (key) {
      return _.isEmpty(arrayItemErrors[key])
    })
    if (errors) {
      return errors
    }
    return !allEmpty
  }

  const validateForm = async (viewFields = false) => {
    let errors = await validate(values, validationSchema);
    let arrayItemErrors = {}
    let questionIds = questions.map(q => q.ID);
    questionIds.map((q, i) => {
      if (i < 6) {
        if ((behavioralQuestions.filter(a => a.QuestionId === q).length === 0) || (behavioralQuestions.filter(a => a.QuestionId === q).length && _.isEmpty(behavioralQuestions.filter(a => a.QuestionId === q)[0].Answer))) {
          if (!arrayItemErrors[i]) {
            arrayItemErrors[i] = {}
          }
          arrayItemErrors[i].FacilityProfileAnswers = ["Please fill in the required fields"]
        } else {
          arrayItemErrors[i] = {}
        }
      }
    })
    setError({ ...errors, ...arrayItemErrors });
    let allEmpty = Object.keys(arrayItemErrors).every(function (key) {
      return _.isEmpty(arrayItemErrors[key])
    })
    if (errors || !allEmpty) {
      const message = errors && errors[Object.keys(errors)[0]][0]
      setType(MessageType.Error);
      setMessage(message);
      setViewFields(viewFields)
      setInvalidAlert(false)
      return false;
    } else {
      return true;
    }
  };

  const dataValidateForm = async (viewFields = false) => {
    let errors = await validate(values, datavalidationSchema);
    setError({ ...errors });
    if (errors) {
      const message = errors[Object.keys(errors)[0]][0]
      setType(MessageType.Error);
      setMessage(message);
      setInvalidAlert(true)
      return false;
    } else {
      return true;
    }
  };



  const handleBehavioralChange = (e, QuestionId) => {
    const Answer = e.target.value;
    const index = behavioralQuestions.findIndex(item => item.QuestionId === QuestionId);
    if (index > -1) {
      const newState = [...behavioralQuestions];
      newState[index].Answer = Answer;
      setBehavioralQuestions(newState)
    } else {
      setBehavioralQuestions(prev => [...prev, { QuestionId, Answer, ID: 0 }])
    }

  };

  const handleCheckboxChange = async (event, { QuestionId, OptionId }) => {
    // const checkboxValue = +event.target.value;
    const isChecked = event.target.checked;
    const item = selectedCheckboxes.find((e) => e.OptionId === OptionId);
    let data = {}
    if (isChecked) {
      if (item && item.ID) {
        const index = selectedCheckboxes.findIndex((e) => e.ID === item.ID)
        let tempValues = [...selectedCheckboxes];
        tempValues[index] = {
          ...tempValues[index],
          QuestionId,
          OptionId,
        };
        data = {
          ...values,
          FacilityProfileAnswers: [...behavioralQuestions, ...tempValues],
        };
        setSelectedCheckboxes([...tempValues]);
      } else {
        setSelectedCheckboxes((prevState) => [
          ...prevState,
          { QuestionId, OptionId },
        ]);
        data = {
          ...values,
          FacilityProfileAnswers: [...behavioralQuestions, ...selectedCheckboxes, { QuestionId, OptionId }],
        }
      }
      let health = await totalHealth();
      data = { ...data, ...health };
      await onBoarding(data, ID);
    }
    if (!isChecked) {
      if (item && item.ID) {
        await removeProjectFacilityAnswers(item.ID);
        let filter = selectedCheckboxes.filter((e) => !(e.OptionId === item.OptionId))
        setSelectedCheckboxes(filter);
      } else {
        setSelectedCheckboxes((prevState) =>
          prevState.filter(
            (item) =>
              !(item.QuestionId === QuestionId && item.OptionId === OptionId)
          )
        );
      }
    }
    if (QuestionId) {
      setSaveAutoCalled(QuestionId);
    }

    // saveAuto();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const facilityvalues = [...behavioralQuestions, ...selectedCheckboxes];
    let isValid = await dataValidateForm()
    if (isValid) {
      setType(MessageType.Success);
      setMessage && setMessage("Your changes have been successfully saved!");
    }
  };

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <Loader loading={loading} height={'20rem'} width={'20rem'} />
      </div>
    )
  }

  return (
    <div>
      <div className="form-start">
        <div className="row">
          <div className="col-md-12 personal-info">
            <h6 className="line-after pr-info">
              <span>Provider Type</span>
            </h6>
          </div>
          <div className="create-patient-form">
            <div className="row">
              <div className="col-md-4 pd-right-45">
                <Select
                  required
                  options={ProviderType.getKeyValuePairs()}
                  value={values.ProviderType || null}
                  error={error && error.ProviderType}
                  onChange={(e) => handleChange("ProviderType", Number(e.target.value))}
                  name='ProviderType'
                  id={'ProviderType'}
                  label="Provider type"
                  placeholder="Provider type"
                  className="inputformdata form-control"
                  onBlur={() => saveAuto("ProviderType")}

                />
              </div>
              <div className="col-md-4 pd-right-45">
                <TextField
                  required
                  type="text"
                  name="AccreditationAgency"
                  value={values.AccreditationAgency}
                  error={error && error.AccreditationAgency}
                  className="inputformdata form-control"
                  id="accreditation"
                  label="Accreditation agency"
                  placeholder="Accreditation agency"
                  wrapperClassName="mr-bt-35"
                  onChange={(e) => handleChange("AccreditationAgency", e.target.value)}
                  onBlur={() => saveAuto("AccreditationAgency")}

                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pd-right-45">
                <div className='inputformContainer'>
                  <DatePickerComponent
                    name="AccreditationIssueDate"
                    selected={startDate || ''}
                    required={true}
                    onChange={(date) => handleIssueDate(date, "AccreditationIssueDate")}
                    value={values.AccreditationIssueDate ? moment(values?.AccreditationIssueDate).format("MM/DD/YYYY") : ""}
                    error={error && error.AccreditationIssueDate}
                    wrapperClassName={`inputformContainer mr-bt-25  ${error && error.AccreditationIssueDate ? "redborder" : ""}`}
                    placeholder="Date Issued"
                    label="Date Issued"
                    placeholderText="Date Issued"
                    onBlur={() => saveAuto("AccreditationIssueDate")}
                  />
                </div>
              </div>

              <div className="col-md-4 pd-right-45">
                <div className='inputformContainer'>
                  <DatePickerComponent
                    name="AccreditationExpiryDate"
                    selected={expiryDate || ''}
                    required={true}
                    onChange={(date) => handleDateChange(date, "AccreditationExpiryDate")}
                    value={values.AccreditationExpiryDate ? moment(values?.AccreditationExpiryDate).format("MM/DD/YYYY") : ""}
                    error={error && error.AccreditationExpiryDate}
                    wrapperClassName={`inputformContainer mr-bt-25  ${error && error.AccreditationExpiryDate ? "redborder" : ""}`}
                    placeholder="Expiry Date"
                    label="Expiry Date"
                    placeholderText="Expiry Date"
                    id="expiry-date"
                    onBlur={() => saveAuto("AccreditationExpiryDate")}
                    futureDateOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 pd-right-45">
                {/* <TextField
                  required
                  type="text"
                  name="ProviderLevel"
                  value={values.ProviderLevel}
                  error={error && error.ProviderLevel}
                  className="inputformdata form-control"
                  id="provider-level"
                  label="Provider level of care *"
                  placeholder="Provider level of care"
                  wrapperClassName="mr-bt-35"
                  onChange={(e) =>
                    handleChange("ProviderLevel", e.target.value)
                  }
                  onBlur={() => saveAuto()}
                /> */}
                <Select
                  options={ProviderLevel.getKeyValuePairs()}
                  value={values.ProviderLevel || null}
                  error={error && error.ProviderLevel}
                  onChange={(e) =>
                    handleChange("ProviderLevel", Number(e.target.value))
                  }
                  name='ProviderLevel'
                  id={'ProviderLevel'}
                  label="Provider level of care"
                  placeholder="Provider level of care"
                  onBlur={() => saveAuto("ProviderLevel")}

                  required

                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 personal-info mt-4">
            <h6 className="line-after pr-info">
              <span>Ancillary Services<label className="cs-lab px-2"> ( If
                applicable )</label></span>
            </h6>
          </div>
          <div className="create-patient-form">
            {Ancillaryservices?.map((input, index) => {
              return (<>
                <div className="row d-flex" key={index}>
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      // required
                      type="text"
                      name="Type"
                      value={input.Type}
                      error= { (error &&
                        error &&
                        error.AncillaryList &&
                        error.AncillaryList[0] &&
                        error.AncillaryList[0][index] &&
                        error.AncillaryList[0][index]?.Type) ||
                      null}                    
                      className="inputformdata form-control"
                      id="Servicetype"
                      label="Service type"
                      placeholder="Service type"
                      wrapperClassName="mr-bt-35"
                      onChange={(event) => {
                        handleAncillaryChange(index, 'Type', event.target.value);
                      }}
                      onBlur={() => saveAuto()}
                    />
                  </div>
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      // required
                      type="text"
                      name="ProviderName"
                      value={input.ProviderName}
                      error= { (error &&
                        error &&
                        error.AncillaryList &&
                        error.AncillaryList[0] &&
                        error.AncillaryList[0][index] &&
                        error.AncillaryList[0][index]?.ProviderName) ||
                      null}                     
                      className="inputformdata form-control"
                      id="ProviderName"
                      label="Provided by"
                      placeholder="Provided by"
                      wrapperClassName="mr-bt-35"
                      onChange={(event) => {
                        handleAncillaryChange(index, 'ProviderName', event.target.value);
                      }}
                      onBlur={() => saveAuto()}
                    />
                  </div>

                  {/* {index !== 0 && */}
                    {/* ( */}
                      <div className="col-md-2">
                      <Icon icon={"fluent:delete-28-regular"} className="m-2 cursor-pointer" width="20" height="20" onClick={async () => await handleDelete(input, index)} />
                    </div>
                    {/* )} */}
                </div>
              </>
              );
            })}
          </div>
          <div className="col-md-4">
            <Link className="i-href w5" onClick={handleAncillaryAdd}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                data-icon="material-symbols:add"
                className="iconify iconify--material-symbols"
              >
                <path
                  fill="currentColor"
                  d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"
                ></path>
              </svg>
              Add{" "}
            </Link>          </div>

        </div>
        <div className="row">
          <div className="col-md-12 personal-info">
            <h6 className="line-after pr-info mt-3">
              <span>Medical Director Information</span>
            </h6>
          </div>
          {inputFields?.map((input, index) => {
            ''
            return (
              <>
                <div className="row">
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      required={index === 0}
                      type="text"
                      name={`MD${index + 1}Name`}
                      value={input.MDName}
                      error={error && error?.[`MD${index + 1}Name`]}
                      className="inputformdata form-control"
                      id="provider"
                      label="Name"
                      placeholder="Name"
                      wrapperClassName="mr-bt-25"
                      onChange={(event) => handleFormChange(index, 'MDName', event.target.value)}
                      onBlur={() => saveAuto(`MD${index + 1}Name`)}

                    />
                  </div>
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      required={index === 0}
                      type="text"
                      value={input.MDType}
                      name={`MD${index + 1}Type`}
                      error={error && error?.[`MD${index + 1}Type`]}
                      className="inputformdata form-control"
                      id="medicine-practice"
                      label="Type of medicine practice"
                      placeholder="Type of medicine practice"
                      wrapperClassName="mr-bt-25"
                      onChange={(event) => handleFormChange(index, 'MDType', event.target.value)}
                      onBlur={() => saveAuto(`MD${index + 1}Type`)} />
                  </div>
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      required={index === 0}
                      type="text"
                      name={`MD${index + 1}FEIN`}
                      error={error && error?.[`MD${index + 1}FEIN`]}
                      value={input.MDFEIN && formatFEIN(input.MDFEIN)}
                      className="inputformdata form-control"
                      id="FEIN"
                      label="FEIN #"
                      placeholder="XX-XXXXXXX"
                      wrapperClassName="mr-bt-25"
                      maxLength={10}
                      onChange={(event) => handleFormChange(index, 'MDFEIN', AcceptFEINNumbers(event.target.value))}
                      onBlur={() => saveAuto(`MD${index + 1}FEIN`)} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      required={index === 0}
                      type="text"
                      name={`MD${index + 1}address`}
                      value={input.MDaddress}
                      error={error && error?.[`MD${index + 1}address`]}
                      className="inputformdata form-control"
                      id="address"
                      label="Address"
                      placeholder="Address"
                      wrapperClassName="mr-bt-25"
                      onChange={(event) => handleFormChange(index, 'MDaddress', event.target.value)}
                      onBlur={() => saveAuto(`MD${index + 1}address`)}
                    />
                  </div>
                  <div className="col-md-4 pd-right-45">
                    <TextField
                      required={index === 0}
                      type="text"
                      name={`MD${index + 1}Phone`}
                      value={input.MDPhone && formatPhoneNumber(input.MDPhone)}
                      error={error && error?.[`MD${index + 1}Phone`]}
                      className="inputformdata form-control"
                      label="Phone"
                      placeholder="Phone "
                      wrapperClassName="mr-bt-25"
                      onChange={(event) => handleFormChange(index, 'MDPhone', AcceptPhoneNumbers(event.target.value))}
                      onBlur={() => saveAuto(`MD${index + 1}Phone`)}
                      maxLength={14}
                    />
                  </div>

                </div>
              </>
            );
          })}

          {/* {inputFields?.length < 3 && (
            <Link 
            className="i-href" 
            onClick={addFields}>
              <Icon width="1em" height="1em" icon={"material-symbols:add"} 
              className={"iconify iconify--material-symbols"} />
              Add{" "}
            </Link>
          )} */}
        </div>

        <div className="row">
          <div className="col-md-12 personal-info">
            <h6 className="line-after pr-info">
              <span>Facility Hours</span>
            </h6>
          </div>
          <div className="row">
            <div className="col-md-8 pd-right-45">
              <div className="inputformContainer inputtextarea ">
                <textarea
                  rows="6"
                  cols="20"
                  className={`form-control ad-textarea ${error && error.SpecialServiceOnSiteNote ? "redborder" : ""}`}
                  id="special-service"
                  placeholder=" "
                  // required
                  name="SpecialServiceOnSiteNote"
                  onChange={(e) => handleChange("SpecialServiceOnSiteNote", e.target.value)}
                  value={values.SpecialServiceOnSiteNote || ""}
                  error={error && error.SpecialServiceOnSiteNote}
                  onBlur={() => saveAuto()}></textarea>
                <label htmlFor="special-service" className="label ">
                  Special services provided onsite
                </label>
              </div>
            </div>

            <div className="col-md-8 pd-right-45">
              <div className="inputformContainer inputtextarea ">
                <textarea
                  rows="6"
                  cols="20"
                  className={`form-control ad-textarea ${error && error.SpecialServiceOffSiteNote ? "redborder" : ""}`}
                  id="special-service1"
                  placeholder=" "
                  // required
                  name="SpecialServiceOffSiteNote"
                  value={values.SpecialServiceOffSiteNote || ""}
                  onChange={(e) => handleChange("SpecialServiceOffSiteNote", e.target.value)}
                  error={error && error.SpecialServiceOffSiteNote}
                  onBlur={() => saveAuto()} >

                </textarea>
                <label htmlFor="special-service1" className="label ">
                  Special services provided offsite
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pd-right-45">
              <TextField required type="text"
                name="FacilityAgeRange" value={values.FacilityAgeRange} error={error && error.FacilityAgeRange}
                className="inputformdata form-control" id="age-range" label="Facility age range"
                placeholder="Facility age range "
                wrapperClassName="mr-bt-25"
                maxLength={30}
                onChange={(e) => handleChange("FacilityAgeRange", e.target.value)}
                onBlur={() => saveAuto("FacilityAgeRange")}

              />
            </div>
          </div>
        </div>
        {questions && _.map(questions, (item, i) => {
          if (item && item?.AnswerType == AnswerType?.TEXTINPUT && behaviorQ_IDs.includes(item.ID)
          ) {
            const questionAnswer = behavioralQuestions?.find((q) => q?.QuestionId === item?.ID);
            const value = questionAnswer ? questionAnswer?.Answer : "";
            let hasError = error && error[i] && error[i].FacilityProfileAnswers
            return (
              <>
                <div className="row">
                  {i === 0 && (
                    <div className="col-md-12 personal-info">
                      <h6 className="line-after pr-info">
                        <span>Behavioral Health Servicing Questions</span>
                      </h6>
                    </div>
                  )}

                  <div className="row servicescontainer">
                    <p>{item.Text}<span className='text-danger'>*</span> </p>
                    <div className="col-md-8 pd-right-45">
                      <TextField
                        required
                        requiredLabel={false}
                        type="text"
                        name="FacilityProfileAnswers"
                        value={value}
                        error={hasError}
                        className="inputformdata form-control"
                        id="age-range"
                        label={""}
                        placeholder={""}
                        wrapperClassName="mr-bt-25"
                        onChange={(e) => handleBehavioralChange(e, item.ID)}
                        onBlur={() => saveAuto(i, 'FacilityProfileAnswers')}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }

          if (item && item?.AnswerType == AnswerType?.CHECKBOX && [7].includes(item.ID)) {
            return (
              <>
                <div className="row">
                  <div className="col-md-12 personal-info">
                    <h6 className="line-after pr-info">
                      <span>Additional Questions</span>
                    </h6>
                  </div>

                  <div className="row">
                    <h4 className="frm-h">{item.Text}</h4>
                    {item?.WMS_FacilityAnswerOptions && item?.WMS_FacilityAnswerOptions?.map((opt, i) => {
                      return (
                        <>
                          <div className="col-md-12 pd-right-45 cst-checkbox">
                            <div className="form-check form-check-ct">
                              <input

                                className="form-check-input"
                                id={`FacilityProfileAnswers_${i}`}
                                name="FacilityProfileAnswers_"
                                error={error && error.FacilityProfileAnswers}
                                type="checkbox"
                                checked={selectedCheckboxes.filter((e) => e.OptionId === opt.ID).length > 0}
                                onChange={async (e) => {
                                  await handleCheckboxChange(e, {
                                    QuestionId: item.ID, OptionId: opt.ID,
                                  });
                                }} />
                              <label htmlFor={`FacilityProfileAnswers_${i}`} className="form-check-label form-check-ct-label" >
                                {opt?.Text}
                              </label>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            );
          }

          if (item && item?.AnswerType == AnswerType?.CHECKBOX && [8].includes(item?.ID)
          ) {
            return (
              <>
                <div className="row">
                  <h4 className="frm-h">{item.Text}</h4>
                  {item?.WMS_FacilityAnswerOptions && item?.WMS_FacilityAnswerOptions.map((opt, i) => {
                    return (
                      <>
                        <div className="col-md-12 pd-right-45 cst-checkbox">
                          <div className="form-check form-check-ct">
                            <input
                              id={`FacilityAnswers${i}`}
                              className="form-check-input"
                              name="FacilityAnswers"
                              checked={selectedCheckboxes.filter((e) => e.OptionId === opt.ID).length > 0}
                              error={error && error.FacilityProfileAnswers}
                              type="checkbox"
                              onChange={(e) => {
                                handleCheckboxChange(e, { QuestionId: item.ID, OptionId: opt.ID, });
                              }} />
                            <label htmlFor={`FacilityAnswers${i}`} className="form-check-label form-check-ct-label" >
                              {opt.Text}
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            );
          }

          return <></>;
        })}

        <div className="footer-btn">
          <div className="row">
            <div className="col-md-6">
              {/* <p className="rd-clr"><span className="iconify" data-icon="icon-park-outline:attention"></span>
                  2 documents are yet to be uploaded</p> */}
            </div>
            <div className="col-md-6">
              <div className="sv-btn">
                <button onClick={handleNext} type="button" className="btn-blue cst-blue bl-dt btnNext"
                  data-bs-toggle="modal" data-bs-target="#view-validation" >
                  <Icon icon={"material-symbols:skip-next-sharp"} style={{ fontSize: "20px" }} />
                  Next
                </button>
                <button onClick={handleSubmit} type="button" className="btn-grey cst-gry" data-bs-dismiss="modal" >
                  <Icon icon={"carbon:save"} style={{ fontSize: "20px" }} />
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
const mapDispatchToProps = {
  onBoarding,
  getQuestionsServices,
  removeProjectFacilityAnswers,
  removeAncillaryService,
  getProjectCompletion
};
const mapStateToProps = (state) => ({
  project: state?.facility && state?.facility?.projectDetails || null
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Services);
