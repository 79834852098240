import React from 'react' 
import image from "../../assets/img"

const PublicLayout = ({ children}) => {
    return (
        <section className="guestlogin">
            <img src={image.guestImg} className="doodle-dl" />
            <img src={image.doodle2} className="doodle-dr" />
            {children}
        </section>)
}

export default PublicLayout;
