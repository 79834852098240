function Enum() {
  this.self = arguments[0];
}
Enum.prototype = {
  keys: function () {
    return Object.keys(this.self);
  },
  values: function () {
    var me = this;
    return this.keys(this.self).map(function (key) {
      return me.self[key];
    });
  },
  getKeyValuePairs: function () {
    var me = this;
    return this.keys(this.self).map(function (key) {
      return { name: key, value: me.self[key] };
    });
  },
  getValueByName: function (key) {
    return this.self[
      this.keys(this.self)
        .filter(function (k) {
          return key.toString() === k;
        })
        .pop() || ""
    ];
  },
  getNameByValue: function (value) {
    var me = this;
    return (
      this.keys(this.self)
        .filter(function (k) {
          return me.self[k] === value;
        })
        .pop() || null
    );
  },
};

export const UserStatus = {
  Inactive: 0,
  Active: 1,
  Deleted: 2,
};

export const RoleType = {
  Admin: 1,
  Guest: 2,
};
export const RoleTypeDesc = new Enum({
  Admin: 1,
});

export const UserStatusDesc = new Enum({
  Inactive: 0,
  Active: 1,
});

export const gender = {
  Select: 0,
  Male: 1,
  Female: 2,
  Other: 3,
};

export const GenderDesc = new Enum({
  1: "Male",
  2: "Female",
  3: "Other",
});

export const MessageType = {
  Success: "Success",
  Error: "Error",
};

export const corporateStatus = new Enum({
  INC: 2,
  LLC: 1,
});

export const FacilityUserForm = {
  DEMOGRAPHICS: 1,
  SERVICES: 2, 
  OWNERSHIPS: 3,
  INSURANCES: 4,
  PAYROLL: 5,
  DOCS: 6,
};
export const HealthStatus = {
  ATRISK:1,
  REQUIESATTENTION:2,
  ONTRACK: 3
};
export const AnswerType = {
  TEXTINPUT: 1,
  RADIO: 2,
  CHECKBOX: 3,
  SELECT: 4,
  LABEL: 5,
}
export const PayFrequency = {
  Biweekly: 'Biweekly',
  Daily: 'Daily',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Semimonthly: "Semimonthly",
  Weekly: "Weekly"
}

export const ProjectStatus = {
  Open: 1,
  InProgress: 2,
  Abandoned: 3,
  OnHold: 4,
  Completed: 5
}

export const radioAnswerTytpe = {
  True: 'true',
  False: 'false',
}


export const ProviderType = new Enum({
  "Adult Homes": 4,
  "Assisted Living Program (ALP)": 7,
  "Congregate Living Health Facility": 1,
  "Continuing Care Retirement Communities": 8,
  "Enriched Housing": 5,
  "Family-Type Homes": 6,
  "Independent Living Apartments": 3,
  "Nursing Home (Skilled Nursing Facility)": 2, 
});
export const ProviderLevel = new Enum({
  "Congregate Living Health Facility": 4,
  "Intermediate Care/Developmentally Disabled": 3,
  "Skilled Nursing Facility": 2,
  "Subacute Care": 1,
});
export const UserType = {
  "Admin": 1,
  "FO": 2
} ;
export const CategoryType={
  "Onboarding Documents":1,
  "Facility Profile":2,
  "Facility Binder":3
}
