import React, { useState, useEffect} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/index";
import store  from "./store";
import ScrollToTop from "./ScrollToTop";
import { Loading } from "./components/Common/Loader";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import { isAlive } from "./store/auth/action";
// Importing the Bootstrap CSS 
import 'jquery/dist/jquery.min.js'
import "../src/assets/css/bootstrap.min.css"
import "../src/scss/app.scss" 

import 'bootstrap/dist/js/bootstrap.min.js'

const browserHistory = createBrowserHistory();

// Bind persist store with redux store
const persistor = persistStore(store);

const App = () => {
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => setLoaded(true), 500);
  }, []);

  const onBeforeLift = async () => {
    const res = await isAlive();
    // let res =true;
    const path = browserHistory.location.pathname;
    if (res) {
      if (path === "/" || path === "/login") {
        browserHistory.push("/dashboard");
        return true;
      }
      return true
    }
    if (path === "/") browserHistory.push("/login");
    return true
  };

  const loader = () => {
    const loadStyle = {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: "75px",
      height: "75px",
    };

    return <span style={loadStyle}></span>;
  };

  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        onBeforeLift={() => onBeforeLift()}
        loading={loader}
      >
        <BrowserRouter basename="/app">
          <ScrollToTop> 
              {!loaded && <Loading loading={loading} />}
              <Router />  
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
