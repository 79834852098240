import { Icon } from '@iconify/react'
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { connect } from 'react-redux';
import { onBoarding, getQuestionsServices, getProjectCompletion } from '../../../../store/facilityProfiling/action';
import { AnswerType, FacilityUserForm, MessageType } from '../../../../util/enum';
import { formatPhoneNumber, getFullNameText, formatOwnerShip, fetchFacilityOwners } from '../../../../util/helper';
import _ from 'lodash';
import Loader from '../../../../components/Common/SpinnerLoader'
import { validate } from 'validate.js';
import { validationSchema, dataValidationSchema } from './schema';
import MessageBox from '../../../../components/Common/MessageBox';
import { UpdateOwnershipPercentage } from '../../../../util/progressUtil';

const Ownership = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            hasError,
            validateForm
        };
    });
    const { fowners, setOwnershipDetails,selected, setDeleteOwnerID, getQuestionsServices,
        project, onBoarding, totalHealth, setType, setMessage, setViewFields, setInvalidAlert, setSaveAutoCalled, ProjectId, getProjectCompletion } = props;

    const [owners, setOwners] = useState([]);
    const [error, setError] = useState({ hasOwnership: true });
    const addRef = useRef()
    const ID = project?.ID || 0
    const [answers, setAnswers] = useState([]);
    const [values, setvalues] = useState({ OwnerShipAnswers: [] });
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData(project) {
            if (project) {
                const res = formatOwnerShip(project);
                res?.OwnerShipAnswers && setAnswers(res?.OwnerShipAnswers);
                const progress = await getProjectCompletion(ProjectId);
                res.CompletionPercentage = progress;
                res.ProjectId = ProjectId
                setvalues(res);
                const fowners = fetchFacilityOwners(project);
                setOwners(fowners?.FacilityOwners || []);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        }
        fetchData(project);
    }, [project]);

    useEffect(() => {
        async function fetchData() {
            if (selected && selected.OwnerShipNav) { 
                const progress = await getProjectCompletion(ProjectId); 
                setvalues({...values, CompletionPercentage:progress}); 
            }
        } 
        fetchData()
    }, [selected]);


    useEffect(() => {
        const fetchData = async () => {
            const response = await getQuestionsServices({ Tab: FacilityUserForm.OWNERSHIPS });
            setQuestions(response)
        }
        fetchData();
    }, [project])

    useEffect(() => {
        if (fowners) {
            setOwners(fowners);
        }
    }, [fowners])



    const handleEdit = (data) => {
        setOwnershipDetails({ ...data, Rule: data.Title, Name: `${data.FName} ${data.MName} ${data.LName}`, Percent: data.Percentage })
        window.$("#add-facility-owner").modal('show');
    }


    const handleDelete = (data) => {
        setDeleteOwnerID(data.ID)
        window.$("#deleteOwnerModal").modal('show');

    }

    const handleNext = () => {
        props.setSelected({
            Insurance: "show active", InsuranceNav: true, InsuranceActive: "active",
            Demographics: "", DemographicsNav: false, DemoActive: "",
            OwnerShip: "", OwnerShipNav: false, OwnerShipActive: "",
            Services: "", ServicesNav: false, ServicesActive: "",
            Payroll: "", PayrollNav: false, PayrollActive: "",
            Documents: "", DocumentsNav: false, DocumentsActive: "",
        })
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100);
    }

    const hasError = async () => {
        const errors = await validate(answers, validationSchema);
        let arrayItemErrors = {}
        questions.map((q, i) => {
            if (i != 5 && i != 11) {
                if (answers.filter(a => a.QuestionId == q.ID).length == 0) {
                    if (!arrayItemErrors[i]) {
                        arrayItemErrors[i] = {}
                    }
                    return arrayItemErrors[i].Answer = ["Please fill in the required fields"]
                } else {
                    arrayItemErrors[i] = {}
                }
            }
        })

        let allEmpty = Object.keys(arrayItemErrors).every(function (key) {
            return _.isEmpty(arrayItemErrors[key])
        })
        if (errors) {
            return errors
        }
        if (owners.length == 0) {
            return owners.length == 0
        }
        return !allEmpty
    }

    const validateForm = async (viewFields = false) => {
        const errors = await validate(answers, validationSchema);
        let arrayItemErrors = {}
        questions.map((q, i) => {
            if (i != 5 && i != 11) {
                if (answers.filter(a => a.QuestionId == q.ID).length == 0) {
                    if (!arrayItemErrors[i]) {
                        arrayItemErrors[i] = {}
                    }
                    return arrayItemErrors[i].Answer = ["Please fill in the required fields"]
                } else {
                    arrayItemErrors[i] = {}
                }
            }
        })
        setError({ ...errors, ...arrayItemErrors, hasOwnership: owners.length });
        let allEmpty = Object.keys(arrayItemErrors).every(function (key) {
            return _.isEmpty(arrayItemErrors[key])
        })
        if (errors || !allEmpty || owners.length === 0) {
            const errorMessage = "Some required information is missing or incomplete. Please correct your entries and try again."
            setType(MessageType.Error);
            setMessage(errorMessage);
            setViewFields(viewFields)
            setInvalidAlert(false)
            return false;
        } else {
            return true;
        }
    }

    const dataValidateForm = async () => {
        const errors = await validate(answers, dataValidationSchema);
        setError({...errors, hasOwnership : true})
        if (errors) {
            const errorMessage = errors[Object.keys(errors)[0]][0]
            setType(MessageType.Error);
            setMessage(errorMessage);
            setInvalidAlert(true)
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {
        setvalues({ ...values, OwnerShipAnswers: answers })
        let isValid = await dataValidateForm()
        if (isValid) {
            setType(MessageType.Success);
            setMessage && setMessage("Your changes have been successfully saved!");
        }
        return true;
    }

    const handleCheckboxChange = async (event, { QuestionId, Answer, i }) => {
        let CompareID = answers?.find((i) => i.QuestionId === QuestionId);
        let data = {};
        if (CompareID) {
            const index = answers.findIndex(
                (item) => item.QuestionId === CompareID.QuestionId
            );
            const newState = [...answers];
            newState[index].Answer = Answer;
            setAnswers(newState);
            data = newState;
        } else {
            const index = answers.findIndex(
                (item) => item.QuestionId === QuestionId
            );
            if (index === -1) {
                setAnswers((prev) => [...prev, { QuestionId, Answer }]);
                data = [...answers, { QuestionId, Answer, ID: 0 }];
            } else {
                const newState = [...answers];
                newState[index].Answer = Answer;
                setAnswers(newState);
                data = newState;
            }
        }
        let ownership = { ...values, OwnerShipAnswers: data };
        ownership = await UpdateOwnershipPercentage(ownership, owners)
        let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.OWNERSHIPS).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.OWNERSHIPS)[0].ProjectId > 0)
            && (ownership.OwnerShipAnswers && ownership.OwnerShipAnswers.filter(d => d.ID === 0).length === 0))
        let health = await totalHealth(ownership.CompletionPercentage);
        ownership = { ...ownership, ...health };
        const project = await onBoarding(ownership, ID, true);
        if (project) {
            if (!skipDispatch) {
                const res = formatOwnerShip(project);
                res?.OwnerShipAnswers && setAnswers(res?.OwnerShipAnswers);
                const progress = await getProjectCompletion(ProjectId);
                res.CompletionPercentage = progress;
                res.ProjectId = ProjectId
                setvalues(res);
            }
            setSaveAutoCalled(project);
        }
    };

    const handleTextArea = async (event, QuestionId) => {
        const Answer = event.target.value;
        const index = answers.findIndex(item => item.QuestionId === QuestionId);
        if (index > 0) {
            const newState = [...answers];
            newState[index].Answer = Answer;
            setAnswers(newState)
        } else {
            setAnswers(prev => [...prev, { QuestionId, Answer }])
        }

    }

    const onBlurSave = async (event, QuestionId) => {
        const Answer = event.target.value;
        const index = answers.findIndex(item => item.QuestionId === QuestionId);
        const newState = [...answers];
        if (index > 0) {
            newState[index].Answer = Answer;
        } else {
            newState = [...answers, { QuestionId, Answer }];
        }
        let ownership = { ...values, OwnerShipAnswers: newState }
        let health = await totalHealth(ownership.CompletionPercentage);
        ownership = { ...ownership, ...health };
        const res = await onBoarding(ownership, ID);
        if (res) {
            setSaveAutoCalled(res);
        }
    }


    const renderOwnership = () => {
        return <table className="table cust-padding fin-table border fnt-12 wit-90 mt-top-20">
            <thead>
                <tr className="rec-nur-head">
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Role/Title</th>
                    <th>Percent</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {owners && owners.length > 0 ? owners.map(o => {
                    return <tr>
                        <td>{getFullNameText({ FName: o.FName, LName: o.LName, MName: o.MName })}</td>
                        <td>{formatPhoneNumber(o.Phone)}</td>
                        <td>{o.Email}</td>
                        <td>{o.Title}</td>
                        <td>{o.Percentage}%</td>
                        <td>
                            <div className="icon-action diag-icon">
                                <div className="on-action"><a href="#" onClick={() => { handleEdit(o) }}>
                                    <Icon icon={"mdi-light:pencil"} />
                                </a></div>
                                <div className="on-action"><a href="#" onClick={() => { handleDelete(o) }}>
                                    <Icon icon={"fluent:delete-28-regular"} />
                                </a></div>
                            </div>
                        </td>
                    </tr>
                }) : <tr>
                    <td colSpan={6}>No owners available</td>
                </tr>
                }
            </tbody>
        </table>
    }


    if (loading) {

        return (
            <div className='d-flex justify-content-center'>
                <Loader loading={loading} height={'20rem'} width={'20rem'} />
            </div>
        )
    }

    return (
        <div>
            <MessageBox setType={setType} setMessage={setMessage} />
            <div className="form-start">
                <div className="col-md-12 insurance-policies-info facility-ownership ps-0">
                    <h6 className='line-after pr-info'><span>Facility ownership <a href="#" ref={addRef} className="i-href-dt"
                        data-bs-toggle="modal" data-bs-target="#add-facility-owner" >
                        <Icon icon={"material-symbols:add"} />Add </a></span></h6>
                    <div className='row'>
                        <div className='col-md-10'>
                            {renderOwnership()}
                        </div>
                        {!error?.hasOwnership && <div className='col-md-4 d-flex mt-4'>
                            <Icon icon="material-symbols:data-alert" fontSize={30} color='red'></Icon>
                            <p style={{ color: 'red', margin: 10 }}>Facility ownership is mandatory</p>
                        </div>}

                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Additional Entity Information</span></h6>
                    </div>
                    <div className="row">
                        <div className="col-md-12  pd-right-45">
                            {questions && questions?.map((item, i) => {
                                if (item && item?.AnswerType === AnswerType.RADIO && [9, 10, 11, 12, 13, 14].includes(item?.ID)) {
                                    const isYesChecked = answers?.some(a => a.QuestionId === item?.ID && a.Answer === 'true');
                                    const isNoChecked = answers?.some(a => a.QuestionId === item?.ID && a.Answer === 'false');
                                    let hasError = error && error[i] && error[i].Answer
                                    return <>
                                        <div className="radio-crtpt text-start" key={i}>
                                            <span className="sort-cate ">{item.Text}<span className='text-danger'>*</span></span>
                                            <div className={`form-radio-dt ${hasError ? 'redborder' : ''}`}>
                                                <input
                                                    type="radio"
                                                    className={`sort-inp${error && error.answers ? 'redborder' : ''}`}
                                                    id={`qaY_${i}`}
                                                    name={item?.Text}
                                                    value="true"
                                                    onChange={(e) => handleCheckboxChange(e, { QuestionId: item?.ID, Answer: e.target.value })}
                                                    checked={isYesChecked} />
                                                <label className="cat-label status-color" htmlFor={`qaY_${i}`}>Yes</label>
                                            </div>
                                            <div className={`form-radio-dt ${hasError ? 'redborder' : ''}`}>
                                                <input
                                                    type="radio"
                                                    className={`sort-inp${error && error.answers ? 'redborder' : ''}`}
                                                    id={`qaN_${i}`}
                                                    name={item?.Text}
                                                    value="false"
                                                    onChange={(e) => handleCheckboxChange(e, { QuestionId: item?.ID, Answer: e.target.value })}
                                                    checked={isNoChecked} />

                                                <label className="cat-label status-color" htmlFor={`qaN_${i}`}>No</label>
                                            </div>
                                        </div>
                                    </>
                                }
                                if (item && item?.AnswerType === AnswerType.TEXTINPUT && [9, 10, 11, 12, 13, 14].includes(item.ID)) {
                                    const option = answers?.find(a => a.QuestionId === item?.ID);
                                    return <>
                                        <div className="col-md-8 pd-right-45 pt-top" key={i}>
                                            <div className="inputformContainer inputtextarea ">
                                                <textarea
                                                    rows="6"
                                                    cols="20"
                                                    className=" form-control  ad-textarea"
                                                    id="entity-info1"
                                                    placeholder=" "
                                                    required name="OwnershipQuestions"
                                                    onChange={(e) => handleTextArea(e, item.ID)}
                                                    value={option?.Answer || ""}
                                                    onBlur={(e) => onBlurSave(e, item?.ID)}
                                                ></textarea>
                                                <label htmlFor="entity-info1" className="label ">{item.Text}</label>
                                            </div>
                                        </div>
                                    </>
                                }

                            })}
                        </div>


                    </div>
                </div>
                <div className="row mt-top-30">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>General</span></h6>
                    </div>
                    <div className="row">
                        {questions && questions?.map((item, i) => {
                            if (item && item.AnswerType === AnswerType.RADIO && [15, 16, 17, 18, 19].includes(item?.ID)) {
                                const isYesChecked = answers?.some(a => a.QuestionId === item?.ID && a.Answer === 'true');
                                const isNoChecked = answers?.some(a => a.QuestionId === item?.ID && a.Answer === 'false');
                                let hasError = error && error[i] && error[i].Answer
                                return <>
                                    <div className="col-md-8  pd-right-45" key={i}>
                                        <div className="radio-crtpt text-start">
                                            <span className="sort-cate ">{item?.Text}<span className='text-danger'>*</span></span>
                                            <div className={`form-radio-dt ${hasError ? 'redborder' : ''}`}>
                                                <input
                                                    type="radio"
                                                    className="sort-inp"
                                                    id={`qaY_${i}`}
                                                    name={item?.Text}
                                                    value="true"
                                                    onChange={(e) => handleCheckboxChange(e, { QuestionId: item?.ID, Answer: e.target.value })}
                                                    checked={isYesChecked}
                                                />
                                                <label className="cat-label status-color" htmlFor={`qaY_${i}`}>Yes</label>
                                            </div>
                                            <div className={`form-radio-dt ${hasError ? 'redborder' : ''}`}>
                                                <input
                                                    type="radio"
                                                    className="sort-inp"
                                                    id={`qaN_${i}`}
                                                    name={item?.Text}
                                                    value="false"
                                                    onChange={(e) => handleCheckboxChange(e, { QuestionId: item?.ID, Answer: e.target.value })}
                                                    checked={isNoChecked}
                                                />
                                                <label className="cat-label status-color" htmlFor={`qaN_${i}`}>No</label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            if (item && item?.AnswerType === AnswerType.TEXTINPUT && [15, 16, 17, 18, 19, 20].includes(item.ID)) {
                                const option = answers?.find(a => a.QuestionId === item?.ID);
                                return <>
                                    <div className="col-md-8 pd-right-45 pt-top" key={i}>
                                        <div className="inputformContainer inputtextarea ">
                                            <textarea
                                                rows="6"
                                                cols="20"
                                                className=" form-control  ad-textarea"
                                                id="entity-info"
                                                placeholder=" "
                                                required
                                                name="OwnershipQuestions"
                                                value={option?.Answer || ""}
                                                onChange={(e) => handleTextArea(e, item?.ID)}
                                                onBlur={(e) => onBlurSave(e, item?.ID)} >
                                            </textarea>
                                            <label htmlFor="entity-info" className="label ">{item?.Text}</label>
                                        </div>
                                    </div>
                                </>
                            }
                        })}
                    </div>
                </div>
                <div className="footer-btn">
                    <div className="row">
                        <div className="col-md-6">
                            {/* <p className="rd-clr"><span className="iconify" data-icon="icon-park-outline:attention"></span>
                  2 documents are yet to be uploaded</p> */}
                        </div>
                        <div className="col-md-6">
                            <div className="sv-btn">
                                <button onClick={handleNext} type="button" className="btn-blue cst-blue bl-dt btnNext"
                                    data-bs-toggle="modal" data-bs-target="#view-validation">
                                    <Icon icon={"material-symbols:skip-next-sharp"} />
                                    Next</button>
                                <button type="button" className="btn-grey cst-gry" data-bs-dismiss="modal" onClick={handleSubmit}>
                                    <Icon icon={"carbon:save"} />
                                    Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )

})
const mapDispatchToProps = { onBoarding, getQuestionsServices, getProjectCompletion }
const mapStateToProps = (state) => ({
    fowners: state.facility && state.facility.fowners,
    project: state?.facility && state?.facility?.projectDetails || null
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Ownership);
