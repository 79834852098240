import React, { useState } from "react";
import _ from "lodash";

const TextField = ({
  onChange,
  error,
  value,
  label,
  innerRef,
  placeholder,
  type,
  disabled,
  maxLength,
  isCalenderIcon,
  wrapperClassName = "",
  className = "",
  labelclass = "",
  endAdornment,
  onKeyPress,
  name,
  id,
  required = false,
  onBlur,
  requiredLabel = true
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const onBlurInput = () => {
    setIsFocus(false);
  };

  return (<>
    <div className={`inputContainer ${wrapperClassName} ${error && 'redborder'}`}>
      <input
        autoComplete="new-password"
        className={className}
        type={type}
        name={name}
        id={id}
        onChange={onChange}
        error={error}
        ref={innerRef}
        value={value || ""}
        disabled={disabled}
        placeholder={isFocus && placeholder||" "}
        onFocus={() => { setIsFocus(true) }}
        onBlur={() => { setIsFocus(false);  onBlur && onBlur();  }}
        maxLength={maxLength}
        onKeyUp={(e) => e.key === 'Enter' && onKeyPress()}
      />
       <label htmlFor={name} className={`label`}> {(isFocus || !_.isEmpty(value)) ? requiredLabel? label : "" : label} <span className="rd">{required && requiredLabel && "*"}</span> </label>  
      {endAdornment && endAdornment()}
      
    </div>
       </>
  );
};

export default TextField;
