import { Icon } from "@iconify/react";
import React from "react";

const CommonModal = ({ onClick, title, onCancel, content, submit, cancel, id = "",loading=false }) => {
    return (
        <div className="passwrdpopup">
            <div className="modal fade" id={id || `exampleModal`} tabIndex="-1"  data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="mainhead" id="exampleModalLabel">{title}</h5>
                        </div>
                        <div className="modal-body">
                            <h6 className="poppara">{content}</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn-cst-grey"  data-bs-dismiss="modal"> <Icon icon={"ion:close-circle-outline"} />No</button>
                           {loading ?  <button type="button" className="btn-cst-blue d-flex">  <div className="loader" style={{marginTop:4}}></div>
                                Yes</button> : <button type="button" className="btn-cst-blue" onClick={() => onClick()}> <Icon icon={"carbon:save"} />
                                Yes</button> } 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonModal;