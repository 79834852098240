/*
Initialize feathers client
 */
import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";
import AuthManagement from "feathers-authentication-management/src/client";
//const rest = require("@feathersjs/rest-client");

const client = feathers();
//this is the server url

const socket = io(process.env.REACT_APP_API_BASE_URL,{
  'reconnection': true,
  'reconnectionDelay': 1000,
  'reconnectionDelayMax' : 5000,
  'reconnectionAttempts': 5, autoConnect:true
});

client.configure(socketio(socket, { timeout: 51200 }));
client.configure(
  authentication({
    storageKey: "feathers-jwt",
    jwtStrategy: "jwt",
    storage: window.localStorage,
    path:'guestauthentication'
  })
);

const authManagement = new AuthManagement(client);
client.authManagement = authManagement;

export default client;
