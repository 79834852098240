import { validate } from "validate.js";

const message = '^Some required information is missing or incomplete. Please correct your entries and try again.'

validate.extend(validate.validators, {

    zip: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/^\d{6}$/.test(value)) {
            return "^Please enter a valid zip code";
        }
        return null
    },
    phone: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{10}$/.test(value)) {
            return "^Please enter a valid phone number";
        }
        return null
    },
    SSN: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "^Please enter a valid SSN #";
        }
        return null
    },
    EIN: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "^Please enter a valid EIN #";
        }
        return null
    },
    BankRouteNum: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "^Please enter a valid routing #";
        }
        return null
    },
    BankAccNum: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{12}$/.test(value)) {
            return "^Please enter a valid account number";
        }
        return null
    },
    NICSCode: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{30}$/.test(value)) {
            return "^Please enter a valid NAICS #";
        }
        return null
    },
    EDD: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{30}$/.test(value)) {
            return "^Please enter a valid EDD #";
        }
        return null
    },

    email: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\S+@\S+\.\S+$/.test(value)) {
            return "^Please enter a valid email address";
        } else {
            return null
        }
    },
    name: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/[A-Za-z]/.test(value)) {
            return "enter a valid value";
        }
        else {
            return null;
        }
    },
    state: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/[0-100]/.test(value)) {
            return "enter a valid ";

        }
        else {
            return null;
        }
    },
    nineDigits: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "Please enter a valid number";
        }
        return null
    },
    onlyDigits: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/^\+?\d$/.test(value)) {
            return "Please enter a valid number";
        }
        return null
    },
    webSite: (value) => {
        if (value == null || value === '') {
            return null
        }
        else if (value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
            return "^Please enter a valid website";
        }
        return null
    },
    datepick: (value) => {
        if (value == null || value === '') {
            return "Please enter a valid date"
        }
    },
})
export const validationSchema = {
    OwnerName: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    Dob: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    Phone: {
        presence: {
            allowEmpty: false,
            message: message
        },
        phone: true,
    },
    SSN: {
        presence: {
            allowEmpty: false,
            message: message
        },
        SSN: true
    },
    Line1: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    City: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    State: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    Email: {
        presence: {
            allowEmpty: false,
            message: message
        },
        email: true,
    },
    CompanyName: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    CompanyLine1: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    CompanyCity: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    CompanyState: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    EIN: {
        presence: {
            allowEmpty: false,
            message: message
        },
        EIN: true
    },
    NICSCode: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // NICSCode: true
    },
    PayFrequency: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    IsPayrollCompany: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    EDD: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // EDD: true
    },
    NumPeopleWithAccess: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    BankName: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    BankRouteNum: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // BankRouteNum: true
    },
    BankAccNum: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // BankAccNum: true
    },
    PayDates: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    PaymentAmt: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    HolidayPayDay: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },

}

export const dataValidationSchema = {
    Phone: {
        phone: true,
    },
    SSN: {
        SSN: true
    },
    Email: {
        email: true,
    },
    EIN: {
        EIN: true
    },
    // NICSCode: {
    //     NICSCode: true
    // },
    // EDD: {
    //     EDD: true
    // },
    accessEmail: {
        email: true,
    },
    BankRouteNum: {
        // BankRouteNum: true
    },
    BankAccNum: {
        // BankAccNum: true
    },
}

