import { Icon } from '@iconify/react';
import React, { useState, useEffect, forwardRef, useImperativeHandle, } from 'react';
import TextField from '../../../../components/Common/TextInput';
import DatePickerComponent from '../../../../components/Common/DatePicker';
import { onBoarding, getQuestionsServices, getProjectCompletion } from '../../../../store/facilityProfiling/action';
import { connect } from 'react-redux';
import { MessageType, PayFrequency, FacilityUserForm } from '../../../../util/enum';
import { validationSchema, dataValidationSchema } from './schema';
import _ from 'lodash';
import Loader from '../../../../components/Common/SpinnerLoader';
import { validate } from 'validate.js';
import { formatFacilityAccess, splitName, formatProjectFacility, formatPayroll, AcceptEINNumbers, formatEIN, AcceptPhoneNumbers, formatPhoneNumber, AcceptSSNNumbers, AcceptNaicsEdd, AcceptAccountNumber, AcceptRoutingNumber, formatSSN } from '../../../../util/helper';
import moment from 'moment';
import Select from '../../../../components/Common/Select';
import { UpdatePayrollPercentage } from '../../../../util/progressUtil';

const Payroll = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            hasError,
            validateForm
        };
    });
    const { setSaveAutoCalled, selected, setSelected, onBoarding, totalHealth, getQuestionsServices, project, setType, setMessage, states, setViewFields, setInvalidAlert, ProjectId, getProjectCompletion } = props;
    const [projectFacilityValues, setProjectFacilityValues] = useState({});
    const [WMS_FacilityPayroll, setWMS_FacilityPayroll] = useState({});
    const [WMS_FacilityAccess, setWMS_FacilityAccess] = useState([{ Name: '', Email: '', }]);
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState({});
    const [FacilityAccessErrors, setFacilityAccessErrors] = useState({ Name: '', Email: '', })
    const [loading, setLoading] = useState(true);
    const ID = project?.ID || 0;
    const fieldContribution = 100 / 31;
    const quetionIds = [21, 22, 23, 24, 25]

    useEffect(() => {
        const fetchData = async () => {
            let q = await getQuestionsServices({ Tab: FacilityUserForm.PAYROLL, });
            setQuestions(q);
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData(project) {
            if (project) {
                const payroll = formatPayroll(project);
                const facilityaccess = formatFacilityAccess(project);
                const projectfacility = formatProjectFacility(project);
                const progress = await getProjectCompletion(ProjectId);
                projectfacility.CompletionPercentage = progress;
                projectfacility.ProjectId = ProjectId;
                payroll && setWMS_FacilityPayroll(payroll);
                facilityaccess && !_.isEmpty(facilityaccess) && setWMS_FacilityAccess(facilityaccess);
                projectfacility && setProjectFacilityValues(projectfacility || []);
            }
        }
        fetchData(project)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [project]);

    useEffect(() => {
        async function fetchData() {
            if (selected && selected.PayrollNav) { 
                const progress = await getProjectCompletion(ProjectId); 
                setProjectFacilityValues({...projectFacilityValues, CompletionPercentage:progress}); 
            }
        } 
        fetchData()
    }, [selected]);

    const addFields = () => {
        let newfield = { Name: '', Email: '' }
        setWMS_FacilityAccess([...WMS_FacilityAccess, newfield])
    }


    const handlePayroll = (field, value) => {
        setWMS_FacilityPayroll({ ...WMS_FacilityPayroll, [field]: value })
    }

    const handleRadioButton = async (field, event) => {
        if (event) {
            let values = {}
            if (field == 'PayFrequency') {
                setWMS_FacilityPayroll({ ...WMS_FacilityPayroll, [field]: event.target.value });
                values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll, [field]: event.target.value } }

            }
            if (field == 'IsPayrollCompany') {
                const newValue = event.target.value === 'true';
                setWMS_FacilityPayroll({ ...WMS_FacilityPayroll, [field]: newValue });
                values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll, [field]: newValue } }
            }
            values = await UpdatePayrollPercentage(values, field);
            let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL)[0].ProjectId > 0) &&
                values.WMS_FacilityPayroll && values.WMS_FacilityPayroll.ID > 0)
            let health = await totalHealth(values.CompletionPercentage);
            values = { ...values, ...health };
            await onBoarding(values, ID, skipDispatch);
            setSaveAutoCalled(event.target.value)
        }
    };

    const handleAnswers = async (event, { QuestionId, Answer }) => {
        let values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll } };
        let CompareID = projectFacilityValues?.FacilityProfileAnswers?.find(i => i.QuestionId === QuestionId);
        const newState = projectFacilityValues?.FacilityProfileAnswers && [...projectFacilityValues?.FacilityProfileAnswers] || [];
        if (CompareID && CompareID.ID) {
            const newState = projectFacilityValues?.FacilityProfileAnswers && [...projectFacilityValues?.FacilityProfileAnswers];
            const index = newState?.findIndex(item => item.ID === CompareID.ID);
            newState[index].Answer = Answer;
            values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll }, FacilityProfileAnswers: newState }
            setProjectFacilityValues((prev) => ({ ...prev, FacilityProfileAnswers: newState }))
        }
        else {
            const newState = projectFacilityValues?.FacilityProfileAnswers && [...projectFacilityValues?.FacilityProfileAnswers];
            const index = projectFacilityValues?.FacilityProfileAnswers?.findIndex(item => item.QuestionId === QuestionId);
            if (index === -1) {
                values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll }, FacilityProfileAnswers: [...projectFacilityValues?.FacilityProfileAnswers, { QuestionId, Answer, ID: 0 }] }
                setProjectFacilityValues((prev) => ({ ...prev, FacilityProfileAnswers: [...projectFacilityValues?.FacilityProfileAnswers, { QuestionId, Answer }] }))
            } else {
                newState[index].Answer = Answer;
                values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll }, FacilityProfileAnswers: newState }
                setProjectFacilityValues((prev) => ({ ...prev, FacilityProfileAnswers: newState }))
            }
        }

        values = await UpdatePayrollPercentage(values);
        let health = await totalHealth(values.CompletionPercentage);
        values = { ...values, ...health };
        let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL)[0].ProjectId > 0) &&
            (values.FacilityProfileAnswers && values.FacilityProfileAnswers.filter(e => e.ID === 0).length == 0))
        
        const res = await onBoarding(values, ID, true);
        if (res) {
            if (!skipDispatch) {
                const projectfacility = formatProjectFacility(res);
                const progress = await getProjectCompletion(ProjectId);
                projectfacility.CompletionPercentage = progress;
                projectfacility.ProjectId = ProjectId;
                projectfacility && setProjectFacilityValues(projectfacility || []);
            }
            setSaveAutoCalled(res);
        }
    }

    const hasError = async () => {
        let errors = await validate({ ...WMS_FacilityPayroll, ...projectFacilityValues }, validationSchema);
        let arrayItemErrors = {}
        let questionIds = questions.map(q => q.ID);
        questionIds && questionIds.map((q, i) => {
            if (projectFacilityValues && projectFacilityValues.FacilityProfileAnswers) {
                if ((projectFacilityValues.FacilityProfileAnswers.filter(a => a.QuestionId === q).length === 0) || (projectFacilityValues.FacilityProfileAnswers.filter(a => a.QuestionId === q).length && _.isEmpty(projectFacilityValues.FacilityProfileAnswers.filter(a => a.QuestionId === q)[0].Answer))) {
                    if (!arrayItemErrors[i]) {
                        arrayItemErrors[i] = {}
                    }
                    arrayItemErrors[i].FacilityProfileAnswers = ["Please fill in the required fields"]
                } else {
                    arrayItemErrors[i] = {}
                }
            }
        })
        let allEmpty = Object.keys(arrayItemErrors).every(function (key) {
            return _.isEmpty(arrayItemErrors[key])
        })
        if (errors) {
            return errors
        }
        return !allEmpty
    }

    const validateForm = async (viewFields = false) => {
        let errors = await validate({ ...WMS_FacilityPayroll, ...projectFacilityValues }, validationSchema);
        setError(errors)
        let arrayItemErrors = {}
        let questionIds = questions.map(q => q.ID);
        questionIds.map((q, i) => {
            if ((projectFacilityValues?.FacilityProfileAnswers && projectFacilityValues.FacilityProfileAnswers.filter(a => a.QuestionId === q).length === 0) || (projectFacilityValues?.FacilityProfileAnswers && projectFacilityValues?.FacilityProfileAnswers.filter(a => a.QuestionId === q).length && _.isEmpty(projectFacilityValues?.FacilityProfileAnswers && projectFacilityValues?.FacilityProfileAnswers.filter(a => a.QuestionId === q)[0].Answer))) {
                if (!arrayItemErrors[i]) {
                    arrayItemErrors[i] = {}
                }
                arrayItemErrors[i].FacilityProfileAnswers = ["Please fill in the required fields"]
            } else {
                arrayItemErrors[i] = {}
            }
        })

        if (WMS_FacilityPayroll.IsPayrollCompany) {
            if (!WMS_FacilityPayroll.Login || !WMS_FacilityPayroll.Password) {
                if (!errors) {
                    errors = {};
                }
                if (!WMS_FacilityPayroll.Login) {
                    errors.Login = ["Please fill in the required fields"];
                }
                if (!WMS_FacilityPayroll.Password) {
                    errors.Password = ["Please fill in the required fields"];
                }
            }
        }
        setError({ ...errors, ...arrayItemErrors });
        let allEmpty = Object.keys(arrayItemErrors).every(function (key) {
            return _.isEmpty(arrayItemErrors[key])
        })
        if (errors || !allEmpty) {
            const message = errors && errors[Object.keys(errors)[0]][0] || "Some required information is missing or incomplete. Please correct your entries and try again.";
            setType(MessageType.Error);
            setMessage(message);
            setViewFields(viewFields)
            setInvalidAlert(false)
            return false;
        } else {
            return true;
        }
    }

    const dataValidateForm = async (viewFields = false) => {
        let errors = await validate(WMS_FacilityPayroll, dataValidationSchema);
        setError(errors)
        if (errors) {
            const message = errors[Object.keys(errors)[0]][0];
            setType(MessageType.Error);
            setMessage(message);
            setInvalidAlert(true)
            return false;
        } else {
            return true;
        }
    }


    const handleSave = async (field) => {
        let values = {};
        let name = splitName(WMS_FacilityPayroll?.OwnerName);
        values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll, FName: name.FName, MName: name.MName, LName: name.LName } } // new change
        delete values.OwnerName
        values = await UpdatePayrollPercentage(values, field);
        let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL)[0].ProjectId > 0) &&
            (values.WMS_FacilityPayroll && values.WMS_FacilityPayroll.ID > 0))
        let health = await totalHealth(values.CompletionPercentage);
        values = { ...values, ...health };
        await onBoarding(values, ID, skipDispatch);
        setSaveAutoCalled(field);
    }

    const handleDateChange = async (field, date) => {
        setWMS_FacilityPayroll({ ...WMS_FacilityPayroll, [field]: date });
        let values = { ...projectFacilityValues, WMS_FacilityPayroll: { ...WMS_FacilityPayroll, [field]: date } }
        let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.PAYROLL)[0].ProjectId > 0) &&
            (values.WMS_FacilityPayroll && values.WMS_FacilityPayroll.ID > 0))
        let health = await totalHealth(values.CompletionPercentage);
        values = { ...values, ...health };
        await onBoarding(values, ID, skipDispatch);
        setSaveAutoCalled(date);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = await dataValidateForm();
        if (isValid) {
            let FName = '', LName = '', MName = '';
            let values = {};
            if (WMS_FacilityPayroll?.OwnerName) {
                let name = splitName(WMS_FacilityPayroll?.OwnerName);
                FName = name.FName
                LName = name.LName
                MName = name.MName
                setWMS_FacilityPayroll({ ...WMS_FacilityPayroll, FName, MName, LName });
            }
            values =
                _.pick(
                    {
                        ...values,
                        Tab: FacilityUserForm.PAYROLL,
                        WMS_FacilityAccess: [WMS_FacilityAccess],
                        ...projectFacilityValues,
                        WMS_FacilityPayroll: _.pick({ ...WMS_FacilityPayroll, FName, MName, LName }, ['ID',
                            'OwnerName',
                            'BankName',
                            'BankRouteNum',
                            'BankAccNum',
                            'PayFrequency',
                            'PayDates',
                            'HolidayPayDay',
                            'IsPayrollCompany',
                            'Login',
                            'Password',
                            'FName',
                            'MName',
                            'LName',
                            'Dob',
                            'Phone',
                            'SSN',
                            'Line1',
                            'Line2',
                            'City',
                            'State',
                            'Email',
                            'CompanyName',
                            'CompanyLine1',
                            'CompanyLine2',
                            'CompanyCity',
                            'CompanyState',
                            'EIN',
                            'EDD',
                            'NICSCode',
                            'PaymentAmt',
                            "NAICS"
                        ]),
                    },
                    [
                        'NumPeopleWithAccess',
                        'Tab',
                        'ProjectFacilityId',
                        'FacilityProfileAnswers',
                        'WMS_FacilityPayroll',
                        'WMS_FacilityAccess',
                    ]
                )
            if (isValid) {
                setType && setType(MessageType.Success);
                setMessage && setMessage("Your changes have been successfully saved!");
            }
        }
    }

    const handleChange = (field, value) => {
        if (field === 'NumPeopleWithAccess') {
            setProjectFacilityValues({ ...projectFacilityValues, [field]: value });
        } else {
            setWMS_FacilityPayroll({ ...WMS_FacilityPayroll, [field]: value });
        }
    }

    const handleFacilityAccessChange = (field, input, value, index) => {
        let tempValues = [...WMS_FacilityAccess];
        tempValues[index] = { ...input, [field]: value }
        setWMS_FacilityAccess(tempValues);

    }

    const handleNext = () => {
        setSelected({
            Documents: "show active", DocumentsNav: true, DocumentsActive: "active",
            Payroll: "", PayrollNav: false, PayrollActive: ""
        })
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100);
    }
    if (loading) {

        return (
            <div className='d-flex justify-content-center'>
                <Loader loading={loading} height={'20rem'} width={'20rem'} />
            </div>
        )
    }
    return (
        <>
            {/* <MessageBox type={type} message={message} setMessage={setMessage} /> */}

            <div className="form-start pil-insur">

                <div className="row">
                    <div className="col-md-12 personal-info">
                        <h6 className='line-after pr-info'><span>Owner's Info</span></h6>
                    </div>
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            <div className="row">
                                <div className="col-md-4 pd-right-45 mt-top-20">
                                    <TextField
                                        maxLength={100}
                                        required={true}
                                        type="text"
                                        name='OwnerName'
                                        value={WMS_FacilityPayroll && WMS_FacilityPayroll?.OwnerName && WMS_FacilityPayroll?.OwnerName || ''}
                                        error={error && error.OwnerName}
                                        label="Name"
                                        className="inputformdata form-control"
                                        id="Name"
                                        placeholder="Name"
                                        wrapperClassName={`mr-bt-25  ${error && error.OwnerName ? "redborder" : ""}`}
                                        onChange={(e) => handleChange("OwnerName", e.target.value)}
                                        onBlur={() => handleSave("OwnerName")}

                                    />
                                </div>
                                <div className="col-md-4 pd-right-45 mt-top-20">
                                    <div className='inputformContainer'>
                                        <DatePickerComponent
                                            required={true}
                                            selected={WMS_FacilityPayroll?.Dob ? new Date(WMS_FacilityPayroll?.Dob) : null}
                                            value={WMS_FacilityPayroll?.Dob ? moment(WMS_FacilityPayroll?.Dob).format("MM/DD/YYYY") : null}
                                            onChange={(date) => handleDateChange('Dob', date)}
                                            className="date-picker-styles"
                                            name="Dob"
                                            wrapperClassName={`mr-bt-25  ${error && error.Dob ? "redborder" : ""}`}
                                            placeholder='MM-DD-YY'
                                            label="Date of Birth"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='Phone'
                                        value={WMS_FacilityPayroll?.Phone && formatPhoneNumber(WMS_FacilityPayroll?.Phone)}
                                        error={error && error.Phone}
                                        label="Phone"
                                        className="inputformdata form-control"
                                        id="Phone"
                                        placeholder="Phone"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("Phone", AcceptPhoneNumbers(e.target.value))}
                                        onBlur={() => { handleSave("Phone") }}
                                        maxLength={14}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='SSN'
                                        value={WMS_FacilityPayroll?.SSN && formatSSN(WMS_FacilityPayroll?.SSN)}
                                        error={error && error.SSN}
                                        label="SSN #"
                                        className="inputformdata form-control"
                                        id="SSN"
                                        placeholder="XXX-XX-XXXX"
                                        maxLength={11}
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("SSN", AcceptSSNNumbers(e.target.value))}
                                        onBlur={() => { handleSave("SSN") }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={255}
                                        required={true}
                                        type="text"
                                        name='AddressLine1'
                                        value={WMS_FacilityPayroll?.Line1}
                                        error={error && error.Line1}
                                        label="Address Line 1"
                                        className="inputformdata form-control"
                                        id="AddressLine1"
                                        placeholder="Address Line 1"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("Line1", e.target.value)}
                                        onBlur={() => { handleSave("Line1") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={255}
                                        type="text"
                                        name='AddressLine2'
                                        value={WMS_FacilityPayroll?.Line2}
                                        label="Address Line 2"
                                        className="inputformdata form-control"
                                        id="AddressLine2"
                                        placeholder="Address Line 2"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("Line2", e.target.value)}
                                        onBlur={() => { handleSave() }}
                                    />
                                </div>
                                {/* <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='Town'
                                        value={WMS_FacilityPayroll?.Town}
                                        error={error && error.Town}
                                        label="Town"
                                        className="inputformdata form-control"
                                        id="Town"
                                        placeholder="Town"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("Town", e.target.value)}
                                        onBlur={() => { handleSave() }}
                                    />
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={255}
                                        required={true}
                                        type="text"
                                        name='City'
                                        value={WMS_FacilityPayroll?.City}
                                        error={error && error.City}
                                        label="City"
                                        className="inputformdata form-control"
                                        id="City"
                                        placeholder="City"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("City", e.target.value)}
                                        onBlur={() => { handleSave("City") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <Select
                                        required={true}
                                        name='OwnersState'
                                        value={WMS_FacilityPayroll?.State}
                                        error={error && error.State}
                                        onChange={(e) => handlePayroll("State", Number(e.target.value))}
                                        options={states}
                                        label="State"
                                        placeholder="State"
                                        className='inputformdata form-control'
                                        onBlur={() => { handleSave("State") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        required={true}
                                        type="text"
                                        name='Email'
                                        value={WMS_FacilityPayroll?.Email}
                                        error={error && error.Email}
                                        label="Email"
                                        className="inputformdata form-control"
                                        id="Email"
                                        placeholder="Email"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("Email", e.target.value)}
                                        onBlur={() => { handleSave("Email") }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 personal-info mt-top-20">
                        <h6 className='line-after pr-info'><span>Company Info</span></h6>
                    </div>
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            <div className="row">
                                <div className="col-md-4 pd-right-45 mt-top-20">
                                    <TextField
                                        maxLength={100}
                                        required={true}
                                        type="text"
                                        name='CompanyName'
                                        value={WMS_FacilityPayroll?.CompanyName}
                                        error={error && error.CompanyName}
                                        label="Company legal name"
                                        className="inputformdata form-control"
                                        id="CompanyName"
                                        placeholder="Company legal name"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("CompanyName", e.target.value)}
                                        onBlur={() => { handleSave("CompanyName") }}
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={255}
                                        required={true}
                                        type="text"
                                        name='CompanyLine1'
                                        value={WMS_FacilityPayroll?.CompanyLine1}
                                        error={error && error.CompanyLine1}
                                        label="Address Line 1"
                                        className="inputformdata form-control"
                                        id="CompanyLine1"
                                        placeholder="Address Line 1"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("CompanyLine1", e.target.value)}
                                        onBlur={() => { handleSave("CompanyLine1") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        type="text"
                                        name='CompanyLine2'
                                        value={WMS_FacilityPayroll?.CompanyLine2}
                                        label="Address Line 2"
                                        className="inputformdata form-control"
                                        id="CompanyLine2"
                                        placeholder="Address Line 2"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("CompanyLine2", e.target.value)}
                                        onBlur={() => { handleSave() }}
                                    />
                                </div>
                                {/* <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='CompanyTown'
                                        value={WMS_FacilityPayroll?.CompanyTown}
                                        error={error && error.CompanyTown}
                                        label="Town"
                                        className="inputformdata form-control"
                                        id="CompanyTown"
                                        placeholder="Town"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("CompanyTown", e.target.value)}
                                        onBlur={() => { handleSave() }}
                                    />
                                </div> */}
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}

                                        required={true}
                                        type="text"
                                        name='CompanyCity'
                                        value={WMS_FacilityPayroll?.CompanyCity}
                                        error={error && error.CompanyCity}
                                        label="City"
                                        className="inputformdata form-control"
                                        id="CompanyCity"
                                        placeholder="City"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("CompanyCity", e.target.value)}
                                        onBlur={() => { handleSave("CompanyCity") }}
                                    />
                                </div>

                            </div>
                            <div className="row">

                                <div className="col-md-4 pd-right-45">
                                    <Select
                                        required={true}
                                        name='CompanyState'
                                        value={WMS_FacilityPayroll?.CompanyState}
                                        error={error && error.CompanyState}
                                        onChange={(e) => handlePayroll("CompanyState", Number(e.target.value))}
                                        options={states}
                                        label="State"
                                        placeholder="State"
                                        className='inputformdata form-control'
                                        onBlur={() => { handleSave("CompanyState") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='EIN'
                                        value={WMS_FacilityPayroll?.EIN && formatEIN(WMS_FacilityPayroll?.EIN)}
                                        error={error && error.EIN}
                                        label="EIN"
                                        className="inputformdata form-control"
                                        id="EIN"
                                        placeholder="XX-XXXXXXX"
                                        maxLength={10}
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("EIN", AcceptEINNumbers(e.target.value))}
                                        onBlur={() => { handleSave("EIN") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='NICSCode'
                                        value={WMS_FacilityPayroll?.NICSCode}
                                        error={error && error.NICSCode}
                                        label="NAICS"
                                        className="inputformdata form-control"
                                        id="NAICS"
                                        placeholder="NAICS"
                                        maxLength={30}
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("NICSCode", e.target.value)}
                                        onBlur={() => { handleSave("NICSCode") }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            <div className="row">

                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='EDD'
                                        value={WMS_FacilityPayroll?.EDD}
                                        error={error && error.EDD}
                                        label="EDD"
                                        className="inputformdata form-control"
                                        id="EDD"
                                        placeholder="EDD"
                                        maxLength={30}
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("EDD", e.target.value)}
                                        onBlur={() => { handleSave("EDD") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        required={true}
                                        type="text"
                                        name='NumPeopleWithAccess'
                                        value={projectFacilityValues?.NumPeopleWithAccess}
                                        error={error && error?.NumPeopleWithAccess}
                                        label="Number of people that will need access"
                                        className="inputformdata form-control"
                                        id="NumPeopleWithAccess"
                                        placeholder="eg 15"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("NumPeopleWithAccess", e.target.value)}
                                        onBlur={() => { handleSave("NumPeopleWithAccess") }}
                                    />
                                </div>
                            </div>

                            {/* <p>Enter the name and email below of the people who need access.
                            </p>
                            {WMS_FacilityAccess?.map((input, index) => {
                                return (
                                    <>

                                        <div className="row">
                                            <div className="col-md-4 pd-right-45">

                                                <TextField
                                                    required={index===0}
                                                    type="text"
                                                    name={`Name${index + 1}`}
                                                    value={input?.Name}
                                                    error={FacilityAccessErrors?.WMS_FacilityAccess && FacilityAccessErrors.WMS_FacilityAccess[0][index] && FacilityAccessErrors.WMS_FacilityAccess[0][index].error.name}
                                                    error={error && error?.[`Name${index +1}`]} 
                                                    label="Name"
                                                    className="inputformdata form-control"
                                                    id="FacilityAccesName"
                                                    placeholder="Name"
                                                    wrapperClassName='mr-bt-25'
                                                    onChange={(e) => handleFacilityAccessChange("Name", input, e.target.value, index)}
                                                    onBlur={() => { handleSave() }}
                                                />
                                            </div>
                                            <div className="col-md-4 pd-right-45">

                                                <TextField
                                                    required={index===0}
                                                    type="text"
                                                    name={`Email${index + 1}`}
                                                    value={input?.Email}
                                                    error={FacilityAccessErrors?.WMS_FacilityAccess && FacilityAccessErrors.WMS_FacilityAccess[0][index] && FacilityAccessErrors.WMS_FacilityAccess[0][index].error.email}
                                                    error={error && error?.[`Name${index +1}`]} 
                                                    label="Email"
                                                    className="inputformdata form-control"
                                                    id="FacilityAccessEmail"
                                                    placeholder="Email"
                                                    wrapperClassName='mr-bt-25'
                                                    onChange={(e) => handleFacilityAccessChange("Email", input, e.target.value, index)}
                                                    onBlur={() => { handleSave() }}
                                                />
                                            </div>
                                        </div>

                                    </>)
                            })}
                            {WMS_FacilityAccess?.length < 3 && (
                                <Link className="i-href" onClick={addFields}><svg
                                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"
                                    viewBox="0 0 24 24" data-icon="material-symbols:add"
                                    className="iconify iconify--material-symbols">
                                    <path fill="currentColor" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z"></path>
                                </svg>Add </Link>
                            )} */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 personal-info mt-top-20">
                        <h6 className='line-after pr-info'><span>Finances</span></h6>
                    </div>
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            <div className="row">
                                <div className="col-md-4 pd-right-45 mt-top-20">
                                    <TextField
                                        maxLength={100}
                                        required={true}
                                        type="text"
                                        name='BankName'
                                        value={WMS_FacilityPayroll?.BankName}
                                        error={error && error.BankName}
                                        label="Bank Name"
                                        className="inputformdata form-control"
                                        id="BankName"
                                        placeholder="Bank Name"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("BankName", e.target.value)}
                                        onBlur={() => { handleSave("BankName") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45 mt-top-20">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='BankRouteNum'
                                        value={WMS_FacilityPayroll?.BankRouteNum}
                                        error={error && error.BankRouteNum}
                                        label="Routing Number"
                                        className="inputformdata form-control"
                                        id="BankRouteNum"
                                        placeholder="Routing Number"
                                        maxLength={9}
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("BankRouteNum", AcceptRoutingNumber(e.target.value))}
                                        onBlur={() => { handleSave("BankRouteNum") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45 mt-top-20">
                                    <TextField
                                        required={true}
                                        type="text"
                                        name='BankAccNum'
                                        value={WMS_FacilityPayroll?.BankAccNum}
                                        error={error && error.BankAccNum}
                                        label="Account Number"
                                        className="inputformdata form-control"
                                        id="BankAccNum"
                                        maxLength={12}
                                        placeholder="Account Number"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("BankAccNum", AcceptAccountNumber(e.target.value))}
                                        onBlur={() => { handleSave("BankAccNum") }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12  pd-right-45">
                                    <div className="radio-crtpt text-start">
                                        <span className="sort-cate ft-16 ">Please check the current pay frequency<span className='text-danger'>*</span>
                                        </span>
                                        <div className={`form-radio-dt ${error && error.PayFrequency ? 'redborder' : ''}`}>
                                            <input type="radio" className="sort-inp" id="biweekly" name="PayFrequency" value={PayFrequency.Biweekly}
                                                checked={WMS_FacilityPayroll?.PayFrequency === PayFrequency.Biweekly} onChange={(event) => handleRadioButton('PayFrequency', event)}
                                            />
                                            <label className="cat-label status-color ft-14" htmlFor="biweekly">Biweekly</label>
                                        </div>
                                        <div className={`form-radio-dt ${error && error.PayFrequency ? 'redborder' : ''}`}>
                                            <input type="radio" className="sort-inp" id="daily" name="PayFrequency" value={PayFrequency.Daily}
                                                checked={WMS_FacilityPayroll?.PayFrequency === PayFrequency.Daily} onChange={(event) => handleRadioButton('PayFrequency', event)} /><label
                                                    className="cat-label status-color ft-14" htmlFor="daily">Daily</label>
                                        </div>
                                        <div className={`form-radio-dt ${error && error.PayFrequency ? 'redborder' : ''}`}>
                                            <input type="radio" className="sort-inp" id="monthly" name="PayFrequency" value={PayFrequency.Monthly}
                                                checked={WMS_FacilityPayroll?.PayFrequency === PayFrequency.Monthly} onChange={(event) => handleRadioButton('PayFrequency', event)} />
                                            <label className="cat-label status-color ft-14" htmlFor="monthly">Monthly</label>
                                        </div>
                                        <div className={`form-radio-dt ${error && error.PayFrequency ? 'redborder' : ''}`}>
                                            <input type="radio" className="sort-inp" id="quarterly" name="PayFrequency" value={PayFrequency.Quarterly}
                                                checked={WMS_FacilityPayroll?.PayFrequency === PayFrequency.Quarterly} onChange={(event) => handleRadioButton('PayFrequency', event)} /><label
                                                    className="cat-label status-color ft-14" htmlFor="quarterly">Quarterly</label>
                                        </div>
                                        <div className={`form-radio-dt ${error && error.PayFrequency ? 'redborder' : ''}`}>
                                            <input type="radio" className="sort-inp" id="semi-monthly" name="PayFrequency" value={PayFrequency.Semimonthly}
                                                checked={WMS_FacilityPayroll?.PayFrequency === PayFrequency.Semimonthly} onChange={(event) => handleRadioButton('PayFrequency', event)} />
                                            <label className="cat-label status-color ft-14" htmlFor="semi-monthly">Semimonthly</label>
                                        </div>
                                        <div className={`form-radio-dt ${error && error.PayFrequency ? 'redborder' : ''}`}>
                                            <input type="radio" className="sort-inp" id="weekly" name="PayFrequency" value={PayFrequency.Weekly}
                                                checked={WMS_FacilityPayroll?.PayFrequency === PayFrequency.Weekly} onChange={(event) => handleRadioButton('PayFrequency', event)} /><label
                                                    className="cat-label status-color ft-14" htmlFor="weekly">Weekly</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            <div className="row">
                                <p>What is your pay date?<span className='text-danger'>*</span></p>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        requiredLabel={false}
                                        required={true}
                                        type="text"
                                        name='PayDates'
                                        value={WMS_FacilityPayroll?.PayDates}
                                        error={error && error.PayDates}
                                        label="e.g.,1st and 15th of the month"
                                        className="inputformdata form-control"
                                        id="PayDates"
                                        placeholder="e.g.,1st and 15th of the month"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("PayDates", e.target.value)}
                                        onBlur={() => { handleSave("PayDates") }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <p>What is your holiday pay?<span className='text-danger'>*</span>
                                </p>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        requiredLabel={false}
                                        required={true}
                                        type="text"
                                        name='PayAmount'
                                        value={WMS_FacilityPayroll?.PaymentAmt}
                                        error={error && error.PaymentAmt}
                                        label=" Time and half? Bonus sum?"
                                        className="inputformdata form-control"
                                        id="PayAmount"
                                        placeholder=" Time and half? Bonus sum?"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handlePayroll("PaymentAmt", e.target.value)}
                                        onBlur={() => { handleSave("PaymentAmt") }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <p>Which holidays are considered for giving holiday pay?<span className='text-danger'>*</span>
                                </p>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        requiredLabel={false}
                                        required={true}
                                        type="text"
                                        name='HolidayPayDay'
                                        value={WMS_FacilityPayroll?.HolidayPayDay}
                                        error={error && error.HolidayPayDay}
                                        label="e.g.,Memorial Day, Labor Day.. "
                                        className="inputformdata form-control"
                                        id="HolidayPayDay"
                                        placeholder="e.g.,Memorial Day, Labor Day.. "
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("HolidayPayDay", e.target.value)}
                                        onBlur={() => { handleSave("HolidayPayDay") }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12  pd-right-45">
                                    <div className="radio-crtpt text-start">
                                        <span className="sort-cate ft-16 ">Do you currently have a payroll company? <span className='text-danger'>*</span>
                                        </span>
                                        <div className={`form-radio-dt ${error && error.IsPayrollCompany ? 'redborder' : ''}`}>
                                            <input
                                                type="radio"
                                                className="sort-inp"
                                                id="IsPayrollCompany-Y"
                                                name="IsPayrollCompany"
                                                value={true}
                                                checked={WMS_FacilityPayroll?.IsPayrollCompany === true}
                                                onChange={(event) => handleRadioButton('IsPayrollCompany', event)} />
                                            <label className="cat-label status-color ft-14" htmlFor="IsPayrollCompany-Y">Yes</label>
                                        </div>
                                        <div className={`form-radio-dt ${error && error.IsPayrollCompany ? 'redborder' : ''}`}>
                                            <input
                                                type="radio"
                                                className="sort-inp"
                                                id="IsPayrollCompany-N"
                                                name="IsPayrollCompany"
                                                value={false}
                                                checked={WMS_FacilityPayroll?.IsPayrollCompany === false}
                                                onChange={(event) => handleRadioButton('IsPayrollCompany', event)}
                                            />
                                            <label className="cat-label status-color ft-14" htmlFor="IsPayrollCompany-N">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            <div className="row">
                                <p>If yes, please provide a supervisor's login and password
                                </p>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        disabled={WMS_FacilityPayroll?.IsPayrollCompany === false}
                                        required={true}
                                        type="text"
                                        name='Login'
                                        value={WMS_FacilityPayroll?.Login}
                                        error={error && error.Login}
                                        label="Login"
                                        className="inputformdata form-control"
                                        id="Login"
                                        placeholder="Login"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("Login", e.target.value)}
                                        onBlur={() => { handleSave("Login") }}
                                    />
                                </div>
                                <div className="col-md-4 pd-right-45">
                                    <TextField
                                        maxLength={100}
                                        disabled={WMS_FacilityPayroll?.IsPayrollCompany === false}
                                        required={true}
                                        type="text"
                                        name='Password'
                                        value={WMS_FacilityPayroll?.Password}
                                        error={error && error.Password}
                                        label="Password"
                                        className="inputformdata form-control"
                                        id="Password"
                                        placeholder="Password"
                                        wrapperClassName='mr-bt-25'
                                        onChange={(e) => handleChange("Password", e.target.value)}
                                        onBlur={() => { handleSave("Password") }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 personal-info mt-top-20">
                        <h6 className='line-after pr-info'><span>Additional Information</span></h6>
                    </div>
                    <div className="create-insurance-form">
                        <div className="contain-insurance">
                            {questions &&
                                _.map(questions, (item, i) => {
                                    const isYesChecked = projectFacilityValues?.FacilityProfileAnswers?.some(a => a.QuestionId === item.ID && a.Answer === 'true');
                                    const isNoChecked = projectFacilityValues?.FacilityProfileAnswers?.some(a => a.QuestionId === item.ID && a.Answer === 'false');
                                    let hasError = error && error[i] && error[i].FacilityProfileAnswers
                                    return (
                                        <div className="row" key={i}>
                                            <div className="col-md-12  pd-right-45">
                                                <div className="radio-crtpt text-start">
                                                    <span className="sort-cate ft-16 ">{item?.Text}<span className='text-danger'>*</span>
                                                    </span>
                                                    <div className={`form-radio-dt ${hasError ? 'redborder' : ''}`}>
                                                        <input
                                                            type="radio"
                                                            className="sort-inp"
                                                            id={`sor-inp_y_${i}`}
                                                            value={"true"}
                                                            checked={isYesChecked && !isNoChecked}
                                                            onChange={(e) => handleAnswers(e, { QuestionId: item.ID, Answer: e.target.value })}
                                                        />
                                                        <label className="cat-label status-color ft-14" htmlFor={`sor-inp_y_${i}`}>Yes</label>
                                                    </div>
                                                    <div className={`form-radio-dt ${hasError ? 'redborder' : ''}`}>
                                                        <input
                                                            type="radio"
                                                            id={`sor-inp_n_${i}`}
                                                            className="sort-inp"
                                                            value={"false"}
                                                            checked={isNoChecked && !isYesChecked}
                                                            onChange={(e) => handleAnswers(e, { QuestionId: item.ID, Answer: e.target.value })}
                                                        />
                                                        <label className="cat-label status-color ft-14" htmlFor={`sor-inp_n_${i}`}>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>


                <div className="footer-btn">
                    <div className="row">
                        <div className="col-md-6">

                        </div>
                        <div className="col-md-6">
                            <div className="sv-btn">
                                <button onClick={handleNext} type="button" className="btn-blue cst-blue bl-dt btnNext"
                                    data-bs-toggle="modal" data-bs-target="#view-validation">
                                    <Icon icon={"material-symbols:skip-next-sharp"} style={{ fontSize: "20px" }} />Next</button>
                                <button onClick={(e) => handleSubmit(e)} type="button" className="btn-grey cst-gry" data-bs-dismiss="modal">
                                    <Icon icon={"carbon:save"} style={{ fontSize: "20px" }} />Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
});

const mapDispatchToProps = { onBoarding, getQuestionsServices, getProjectCompletion }
const mapStateToProps = (state) => ({
    project: state?.facility && state?.facility?.projectDetails || null,
    states: state.facility && state.facility.states
})
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Payroll);

