import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { MessageType } from "../../../util/enum.js";

const MessageBox = (props) => {
  const { type, message, setMessage } = props;
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const handleClose = () => {
    setMessage(null);
  };

  if (type === MessageType.Success && message) {
    return (
      <div className="success_box alertbox_encounter">
        <Icon icon="clarity:success-standard-solid" />
        {message}
        <button onClick={handleClose}>
          <Icon className="clsbtn" icon="codicon:chrome-close" />
        </button>
      </div>
    );
  }
  if (type === MessageType.Error && message) {
    return <div className="error_box alertbox_encounter" style={{ display: "flex" }}>
      <Icon className="ic1" icon="akar-icons:circle-alert" />
      <div className="pswrdblock">
        {typeof message !== "string" && message[0] && message[0].length > 0
          ? message[0].split("*").map((c, i) => {
            return <p>{i > 0 ? `* ${c}` : c}</p>;
          })
          : message}
      </div>
      <button onClick={handleClose}>
        <Icon className="clsbtn" icon="codicon:chrome-close" />
      </button>
    </div>
  }
  return <></>;
};

export default MessageBox;
