import validate from "validate.js";

const message = '^Some required information is missing or incomplete. Please correct your entries and try again.'


validate.extend(validate.validators, {
    uszip: (value) => {  
        if (value === null || value === '') {
            return null
        }
        else if (value && value.length<5) {
            // alert(!/^\d{5}$/.test(value))
            return "^Please enter a valid zip code";
        }
        return null
    },

    phone: (value) => { 
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{10}$/.test(value)) {
            return "^Please enter a valid phone number";
        }
        return null
    },
    Validfax: (value) => {  
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{10}$/.test(value)) {
            return "^Please enter a valid fax number";
        }
        return null
    },

    email: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\S+@\S+\.\S+$/.test(value)) {
            return "^Please enter a valid email address";
        } else {
            return null
        }
    },
    // name: (value) => {
    //     if (value === null || value === '') {
    //         return null
    //     }
    //     else if (value && !/[A-Za-z]/.test(value)) {
    //         return "^Enter a valid ";

    //     }
    //     else {
    //         return null;
    //     }
    // },
    // state: (value) => {
    //     if (value === null || value === '') {
    //         return null
    //     }
    //     else if (value && !/[0-100]/.test(value)) {
    //         return "^Select a valid ";
    //     }
    //     else {
    //         return null;
    //     }
    // },
    NPI: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{10}$/.test(value)) {
            return "^ Please enter a valid NPI #";
        }
        return null
    },
    elevenDigits: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{11}$/.test(value)) {
            return "^Please enter a valid number";
        }
        return null
    },
    alphaNumeric: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^[a-zA-Z0-9]{11}$/.test(value)) {
            return "^Please enter a valid number";
        }
        return null
    },
    nineDigits: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "^Please enter a valid number";
        }
        return null
    },
    FEIN: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "^Please enter a valid FEIN #";
        }
        return null
    },
    CDPH: (value) => {
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^\+?\d{9}$/.test(value)) {
            return "^Please enter a valid CDPH LIC #";
        }
        return null
    },
    webSite: (value) => { 
        if (value === null || value === '') {
            return null
        }
        else if (value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
            return "^Please enter a valid website";
        }
        return null
    },
    datepick: (value) => {
        if (value === null || value === '') {
            return null
        }
    }, 

})


export const validationSchema = {
    BusinessName: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true,
    },
    CorporateStatus: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    FEIN: {
        presence: {
            allowEmpty: false,
            message: message
        },
        FEIN: true,
    },
    NPI: {
        presence: {
            allowEmpty: false,
            message: message
        },
        NPI: true
    },
    CDPH: {
        presence: {
            allowEmpty: false,
            message: message
        },
        CDPH: true,
    },
    CDPHExpiryDate: {
        presence: {
            allowEmpty: false,
            message: message
        },
        datepick:true
    },

    FacilityPhone: {
        presence: {
            allowEmpty: false,
            message: message
        },
        phone: true,

    },
    FacilityEmail: {
        presence: {
            allowEmpty: false,
            message: message
        },
        email: true,
    },
    FacilityAddress: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true
    },
    FacilityCity: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true
    },
    FacilityState: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // state: true

    },
    FacilityPincode: {
        presence: {
            allowEmpty: false,
            message: message
        },
        uszip: true
    },
    FacilityFax: {
        presence: {
            allowEmpty: false,
            message: message
        },
        Validfax: true,
    },
    WebsiteUrl: { 
        //  webSite: true
    },
    Contact1Name: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true,
    },
    Contact1Title: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true,
    },
    Contact1Mobile: {
        presence: {
            allowEmpty: false,
            message: message
        },
        phone: true,
    },
    Contact1Email: {
        presence: {
            allowEmpty: false,
            message: message
        },
        emailID: true,
    },
    // Contact2Name: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     name: true,
    // },
    // Contact2Title: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     name: true,


    // },
    // Contact2Mobile: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     phone: true
    // },
    // Contact2Email: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     email: true
    // },
    // CaseManagerName: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     name: true,
    // },
    // CaseManagerAddress: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     name: true,
    // },
    // CaseManagerState: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    // },
    // CaseManagerPincode: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     zip: true,
    // },
    // CaseManagerPhone: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     phone: true
    // },
    // CaseManagerFax: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     nineDigits: true,
    // },
    // CaseManagerEmail: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     emailID: true
    // },
    MailingLine1: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true,
    },
    MailingCity: {
        presence: {
            allowEmpty: false,
            message: message
        },
        // name: true,
    },
    // MailingSuit: {
    //     presence: {
    //         allowEmpty: false,
    //     },
    //     name: true,
    // },
    MailingState: {
        presence: {
            allowEmpty: false,
            message: message
        },
    },
    MailingPincode: {
        presence: {
            allowEmpty: false,
            message: message
        },
        uszip: true,
    },
}

export const dataValidationSchema = { 
    FEIN: { 
        FEIN: true,
    },
    NPI: { 
        NPI: true
    },
    CDPH: { 
        CDPH: true,
    },
    FacilityPhone: { 
        phone: true, 
    },
    FacilityEmail: { 
        email: true,
    }, 
    FacilityFax: {
        Validfax: true,
    },
    FacilityPincode: { 
        uszip: true
    },
    WebsiteUrl: {  
        //  webSite: true
    }, 
    Contact1Mobile: { 
        phone: true,
    },
    Contact1Email: { 
        emailID: true,
    }, 
    CaseManagerPincode:{
        uszip: true   
    },
    CaseManagerPhone:{
        phone: true,
    },
    CaseManagerFax:{
        Validfax: true,
    },
    CaseManagerEmail:{
        emailID: true,
    },
    BillingPincode:{
        uszip: true    
    },
    BillingPhone:{
        phone: true,
    },
    BillingFax:{
        Validfax: true,
    },
    BillingEmail:{
        emailID: true,
    },
    MailingPincode: { 
        uszip: true,
    },
}
