export default {
    Name: {
        presence: { allowEmpty: false, message: "^Please fill the required fields" }, 
    },
    Phone: {
        presence: { allowEmpty: false, message: "^Please fill in the required fields" },
        length: {
            is: 10,
            message: "^Enter a valid phone number",
        },
    },
    Email: {
        presence: { allowEmpty: false, message: "^Please fill in the required fields" },
        email: { required: true, message: '^Please enter a valid email address' },
        length: {
            maximum: 64,
        },
    },
    Rule: {
        presence: { allowEmpty: false, message: "^Please fill the required fields" }, 
    },
    Percent: {
        presence: { allowEmpty: false, message: "^Please fill the required fields" }, 
    },
};