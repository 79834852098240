import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  getDocuments, uploadDocument, openDocument,onBoarding,getDoctypesExcluded,getProjectCompletion} from '../../../../store/facilityProfiling/action';
import _ from 'lodash'
import { doctypeByCategory} from '../../../../util/helper'
import { Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";
import moment from 'moment';
import Loader from '../../../../components/Common/SpinnerLoader'   
import { FacilityUserForm, MessageType } from '../../../../util/enum';
import MessageBox from '../../../../components/Common/MessageBox';

const Documents = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
      return { 
        pendingUpload,
        uploads,
        setUploads,
        validateForm,
        mandatoryDocuments,
        totalUploads
      };
  });
  const { doc_categories, getDocuments,setMessage,totalHealth,setType,setViewFields,getProjectCompletion,uploadDocument, projectDetails, openDocument, categories, doctypes,setDeleteDocID, finalFormSubmission, setSaveAutoCalled, ProjectId , refreshDocs,getDoctypesExcluded} = props
  const [selected, setSelected] = useState(1);
  const [docCategory, setDocCategory] = useState([]);
  const [uploads, setUploads] = useState([]);
  const uploadRef = useRef([])
  const [loader, setLoader] = useState([])
  const [error, setError] = useState({});
  const [notFilled, setNotFilled]= useState(false);
  const [total, setTotal] =useState(doctypes?.length)
  const [totalUploads, setTotalUploads] =useState()
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [doctypesExcluded, setDoctypesExcluded] = useState([]);
  const [mandatoryDocuments, setMandatoryDocuments] = useState([])
  const [completionPercentage, setCompletionPercentage] = useState([])
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchData()
    async function fetchData() {
      if (doctypes && categories) {
        const result = doctypeByCategory(categories && _.orderBy(categories.data, [c => c.Title.toLowerCase()], ['asc']), doctypes && doctypes)
        setDocCategory(result)
        const docs = await getDocuments(ProjectId)
        setUploads(docs)
        const excludedDoctypes = await getDoctypesExcluded(ProjectId)
        setDoctypesExcluded(excludedDoctypes)
        const progress = await getProjectCompletion(ProjectId);
        setCompletionPercentage(progress); 
        docs.ProjectId = ProjectId
        setTimeout(() => {
          setLoading(false)
          setSaveAutoCalled(docs);
          totalHealth()
        }, 1000);
      }
    }
  }, [projectDetails, categories, doctypes])

  useEffect(()=>{
    if(doctypesExcluded && doctypes && uploads){ 
      let DocumentsRequiredFieldCount = doctypes?.length - doctypesExcluded?.length;
      setTotal(DocumentsRequiredFieldCount)
      let mandatoryDocuments = doctypes.filter((elem) => !doctypesExcluded.find(({ DocTypeId }) => elem.ID == DocTypeId)); 
      setMandatoryDocuments(mandatoryDocuments)
      let uploadedMandatoryDocuments =  uploads.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.DocTypeId == ID));
      setTotalUploads(uploadedMandatoryDocuments)
    }
  },[doctypesExcluded,doctypes,uploads])

  const handleSelect = (ID, index) => {
    setSelected(index+1); 
    const id = `documents_${index}`;
    const yOffset = -200;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset; 
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
  const pendingUpload = async () =>{
    if(mandatoryDocuments && totalUploads && mandatoryDocuments.length && totalUploads.length) return mandatoryDocuments.length !== totalUploads.length
    else{
      return true
    }
  }

  const UpdateDocumentsPercentage  = async(uploadedDocLength) => { 
    let CompletionPercentage = []
    const percentage = uploadedDocLength * (100/total);
    let res = completionPercentage?.filter((e) => e && e.Tab === FacilityUserForm.DOCS) || [];
    if (res && res.length) {
      res[0].Percentage = percentage; 
      CompletionPercentage= res;
    } else {
      CompletionPercentage = [{
          Tab: FacilityUserForm.DOCS,
          Percentage: percentage, 
        }];
    }  
    return CompletionPercentage;
}

  const handleUpload = async (event, doc, uploadeddoc) => {
    if (event.target.files) {
      setLoader([doc.ID])
      const file = event.target.files.length > 0 ? event.target.files[0] : event.target.files;
      // if (!(file.type.includes("application/pdf") ||
      //   file.type.includes("image/png") ||
      //   file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || file.type.includes("application/msword"))) {
      //   return;
      // }
      const { name, type, size } = file;
      const arrFiles = {
        file: {
          name: name.replace(/\s/g, ''),
          type: type,
          buffer: file,
          size: size,
          folder: ProjectId + "/" + doc.ID,
        },
        DocTypeId: doc.ID,
        DocName: name,
        MimeType: type,
        ProjectId: ProjectId
      };
      if (uploadeddoc) {
        arrFiles.ID = uploadeddoc.ID
      }
      let totalDocuments =totalUploads.length;
      if(mandatoryDocuments.length !=totalUploads.length && mandatoryDocuments.filter((d)=> d.ID === doc.ID).length){
        totalDocuments = totalDocuments+1;
      }
     let CompletionPercentage = await UpdateDocumentsPercentage(totalDocuments); 
     await uploadDocument(arrFiles, CompletionPercentage)
      const docs = await getDocuments(ProjectId)
      let res = CompletionPercentage?.filter((e) => e && e.Tab === FacilityUserForm.DOCS) || [];
      if (res && res.length && !res[0].ID) {
        const progress = await getProjectCompletion(ProjectId);
        setCompletionPercentage(progress);
      } 
      setUploads(docs)
      let uploadedMandatoryDocuments =  docs.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.DocTypeId == ID));
      setTotalUploads(uploadedMandatoryDocuments)
      setLoader([])
      if(!uploadeddoc)
      {
        setTotal(total-1)
      } 
      setSaveAutoCalled(doc)
      totalHealth()
    }
  }

  const handleOpenDoc = async (url) => {
    let docUrl = await openDocument(url);
    if (docUrl && docUrl.signedUrl) {
      window.open(docUrl.signedUrl)
    }
  }

  const validateForm = async (viewFields = false) => {
    let errors = mandatoryDocuments.length !== totalUploads.length;
    let DocsNotUploaded = mandatoryDocuments.filter((d)=> !totalUploads.find(({DocTypeId})=> d.ID === DocTypeId))
    if(DocsNotUploaded.length > 0){
      setNotFilled(true);
      }
    if (errors || DocsNotUploaded.length > 0) {
      const message = 'Some required information is missing or incomplete. Please correct your entries and try again.';
      setType(MessageType.Error);
      setMessage(message);
      setViewFields(viewFields)
      return false;
    } else {
      return true;
    }
  };

  // const validateForm = async (viewFields = false) => {
  //   if(mandatoryDocuments.length !== totalUploads.length){
  //     const message = 'Some required information is missing or incomplete. Please correct your entries and try again.';
  //     setType(MessageType.Error);
  //     setMessage(message);
  //     setViewFields(viewFields)
  //   } else {
  //     return true;
  //   }
  // }

  const onSubmit = async () => {
      finalFormSubmission() 
  }

  const renderDocuments = (docCategory) => {
    return Object.entries(docCategory).map(([k, v], index) => {
      return <div className="finances-data" id={`documents_${index}`}>
        <h6 className='line-after pr-info pr-blue'><span>{k}</span></h6>
        {v.map((d, u) => { 
          return <>
            <h4>{d[0].Title && d[0].Title}</h4>
            <div className="tab-fin">
              {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length > 0 && 
              <table className="table fin-table tab-cl">
                <thead>
                  <tr>
                    <th scope="col">Document name</th>
                    <th scope="col">Uploaded date</th>
                    <th scope="col" className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="fin-tb-body">
                  {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).map((doc, i) => {
                    if (uploads.filter(u => u.DocTypeId === doc.ID).length) {
                      let up_doc = uploads.find(u => u.DocTypeId === doc.ID);
                      return(<> <tr key={i} className=" clr-grey">
                         <td className="wt-preline cr-gry">{doc.Description} <span className='rd-mandatory'>*</span>  <br />
                          <span><a href="#" onClick={() => handleOpenDoc(up_doc.DocExtURL)}><span className="iconify"
                            data-icon="mdi:paperclip"></span>{up_doc.DocName}</a></span>
                        </td>
                        <td className="cr-gry">{`${moment(up_doc.LastModifiedAt).format('dddd')},  ${moment(up_doc.LastModifiedAt).format("MMMM Do YYYY")}`}</td>
                        <td>
                          <div className="action-btn">
                            {loader.filter(l => l === doc.ID).length > 0 ? <Spinner
                              size='sm'
                              animation="border"
                              style={{ color: '#007ACC' }}
                              role="status">
                            </Spinner> :
                              <>
                                <button onClick={() => handleOpenDoc(up_doc.DocExtURL)}>
                                  <Icon icon={"ic:outline-remove-red-eye"} className="iconify" />
                                </button>
                                <button onClick={(e) => { uploadRef.current[doc.ID].click() }}>
                                  <Icon icon={"mdi:file-document-refresh-outline"} className="iconify" />
                                </button>
                                <input ref={el => uploadRef.current[doc.ID] = el} type="file" className='d-none' accept=".doc, .docx, .pdf" onChange={(e) => { handleUpload(e, doc, up_doc); }}
                                />
                                 <button onClick={(e) => { setDeleteDocID(up_doc); window.$(`#_delete`).modal("show"); }}>
                                  <Icon icon={"fluent:delete-24-regular"} className="iconify" />
                                </button>
                              </>
                            }</div>
                        </td>
                      </tr>
                      </>)
                    }
                    return <tr key={i}>
                      <td className={`wt-preline ${notFilled? 'rd-doc':''}`}>{doc.Description }<span className='rd-mandatory'>*</span></td>
                      <td>-</td>
                      <td>
                        <div className="action-btn">
                          {/* <button><span className="iconify btn-disable" data-icon="ic:outline-remove-red-eye"></span>
                              </button> */}
                          {loader.filter(l => l === doc.ID).length > 0 ? <Spinner
                            size='sm'
                            animation="border"
                            style={{ color: '#007ACC' }}
                            role="status">
                          </Spinner> : <>
                            <button onClick={(e) => { uploadRef.current[doc.ID].click() }} disabled={loader.length > 0 ? true: false}>
                              <Icon icon={"material-symbols:upload-rounded"} className="iconify" />
                            </button>
                            { notFilled === true ? 
                            <div>
                              <Icon className="me-1" icon="mdi:warning-circle-outline" fontSize={15} style={{ color: 'red' }} />
                            </div>:''}
                            <input ref={el => uploadRef.current[doc.ID] = el} type="file" className='d-none' onChange={(e) => { handleUpload(e, doc); }}
                            /></>}

                        </div>
                      </td>
                    </tr>

                  })}
                  {/* {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length == 0 && 
                  <tr>
                    <td></td>
                    <td>No documents are required to be uploaded under this category.</td>
                  </tr>
                  } */}
                </tbody>
              </table>}
              {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length == 0 && 
                  <tr>
                    <td></td>
                    <td>No documents are required to be uploaded under this category.</td>
                  </tr>
                  }
            </div>
          </>
        })}

      </div>
    })
  }

if (loading) {
  return (
    <div className='d-flex justify-content-center'>
      <Loader loading={loading} height={'20rem'} width={'20rem'} />
    </div>
  )
}


  return (
    <>
    <MessageBox setType={setType} setMessage={setMessage} />
  <div class="document-data">
      <div class="row">
      <div class="col-md-2 rit-border">
        <div class="nav-menu-data" id="header" >
          <nav id="navbar" class="navbar">
            <ul>
              {doc_categories && _.orderBy(doc_categories, [c => c.Title.toLowerCase()], ['asc']).map((d, index) => <li key={index}><a className={`${selected === (index+1) ? 'nav-link scrollto active cursor-pointer' : 'nav-link scrollto cursor-pointer'}`} to="#" onClick={() =>handleSelect(d.ID,index)}>{d.Title}</a></li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className="col-md-10">
        {renderDocuments(docCategory)}
        <div className="footer-btn">
          <div className="row">
           <div className="col-md-6">
            {(totalUploads.length) !== mandatoryDocuments.length ?
              <p><span className="iconify" data-icon="icon-park-outline:attention"></span>
                {' '}{mandatoryDocuments.length - totalUploads.length} of {mandatoryDocuments.length}{' '}documents are yet to be uploaded</p> :''}
            </div>
          
            <div className="col-md-12">
              <div className="sv-btn">
                <button type="button"
                  class="btn-blue cst-blue bl-dt btnNext d-flex" 
                  onClick={(e)=> {e.preventDefault(); onSubmit()}}
                  > {isLoading && ( <div className="loader" style={{marginTop:4}}></div>)}
                  Submit</button> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </>
  );
})

const mapDispatchToProps = { getDocuments, uploadDocument, openDocument,onBoarding, getDoctypesExcluded,getProjectCompletion }
const mapStateToProps = (state) => ({
  doc_categories: state.facility && state.facility.doc_category && state.facility.doc_category.data
    && state.facility.doc_category.data.length && state.facility.doc_category.data || [],
  projectDetails: state.facility && state.facility.projectDetails || null,
    doctypes: state.facility && state.facility.doc_types,
    categories: state.facility && state.facility.doc_category

})
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Documents);
   