/* eslint-disable import/no-anonymous-default-export */

export default (state = {}, action) => {
    switch (action.type) {

        case 'FACILITY':
            return {
                ...state,
                facility: action.data,
            };
        case 'PROJECT_DETAILS':
            return {
                ...state,
                projectDetails: action.data && action.data.data && action.data.data[0] || [],
            };
        case 'FACILITY_UPDATE':
                return {
                    ...state,
                    projectDetails: action.data,
                };
        case 'DOC_CATEGORY':
            return {
                ...state,
                doc_category: action.data,
            };
        case 'DOC_TYPES':
                return {
                    ...state,
                doc_types: action.data,
                };
        // case 'DOC_TYPES':
        //         return {
        //             ...state,
        //             doc_types: action.data,
        //         };
        case 'FACILITY_OWNERS':
            return {
                ...state,
                fowners: action.data,
            }; 
        case 'STATES':
                return {
                    ...state,
                states: action.data,
                };
        case 'CLEAR_PROJECT_DETAILS':
            return {
                ...state,
                projectDetails: [],
            };
        default:
            return state;
    }
}