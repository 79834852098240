import store from "..";

export const errorHandler = async (err) => {
  let { message } = err;  
  switch (err.code) {
    case 401: // auth failed
      if (
        err.message === "invalid signature" ||
        err.message === "Not authenticated" ||
        err.message === "jwt expired"
      ) {
        message = "Your session is expired. Please re-login"; 
        localStorage.removeItem("feathers-jwt");
        localStorage.removeItem("persist:root");
        await store.dispatch({
          type: "LOGOUT",
        });
      } else if (err.message === "SMS is not supported by landline phone number") {
        message = "SMS is not supported by landline phone number, please contact administrator.";
      }
      else if ((err.message === "Email ID or password is incorrect") || (err.message === "Invalid login")) {
        message = "Email id or password is incorrect";
      } else if (err.message === "OTP wrong.") {
        message = "You have entered a wrong code.";
      } else if (err.message === "OTP expired.") {
        message = "Your code is expired. Please re-login";
      } else if (err.message === "Too many requests") {
        message = "Too many requests";
      } else if (err.message === "jwt malformed") {
        message = ""
      } else if (err.data && err.data.text === "OTP sent") {
        store.dispatch({
          type: "OTP_VERIFY",
          data: err.data,
        });
        return true;
      }  
      break;
    case 400: //validation error
      if (err.errors && err.errors[0]) {
        const error = err.errors[0];
        if(error.message==="mobile must be unique" || error.message === "users.mobile must be unique" )
        {
          message = "Mobile number already exists. ";
        }
        if (error.message === "users.email must be unique" || error.message==="email must be unique") {
          message = "Email address already exists.";
        }
      }
      break;
    case 404: // server error
      message = "Opps... something went wrong";
      break;
    case 408: // timesout
      message = "Request timed out, please try again";
      break;
    default:
      break;
  }

  store.dispatch({
    type: "SHOW_ERROR",
    data: { ...err, message },
  });
};

export const removeError = () => {
  return store.dispatch({
    type: "REMOVE_ERROR",
    data: {},
  });
};
