/* eslint-disable import/no-anonymous-default-export */
import PublicLayout from "../layouts/Public";
import NotFound from "../containers/NotFound";
import FacilityUserLogin from "../containers/Account/FacilityUserLogin";
import FacilityProfile from "../containers/FacilityProfiling";
import GuestLayout from "../layouts/Guest";   
import ThankYou from "../containers/FacilityProfiling/Tabs/ThankYou";
import Dashboard from "../containers/Dashboard";

// routes
export default [
  {
    path: "login",
    View: FacilityUserLogin,
    Layout: PublicLayout 
  },
  {
    path: "/*",
    View: NotFound,
    Layout: PublicLayout,
    isProtected: false,
    userPrevillege: "all",
  },
  {
    path: "/facility-profile/:id",
    View: FacilityProfile,
    Layout: GuestLayout,
    isProtected: true,
    userPrevillege: "all"
  },
  {
    path: "/thankyou",
    View: ThankYou,
    Layout: GuestLayout,
    isProtected: true,
    userPrevillege: "all"
  },
  {
    path: "/dashboard",
    View: Dashboard,
    Layout: GuestLayout,
    isProtected: true,
    userPrevillege: "all"
  },

];
