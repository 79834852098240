import { Icon } from '@iconify/react';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../store/auth/action';
function Header(props) {
  const {logout, showDashboardRedirection = false } = props
  const navigate = useNavigate();
  const logOut = async()=>{
   await logout();
   navigate("/login");
  }
  return (<div className="row ct-header cst-header">
    <div className="col-md-8 ct-left">
      <h1>SecondOpinionExpert, Inc.</h1>
      <h4>Point-of-Care Services</h4>
    </div>
    <div className="col-md-4 ct-right">
      <div className="log-out">
        {
          showDashboardRedirection && <Link to="/dashboard" className='bordersolid me-2'>
            <Icon icon={"tabler:dashboard"} className="iconify" fontSize={20} />
            {' '}Dashboard</Link>
        }

        <Link onClick={logOut}>
          <Icon icon={"material-symbols:logout-rounded"} className="iconify" />
          Logout</Link>
      </div>
    </div>
  </div>
      );
}


const mapDispatchToProps = { logout };
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
