import { Icon } from '@iconify/react';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import TextField from '../../../../components/Common/TextInput';
import DatePickerComponent from '../../../../components/Common/DatePicker';
import { getProjectCompletion, onBoarding } from '../../../../store/facilityProfiling/action';
import { connect } from 'react-redux';
import { FacilityUserForm, MessageType, } from '../../../../util/enum';
import { validationSchema, dataValidationSchema } from './schema';
import _ from 'lodash';
import MessageBox from '../../../../components/Common/MessageBox';
import { validate } from 'validate.js';
import { arrayBufferToBase64, b64toBlob, formatInsurance, round } from '../../../../util/helper';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas'
import Loader from '../../../../components/Common/SpinnerLoader'
import { UpdateInsurancePercentage } from '../../../../util/progressUtil';


const Insurance = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      hasError,
      validateForm
    };
  });
  const { setSelected, setMessage, setType, onBoarding,selected, totalHealth, project, setViewFields, setSaveAutoCalled, setInvalidAlert, ProjectId, getProjectCompletion } = props;
  const [values, setValues] = useState({ ISMalpracticeInsured: false, IsCLInsuredAnnually: false, IsCMLInsuredAnnually: false, IsCGLInsuredAnnually: false });
  const [error, setError] = useState({});
  const [clear, setClear] = useState(false)
  const [signatureURL, setSignatureURL] = useState(false)
  const signedRef = useRef();
  const ID = project?.ID || 0;
  const startDate = new Date();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData(project) {
      if (project) {
        const res = formatInsurance(project);
        const progress = await getProjectCompletion(ProjectId);
        res.CompletionPercentage = progress;
        res.ProjectId = ProjectId;
        if (!res.AuthorizerSignedAt) {
          res.AuthorizerSignedAt = moment().format("MM/DD/YYYY");
        }
        if (res) {
          let signatureURL = res.AuthorizerSignBlob && arrayBufferToBase64(res.AuthorizerSignBlob, "image/png");
          setSignatureURL(signatureURL);
          setValues(res);
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        }
      }
    }
    fetchData(project);
  }, [project]);

  useEffect(() => {
    async function fetchData() {
        if (selected && selected.InsuranceNav) { 
            const progress = await getProjectCompletion(ProjectId); 
            setValues({...values, CompletionPercentage:progress}); 
        }
    } 
    fetchData()
}, [selected]);

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value })
    setSaveAutoCalled(value)
  }

  const handleDateChange = async (field, date) => {
    setValues({ ...values, [field]: date });
    let data = { ...values, [field]: date }
    data = await UpdateInsurancePercentage(data, field);
    let health = await totalHealth(data.CompletionPercentage);
    data = { ...data, ...health };
    let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES)[0].ProjectId > 0))
    await onBoarding(data, ID, skipDispatch);
    setSaveAutoCalled(date)
  }

  const handleRadioButton = async (field, event) => {
    if (event) {
      const newValue = event.target.value === "true";
      setValues({ ...values, [field]: newValue });
      let data = { ...values, [field]: newValue }
      data = await UpdateInsurancePercentage(data, field);
      let health = await totalHealth(data.CompletionPercentage);
      data = { ...data, ...health };
      let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES)[0].ProjectId > 0))
      await onBoarding(data, ID, skipDispatch);
      setSaveAutoCalled(newValue)
    }
  };

  const hasError = async () => {
    let errors = await validate(values, validationSchema);
    if (!values.AuthorizerSignBlob) {
      if (!errors) {
        errors = {}
      }
      errors.AuthorizerSignBlob = ["Some required information is missing or incomplete. Please correct your entries and try again."];
    }
    return errors
  }

  const validateForm = async (viewFields = false) => {
    let errors = await validate(values, validationSchema);
    if (!values.AuthorizerSignBlob) {
      if (!errors) {
        errors = {}
      }
      errors.AuthorizerSignBlob = ["Some required information is missing or incomplete. Please correct your entries and try again."];
    }
    setError(errors)
    if (errors) {
      const message = errors[Object.keys(errors)[0]][0];
      setType(MessageType.Error);
      setMessage(message);
      setViewFields(viewFields)
      setInvalidAlert(false)
      return false;
    } else {
      return true;
    }
  }

  const dataValidateForm = async (viewFields = false) => {
    const errors = await validate(values, dataValidationSchema);
    setError(errors)
    if (errors) {
      const message = errors[Object.keys(errors)[0]][0];
      setType(MessageType.Error);
      setMessage(message);
      setInvalidAlert(true)
      return false;
    } else {
      return true;
    }
  }


  const handleSave = async (field) => {
    let data = { ...values }
    data = await UpdateInsurancePercentage(data, field);
    let health = await totalHealth(data.CompletionPercentage);
    data = { ...data, ...health };
    let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES)[0].ProjectId > 0))
    await onBoarding(data, ID, skipDispatch);
    setSaveAutoCalled(field);
  }

  const handleSaveSignature = async (e) => {
    let data = { ...values };
    setClear(true);
    const base64 = signedRef.current.getTrimmedCanvas().toDataURL('image/png')
    const byteCharacters = b64toBlob(base64, 'esign.png', 'image/png');
    data.AuthorizerSignBlob = byteCharacters;
    data = await UpdateInsurancePercentage(data, "AuthorizerSignBlob");
    let health = await totalHealth(data.CompletionPercentage);
    data = { ...data, ...health };
    let skipDispatch = (ID > 0 && (values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES).length && values.CompletionPercentage.filter((e) => e && e.Tab === FacilityUserForm.INSURANCES)[0].ProjectId > 0))
    const res = await onBoarding(data, ID, skipDispatch);
    let signatureURL = res.AuthorizerSignBlob && arrayBufferToBase64(res.AuthorizerSignBlob, 'image/png')
    setSignatureURL(signatureURL)
    setSaveAutoCalled(data)
    setValues({ ...values, AuthorizerSignBlob: byteCharacters })
  }

  const handleSubmit = async (e) => {
    let isValid = await dataValidateForm();
    if (isValid) {
      setType(MessageType.Success);
      setMessage && setMessage("Your changes have been successfully saved!");
    }
  }

  const handleNext = () => {
    setSelected({
      Payroll: "show active", PayrollNav: true,
      PayrollActive: "active",
      Insurance: "", InsuranceNav: false, InsuranceActive: "",
    })
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }

  const renderSignatureCanvas = (blob) => {
    return (<>
      <div className='row'>
        <div class="col-md-12">
          <h5 className={` ${error && error.AuthorizerSignBlob ? 'rd-doc' : ''}`}>Authorizing Signature *</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="signature-pad">
            {blob && !clear ? <>
              <div className='sigCanvas' >
                <img src={blob} className='esignImg' />
              </div>
              <span class="btn" id="sig-clearBtn" onClick={() => { setValues({ ...values, AuthorizerSignBlob: null }); setClear(true) }} style={{ "marginleft": "380px" }}>Clear</span>
            </> : <>
              <SignatureCanvas onEnd={(e) => { handleSaveSignature(e) }} id="sig-canvas" ref={signedRef} canvasProps={{ width: 480, height: 200, className: ` sigCanvas ${error && error.AuthorizerSignBlob ? 'border-red' : ''}` }} />
              <span className="btn" id="sig-clearBtn" onClick={() => { signedRef.current.clear(); setValues({ ...values, AuthorizerSignBlob: null }); }} style={{ "marginleft": "380px" }}>Clear</span>
            </>
            }
          </div>
        </div>
      </div>
    </>)
  }

  if (loading) {

    return (
      <div className='d-flex justify-content-center'>
        <Loader loading={loading} height={'20rem'} width={'20rem'} />
      </div>
    )
  }

  return (
    <>
      <MessageBox setType={setType} setMessage={setMessage} />

      <div className="form-start pil-insur">
        <div className="row">
          <div className="col-md-12 personal-info">
            <h6 className='line-after pr-info'><span>General</span></h6>
          </div>
          <div className="create-insurance-form">
            <div className="contain-insurance">
              <div className="row mb-3">
                <div className="col-md-4 pd-right-45">
                  <p>Current general liability Coverage per occurrence start date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.CGLOInsuranceStart ? new Date(values.CGLOInsuranceStart) : null}
                      value={values?.CGLOInsuranceStart ? moment((values?.CGLOInsuranceStart)).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('CGLOInsuranceStart', date)}
                      wrapperClassName={`mr-bt-25  ${error && error.CGLOInsuranceStart ? "redborder" : ""}`}
                      className="date-picker-styles"
                      wrapperclassName='mr-bt-25'
                      placeholder='MM-DD-YY'
                      label="Start Date"
                      onBlur={() => handleSave('CGLOInsuranceStart')}

                    // iconClass={'top68'}
                    />
                  </div>
                </div>
                <div className="col-md-4 pd-right-45">
                  <p>Current general liability coverage per occurrence expiration date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.CGLOInsuranceEnd ? new Date(values.CGLOInsuranceEnd) : null}
                      value={values?.CGLOInsuranceEnd ? moment((values?.CGLOInsuranceEnd)).format("MM/DD/YYYY") : null}
                      wrapperClassName={`mr-bt-25  ${error && error.CGLOInsuranceEnd ? "redborder" : ""}`}
                      onChange={(date) => handleDateChange('CGLOInsuranceEnd', date)}
                      className="date-picker-styles"
                      placeholder='MM-DD-YY'
                      label="Expiry Date"
                      onBlur={() => handleSave('CGLOInsuranceEnd')}

                      // iconClass={'top68'}
                      futureDateOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 pd-right-45">
                  <p>Current general liability coverage per aggregated start date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.CGLAInsuranceStart ? new Date(values.CGLAInsuranceStart) : null}
                      value={values?.CGLAInsuranceStart ? moment(values?.CGLAInsuranceStart).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('CGLAInsuranceStart', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.CGLAInsuranceStart ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Start Date"
                      onBlur={() => handleSave('CGLAInsuranceStart')}

                    // iconClass={'top68'}
                    />
                  </div>
                </div>
                <div className="col-md-4 pd-right-45">
                  <p>Current general liability coverage per aggregated expiration date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.CGLAInsuranceEnd ? new Date(values.CGLAInsuranceEnd) : null}
                      value={values?.CGLAInsuranceEnd ? moment(values?.CGLAInsuranceEnd).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('CGLAInsuranceEnd', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.CGLAInsuranceEnd ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Expiry Date"
                      onBlur={() => handleSave('CGLAInsuranceEnd')}

                      // iconClass={'top68'}
                      futureDateOnly={true}

                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12  pd-right-45">
                  <div className="radio-crtpt text-start">
                    <span className="sort-cate ft-16 ">General coverage is renewed <span className='text-danger'>*</span></span>
                    <div className={`form-radio-dt ${error && error.IsCGLInsuredAnnually ? 'redborder' : ''}`}>
                      <input type="radio"
                        className="sort-inp"
                        id="annually"
                        name="IsCGLInsuredAnnually"
                        value={true}
                        checked={values?.IsCGLInsuredAnnually === true}
                        onChange={(event) => handleRadioButton('IsCGLInsuredAnnually', event)}
                      />
                      <label className="cat-label status-color ft-14" htmlFor="annually">Annually</label>
                    </div>
                    <div className={`form-radio-dt ${error && error.IsCGLInsuredAnnually ? 'redborder' : ''}`}>
                      <input
                        type="radio"
                        className="sort-inp"
                        id="continuous"
                        name="IsCGLInsuredAnnually"
                        value={false}
                        checked={values?.IsCGLInsuredAnnually === false}
                        onChange={(event) => handleRadioButton('IsCGLInsuredAnnually', event)}
                      />
                      <label className="cat-label status-color ft-14" htmlFor="continuous">Continuous</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contain-insurance mt-top-20">
              <div className="col-md-12 personal-info">
                <h6 className='line-after pr-info'><span>Medical Liability</span></h6>
              </div>
              <div className="row mb-3">

                <div className="col-md-4 pd-right-45">
                  <p>Current medical liability coverage per occurrence start date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.MLOInsuredStart ? new Date(values.MLOInsuredStart) : null}
                      value={values?.MLOInsuredStart ? moment(values?.MLOInsuredStart).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('MLOInsuredStart', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.MLOInsuredStart ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Start Date"
                      onBlur={() => handleSave('MLOInsuredStart')}

                    // iconClass={'top68'}
                    />
                  </div>
                </div>
                <div className="col-md-4 pd-right-45">
                  <p>Current medical liability coverage per occurrence expiration date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.MLOInsuranceEnd ? new Date(values.MLOInsuranceEnd) : null}
                      value={values?.MLOInsuranceEnd ? moment(values?.MLOInsuranceEnd).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('MLOInsuranceEnd', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.MLOInsuranceEnd ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Expiry Date"
                      onBlur={() => handleSave('MLOInsuranceEnd')}

                      // iconClass={'top68'}
                      futureDateOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 pd-right-45">
                  <p>Current medical liability coverage per aggregated start date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.MLAInsuranceStart ? new Date(values.MLAInsuranceStart) : null}
                      value={values?.MLAInsuranceStart ? moment(values?.MLAInsuranceStart).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('MLAInsuranceStart', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.MLAInsuranceStart ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Start Date"
                      onBlur={() => handleSave('MLAInsuranceStart')}

                    // iconClass={'top68'}
                    />
                  </div>
                </div>
                <div className="col-md-4 pd-right-45">
                  <p>Current medical liability coverage per aggregated expiration date</p>
                  <div className='inputformContainer'>
                    <DatePickerComponent
                      required={true}
                      selected={values?.MLAInsuranceEnd ? new Date(values.MLAInsuranceEnd) : null}
                      value={values?.MLAInsuranceEnd ? moment(values?.MLAInsuranceEnd).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('MLAInsuranceEnd', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.MLAInsuranceEnd ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Expiry Date"
                      onBlur={() => handleSave('MLAInsuranceEnd')}

                      // iconClass={'top68'}
                      futureDateOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12  pd-right-45">
                  <div className="radio-crtpt text-start">
                    <span className="sort-cate ft-16 ">General coverage is renewed<span className='text-danger'>*</span></span>
                    <div className={`form-radio-dt ${error && error.IsCMLInsuredAnnually ? 'redborder' : ''}`}>
                      <input
                        type="radio"
                        className="sort-inp"
                        id="annually-annually"
                        name="IsCMLInsuredAnnually"
                        value={true}
                        checked={values.IsCMLInsuredAnnually === true}
                        onChange={(event) => handleRadioButton('IsCMLInsuredAnnually', event)}
                      />
                      <label className="cat-label status-color ft-14" htmlFor="annually-annually">Annually</label>
                    </div>
                    <div className={`form-radio-dt ${error && error.IsCMLInsuredAnnually ? 'redborder' : ''}`}>
                      <input
                        type="radio"
                        className="sort-inp"
                        id="annually-continuous"
                        name="IsCMLInsuredAnnually"
                        value={false}
                        checked={values.IsCMLInsuredAnnually === false}
                        onChange={(event) => handleRadioButton('IsCMLInsuredAnnually', event)} />
                      <label className="cat-label status-color ft-14" htmlFor="annually-continuous">Continuous</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contain-insurance mt-top-20">
              <div className="col-md-12 personal-info">
                <h6 className='line-after pr-info'><span>Liability</span></h6>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 pd-right-45">
                  <p style={{width: "90%"}}>Current liability coverage per occurrence start date</p>
                  <div className='inputformContainer'>

                    <DatePickerComponent
                      required={true}
                      selected={values?.CLOInsuranceStart ? new Date(values.CLOInsuranceStart) : null}
                      value={values?.CLOInsuranceStart ? moment(values?.CLOInsuranceStart).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('CLOInsuranceStart', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.CLOInsuranceStart ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Start Date"
                      onBlur={handleSave}
                    // iconClass={'top60'}

                    />
                  </div>
                </div>
                <div className="col-md-4 pd-right-45">
                  <p>Current liability coverage per occurrence expiration date</p>
                  <div className='inputformContainer'>
                    <DatePickerComponent
                      required={true}
                      selected={values?.CLOInsuranceEnd ? new Date(values.CLOInsuranceEnd) : null}
                      value={values?.CLOInsuranceEnd ? moment(values?.CLOInsuranceEnd).format("MM/DD/YYYY") : null}
                      wrapperClassName={`mr-bt-25  ${error && error.CLOInsuranceEnd ? "redborder" : ""}`}
                      onChange={(date) => handleDateChange('CLOInsuranceEnd', date)}
                      className="date-picker-styles"
                      placeholder='MM-DD-YY'
                      label="Expiry Date"
                      onBlur={handleSave}
                      // iconClass={'top60'}
                      futureDateOnly={true}

                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 pd-right-45">
                  <p style={{width: "90%"}}>Current liability coverage per aggregated start date</p>
                  <div className='inputformContainer'>
                    <DatePickerComponent
                      required={true}
                      selected={values?.CLAInsuranceStart ? new Date(values.CLAInsuranceStart) : null}
                      value={values?.CLAInsuranceStart ? moment(values?.CLAInsuranceStart).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('CLAInsuranceStart', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.CLAInsuranceStart ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Start Date"
                      onBlur={handleSave}
                    // iconClass={'top60'}
                    />
                  </div>
                </div>
                <div className="col-md-4 pd-right-45">
                  <p>Current liability coverage per aggregated expiration date</p>
                  <div className='inputformContainer'>
                    <DatePickerComponent
                      required={true}
                      selected={values?.CLAInsuranceEnd ? new Date(values.CLAInsuranceEnd) : null}
                      value={values?.CLAInsuranceEnd ? moment(values?.CLAInsuranceEnd).format("MM/DD/YYYY") : null}
                      onChange={(date) => handleDateChange('CLAInsuranceEnd', date)}
                      className="date-picker-styles"
                      wrapperClassName={`mr-bt-25  ${error && error.CLAInsuranceEnd ? "redborder" : ""}`}
                      placeholder='MM-DD-YY'
                      label="Expiry Date"
                      onBlur={handleSave}
                      // iconClass={'top60'}
                      futureDateOnly={true}

                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12  pd-right-45">
                  <div className="radio-crtpt text-start">
                    <span className="sort-cate ft-16 ">General coverage is renewed<span className='text-danger'>*</span></span>
                    <div className={`form-radio-dt ${error && error.IsCLInsuredAnnually ? 'redborder' : ''}`}>
                      <input
                        type="radio"
                        className="sort-inp"
                        id="general-annually"
                        name="IsCLInsuredAnnually"
                        value={true}
                        checked={values.IsCLInsuredAnnually === true || ''}
                        onChange={(event) => handleRadioButton('IsCLInsuredAnnually', event)}
                      />
                      <label className="cat-label status-color ft-14" htmlFor="general-annually">Annually</label>
                    </div>
                    <div className={`form-radio-dt ${error && error.IsCLInsuredAnnually ? 'redborder' : ''}`}>
                      <input
                        type="radio"
                        className="sort-inp"
                        id="general-continuous"
                        name="IsCLInsuredAnnually"
                        value={false}
                        checked={values.IsCLInsuredAnnually === false || ''}
                        onChange={(event) => handleRadioButton('IsCLInsuredAnnually', event)}
                      />
                      <label className="cat-label status-color ft-14" htmlFor="general-continuous">Continuous</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4 pd-right-45 mt-top-20">
                <TextField
                  maxLength={100}
                  required
                  type="text"
                  name='MalpracticeCarrierName'
                  value={values.MalpracticeCarrierName || null}
                  error={error && error.MalpracticeCarrierName}
                  label="Malpractice carrier name"
                  className="inputformdata form-control"
                  id="DBA"
                  placeholder="Malpractice carrier name"
                  wrapperClassName={`mr-bt-25  ${error && error.MalpracticeCarrierName ? "redborder" : ""}`}
                  onChange={(e) => handleChange("MalpracticeCarrierName", e.target.value)}
                  onBlur={() => handleSave("MalpracticeCarrierName")}


                />

              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4  pd-right-45">
                <div className="radio-crtpt text-start">
                  <span className="sort-cate ft-16 ">Are physicians, practitioners and professional clinicians
                    covered under the malpractice insurance?<span className='text-danger'>*</span></span>
                  <div className={`form-radio-dt ${error && error.ISMalpracticeInsured ? 'redborder' : ''}`}>
                    <input
                      type="radio"
                      className="sort-inp"
                      id="practitioners-yes"
                      name="ISMalpracticeInsured"
                      value={true}
                      checked={values.ISMalpracticeInsured == true}
                      onChange={(event) => handleRadioButton('ISMalpracticeInsured', event)} />
                    <label className="cat-label status-color ft-14" htmlFor="practitioners-yes">Yes</label>
                  </div>
                  <div className={`form-radio-dt ${error && error.ISMalpracticeInsured ? 'redborder' : ''}`}>
                    <input
                      type="radio"
                      className="sort-inp"
                      id="practitioners-no"
                      name="ISMalpracticeInsured"
                      value={false}
                      checked={values.ISMalpracticeInsured == false}
                      onChange={(event) => handleRadioButton('ISMalpracticeInsured', event)} />
                    <label className="cat-label status-color ft-14" htmlFor="practitioners-no">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 personal-info mt-top-20">
            <h6 className='line-after pr-info'><span>I Certify That</span></h6>
          </div>
          <div className="row">
            <div className="col-md-12 application-data ct-data">
              <p>I understand the effective date of participation is the date the application is actually approved by
                {'  '} <input id="approved-by" type="text" name="CertifyField1" className={`certify-data ${error && error.CertifyField1 ? 'redborder-btm' : ''}`} required="required" minLength="3" maxLength="20" placeholder='Type here'
                  value={values.CertifyField1}
                  onBlur={() => handleSave("CertifyField1")}

                  onChange={(event) => handleChange("CertifyField1", event.target.value)}
                />
                and is not the date the application was submitted or received.</p>
              <p>The information contained in this application is complete and accurate. </p>
              <p>

                I understand that  {'  '}<input id="verify-by" type="text" name="CertifyField2" className={`certify-data textline ${error && error.CertifyField1 ? 'redborder-btm' : ''}`} required="required" placeholder='Type here'
                  value={values.CertifyField2}
                  onBlur={() => handleSave("CertifyField2")}

                  onChange={(event) => handleChange("CertifyField2", event.target.value)}
                /> may do an on‐site survey after review of this application to verify program compliance and to verify the accuracy of any information provided.</p>
              <p>I understand the facility is not eligible to submit claims for payment until it is approved by
                {'  '}<input id="verify-by" type="text" name="CertifyField3" className={`certify-data ${error && error.CertifyField3 ? 'redborder-btm' : ''}`} required="required" placeholder='Type here'
                  value={values.CertifyField3}
                  onBlur={() => handleSave("CertifyField3")}

                  onChange={(event) => handleChange("CertifyField3", event.target.value)} /> , both parties sign the agreement(s), and the processing systems are updated.</p>
              <p>I will notify {'  '}<input id="verify-by" type="text" name="CertifyField4" className={`certify-data ${error && error.CertifyField4 ? 'redborder-btm' : ''}`} required="required" placeholder='Type here'
                value={values.CertifyField4}
                onBlur={() => handleSave("CertifyField4")}
                onChange={(event) => handleChange("CertifyField4", event.target.value)}
              /> immediately in writing of changes affecting this data.</p>
              <p>The authorized signer agrees that he/she has the company's designated authority to request and maintain minimum necessary Web access and is responsible for complying with all terms and conditions contained within the Provider Secured Service Use and Protection Agreement. </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 pd-right-45">
              <TextField
                maxLength={100}
                required
                type="text"
                name='AuthorizerName'
                value={values.AuthorizerName}
                error={error && error.AuthorizerName}
                label="Name"
                className="inputformdata form-control"
                id="AuthorizerName"
                placeholder="Name"
                wrapperClassName='mr-bt-25'
                onChange={(e) => handleChange("AuthorizerName", e.target.value)}
                onBlur={() => handleSave("AuthorizerName")}

              />

            </div>
            <div className="col-md-4 pd-right-45">
              <TextField
                maxLength={100}
                required
                type="text"
                name='AuthorizerTitle'
                value={values.AuthorizerTitle}
                error={error && error.AuthorizerTitle}
                label="Title"
                className="inputformdata form-control"
                id="AuthorizerTitle"
                placeholder="Title "
                wrapperClassName='mr-bt-25'
                onChange={(e) => handleChange("AuthorizerTitle", e.target.value)}
                onBlur={() => handleSave("AuthorizerTitle")}

              />

            </div>
            <div className="col-md-4  ">
              <div className=" inputformContainer mr-bt-35">
                <div className='inputformContainer'>
                  <DatePickerComponent
                    required={true}
                    selected={values?.AuthorizerSignedAt ? new Date(values.AuthorizerSignedAt) : startDate}
                    value={values?.AuthorizerSignedAt ? moment(values?.AuthorizerSignedAt).format("MM/DD/YYYY") : null}
                    onChange={(date) => handleDateChange('AuthorizerSignedAt', date)}
                    className="date-picker-styles"
                    wrapperClassName={`mr-bt-25  ${error && error.AuthorizerSignedAt ? "redborder" : ""}`}
                    placeholder='MM-DD-YY'
                    label="Date"
                    onBlur={() => handleSave('AuthorizerSignedAt')}

                    iconClass='right5'
                    futureDateOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {renderSignatureCanvas(signatureURL)}
        </div>

        <div className="footer-btn">
          <div className="row">
            <div className="col-md-6">

            </div>
            <div className="col-md-6">
              <div className="sv-btn">
                <button onClick={handleNext} type="button" className="btn-blue cst-blue bl-dt btnNext"
                  data-bs-toggle="modal" data-bs-target="#view-validation">
                  <Icon icon={"material-symbols:skip-next-sharp"} style={{ fontSize: "20px" }} />Next</button>
                <button onClick={(e) => handleSubmit(e)} type="button" className="btn-grey cst-gry" data-bs-dismiss="modal">
                  <Icon icon={"carbon:save"} style={{ fontSize: "20px" }} />Save</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
});

const mapDispatchToProps = { onBoarding, getProjectCompletion }
const mapStateToProps = (state) => ({
  project: state?.facility && state?.facility?.projectDetails || null
})
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Insurance);

