import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Loader from '../../components/Common/SpinnerLoader'
import { ProjectStatus } from "../../util/enum";
import { clear_form } from "../../store/facilityProfiling/action";
import _ from "lodash";
import { connect } from "react-redux";

const Projects = ({ list, loading, clear_form }) => {
    const GetProjectStatus = (project) => {
        if (project?.Status <= ProjectStatus.InProgress && project?.WMS_ProjectFacility?.length == 0) {
            return 'New'
        }
        if (project?.Status === ProjectStatus.InProgress && project?.WMS_ProjectFacility?.length > 0 && !project.WMS_ProjectFacility[0].Status) {
            return 'In Progress';
        }
        if (project?.Status === ProjectStatus.InProgress && project?.WMS_ProjectFacility?.length > 0 && project.WMS_ProjectFacility[0].Status === ProjectStatus.Completed) {
            return 'Completed';
        }
        if (project?.Status === ProjectStatus.Completed) {
            return 'Completed'
        }
    }

    const GetProjectStatusColor = (project) => {
        if (project.Status <= ProjectStatus.InProgress && project?.WMS_ProjectFacility?.length == 0) {
            return 'text-primary'
        }
        if (project.Status === ProjectStatus.InProgress && project?.WMS_ProjectFacility?.length > 0 && !project.WMS_ProjectFacility[0].Status) {
            return 'text-warning'
        }
        if (project.Status === ProjectStatus.InProgress && project?.WMS_ProjectFacility?.length > 0 && project.WMS_ProjectFacility[0].Status === ProjectStatus.Completed) {
            return 'text-success'
        }
        if (project.Status === ProjectStatus.Completed) {
            return 'text-success'
        }
    }

    return <div className="prj-data main-dashboard ">
        {loading && <Loader loading={loading} height={'20rem'} width={'65rem'} />}
        <div className="lin-dt">
            <h2>Facilities </h2>
        </div>
        <div className="row">
            {list && list.map((p, i) => {
                return <div className="col-md-4" key={i}>
                    <div className="prj-overview">
                        <div className="ovr-data">
                            <Link to={(p?.Status > ProjectStatus.InProgress || p?.WMS_ProjectFacility?.length > 0 && p?.WMS_ProjectFacility[0]?.Status > ProjectStatus.InProgress) ? '/dashboard' : `/facility-profile/${p.ID}`} onClick={clear_form}><Icon icon={"material-symbols:add-home-work-outline"} className="iconify" />
                            </Link>
                        </div>
                        <Link to={(p?.Status > ProjectStatus.InProgress || p?.WMS_ProjectFacility?.length > 0 && p?.WMS_ProjectFacility[0]?.Status > ProjectStatus.InProgress) ? '/dashboard' : `/facility-profile/${p.ID}`} onClick={clear_form}><h2>{p.Title}<span className={GetProjectStatusColor(p)}>{GetProjectStatus(p)}</span></h2></Link>
                    </div>
                </div>
            })
            }
        </div>
    </div>

}
const mapDispatchToProps = { clear_form }
const mapStateToProps = (state) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Projects); 