import round from "lodash/round";
import { FacilityUserForm } from "./enum";

// Required Fields of each tab
const DemographicsRequiredFieldCount = 21;
const ServicesRequiredFieldCount = 17;
const OwnershipRequiredFieldCount = 11;
const InsuranceRequiredFieldCount = 25;
const PayrollRequiredFieldCount = 31;
const PayrollRequiredFieldCountWithoutAcess = 29;

const ServiceQnIds = [1, 2, 3, 4, 5, 6];
const OwnershipQnIds = [9,10,11,12,13,15,16,17,18,19];
const payrollQnIds = [21,22,23,24,25];

const DemographicsRequiredFields = [
            'BusinessName',
            'CorporateStatus',
            'FEIN',
            'NPI',
            'CDPH',
            'CDPHExpiryDate',
            'FacilityAddress',
            'FacilityCity',
            'FacilityState',
            'FacilityPincode',
            'FacilityFax',
            'FacilityPhone',
            'FacilityEmail',
            'Contact1Name',
            'Contact1Title',
            'Contact1Mobile',
            'Contact1Email',
            'MailingLine1',
            'MailingCity',
            'MailingState',
            'MailingPincode',
        ]
const ServicesRequiredFields = [
            'ProviderType',
            'AccreditationAgency',
            'AccreditationIssueDate',
            'AccreditationExpiryDate',
            'ProviderLevel',
            'MD1Name',
            'MD1Phone',
            'MD1Type',
            'MD1address',
            'MD1FEIN',
            'MD2Name',
            'MD2Phone',
            'MD2Type',
            'MD2address',
            'MD2FEIN',
            'FacilityAgeRange',
        ]

const OwnershipRequiredFields = []

const InsuranceRequiredFields = [
            'CGLOInsuranceStart',
            'CGLOInsuranceEnd',
            'CGLAInsuranceStart',
            'CGLAInsuranceEnd',
            'MLOInsuredStart',
            'MLOInsuranceEnd',
            'MLAInsuranceStart',
            'MLAInsuranceEnd',
            'CLOInsuranceStart',
            'CLOInsuranceEnd',
            'CLAInsuranceStart',
            'CLAInsuranceEnd',
            'AuthorizerSignedAt',
            'IsCGLInsuredAnnually',
            'IsCMLInsuredAnnually',
            'IsCLInsuredAnnually',
            "MalpracticeCarrierName",
            'ISMalpracticeInsured',
            "AuthorizerName",
            "AuthorizerTitle",
            'AuthorizerSignBlob',
            "CertifyField1",
            "CertifyField2",
            "CertifyField3",
            "CertifyField4",
        ]

const PayrollRequiredFields = [
            'OwnerName',
            "Dob",
            "Phone",
            "SSN",
            "Line1",
            "City",
            "State",
            "Email",
            "CompanyName",
            "CompanyLine1",
            "CompanyCity",
            'CompanyState',
            'EIN',
            "NICSCode",
            "EDD",
            "NumPeopleWithAccess",
            "BankName",
            "BankRouteNum",
            'BankAccNum',
            "PayFrequency",
            "PayDates",
            "PaymentAmt",
            "HolidayPayDay",
            "IsPayrollCompany",
            "Login",
            "Password",
        ]

const PayrollRequiredFieldsWithoutAccess = [
          'OwnerName',
          "Dob",
          "Phone",
          "SSN",
          "Line1",
          "City",
          "State",
          "Email",
          "CompanyName",
          "CompanyLine1",
          "CompanyCity",
          'CompanyState',
          'EIN',
          "NICSCode",
          "EDD",
          "NumPeopleWithAccess",
          "BankName",
          "BankRouteNum",
          'BankAccNum',
          "PayFrequency",
          "PayDates",
          "PaymentAmt",
          "HolidayPayDay",
          "IsPayrollCompany", 
      ]
const DocumentsRequiredFields  = []

const DemographicsValuesCount = (field, values) => {
    const countState = Object.keys(values).reduce((prev, curr) => {
        if (DemographicsRequiredFields.includes(curr) && values[curr]) {
            return ({ ...prev, [curr]: true })
        }
        else {
            return ({ ...prev })
        }
    }, {});
    if (field) {
        if (values[field]) {
            countState[field] = true;
        }
        else {
            countState[field] = false;
        }
    }
    return countState;
}

const ServicesValuesCount = (values) => {
    let behavouCountState = (values.FacilityProfileAnswers?.filter((e) => e && ServiceQnIds.includes(e.QuestionId)) || []).reduce((prev, curr, index) => {
        if (curr.Answer) {
            return ({ ...prev, [curr.QuestionId]: true })
        } else {
            return ({ ...prev, [curr.QuestionId]: false })
        }
    }, {})
    const countState = Object.keys(values).reduce((prev, curr) => {
        if (ServicesRequiredFields.includes(curr) && values[curr]) {
            return ({ ...prev, [curr]: true })
        } else {
            return ({ ...prev, [curr]: false })
        }
    }, {}); 
    return { ...countState, ...behavouCountState };
}

const OwnershipValuesCount = (values, owners) => { 
       let ownerCountState = owners && owners.length > 0 ? { facilityOwners: true } : { facilityOwners: false } 
       const ownerShiCountpState = values.OwnerShipAnswers && values.OwnerShipAnswers.filter( (e) => e.QuestionId && OwnershipQnIds.includes(e.QuestionId)).reduce((prev, curr, index) => {
        if (curr.Answer) {
          return { ...prev, [curr.QuestionId]: true };
        } else {
          return { ...prev, [curr.QuestionId]: false };
        }
      }, {});  
       return { ...ownerCountState, ...ownerShiCountpState };
} 

const InsuranceValuesCount = (values, field) => {
    let countState = Object.keys(values).reduce((prev, curr) => {
        if (InsuranceRequiredFields.includes(curr) && values[curr]) {
            return ({ ...prev, [curr]: true })
        }
        else { 
            if(typeof(values[curr]) == "boolean" && values[curr] != null)
            {
                return ({ ...prev, [curr]: true })  
            }
            return ({ ...prev, [curr]: false })
        } 
    }, {}) 
    if(field === "AuthorizerSignBlob")
    {
        countState[field] = true; 
    }  
  return countState;
}

const PayrollValuesCount = (values, field) => {
  let countState = {};
  if (values.WMS_FacilityPayroll["IsPayrollCompany"] === true) { 
    countState = Object.keys(values.WMS_FacilityPayroll)?.reduce((prev, curr) => {
        if (PayrollRequiredFields.includes(curr) && values.WMS_FacilityPayroll[curr] !== "" && values.WMS_FacilityPayroll[curr] !== null) {
          return { ...prev, [curr]: true };
        } else {
          return { ...prev, [curr]: false };
        }
      },{});
  } else {
    countState = Object.keys(values.WMS_FacilityPayroll)?.reduce((prev, curr) => {
        if (PayrollRequiredFieldsWithoutAccess.includes(curr) && values.WMS_FacilityPayroll[curr] !== "" && values.WMS_FacilityPayroll[curr] !== null ) {
          return { ...prev, [curr]: true };
        } else {
          return { ...prev, [curr]: false };
        }
      },{});
  }
  const peopleAccessCount = values.NumPeopleWithAccess !== "" && values.NumPeopleWithAccess !== undefined   ? { peopleAccessCount: true } : { peopleAccessCount: false };
  const ProfileAnswerCountState = values.FacilityProfileAnswers && values.FacilityProfileAnswers.filter((e) => e.QuestionId && payrollQnIds.includes(e.QuestionId)).reduce((prev, curr, index) => {
      if (curr.Answer) {
        return { ...prev, [curr.QuestionId]: true };
      } else {
        return { ...prev, [curr.QuestionId]: false };
      }
    }, {});
  return { ...countState, ...ProfileAnswerCountState, ...peopleAccessCount };
};

const UpdateDemographicsPercentage  = async(values, field) => {
    const count = await DemographicsValuesCount(field, values);
    const percentage = Object.values(count).filter((e) => e === true).length * (100/DemographicsRequiredFieldCount);
    let copyState = [...values.CompletionPercentage];
    let index = copyState.findIndex( (e) => e && e.Tab === FacilityUserForm.DEMOGRAPHICS);
    if (index != -1) {
      copyState[index].Percentage = percentage;
      values.CompletionPercentage[index] = copyState[index];
    } else {
      values.CompletionPercentage.push({
          Tab: FacilityUserForm.DEMOGRAPHICS,
          Percentage: percentage, 
        });
    }  
    return values;
}

const UpdateServicesPercentage = async(values) => {
    const count = await ServicesValuesCount(values);
    const percentage = Object.values(count).filter((e) => e === true).length * (100/ServicesRequiredFieldCount);
    let copyState = [...values.CompletionPercentage];
    let index = copyState.findIndex( (e) => e && e.Tab === FacilityUserForm.SERVICES);
    if (index != -1) {
      copyState[index].Percentage = percentage;
      values.CompletionPercentage[index] = copyState[index];
    } else {
      values.CompletionPercentage.push({
        Tab: FacilityUserForm.SERVICES,
        Percentage: percentage,
      }) 
    }
    return values;
};

const UpdateOwnershipPercentage  = async(ownership, owners) => {
    const count = await OwnershipValuesCount(ownership, owners);
    const percentage = Object.values(count).filter((e) => e === true).length * (100/OwnershipRequiredFieldCount); 
    let copyState = [...ownership.CompletionPercentage];
    let index = copyState.findIndex( (e) => e && e.Tab === FacilityUserForm.OWNERSHIPS);
    if (index != -1) {
      copyState[index].Percentage = percentage;
      ownership.CompletionPercentage[index] = copyState[index];
    } else {
      ownership.CompletionPercentage.push({
          Tab: FacilityUserForm.OWNERSHIPS,
          Percentage: percentage,
        })
    }
    return ownership;
}

const UpdateInsurancePercentage = async (values, fields) => {
    const count = await InsuranceValuesCount(values, fields);
    const percentage = round((Object.values(count).filter(e => e === true).length * (100 / InsuranceRequiredFieldCount)) || 0, 2)  
    let copyState = [...values.CompletionPercentage];
    let index = copyState.findIndex( (e) => e && e.Tab === FacilityUserForm.INSURANCES);
    if (index != -1) {
      copyState[index].Percentage = percentage;
      values.CompletionPercentage[index] = copyState[index];
    } else {
      values.CompletionPercentage.push({
          Tab: FacilityUserForm.INSURANCES,
          Percentage: percentage, 
        });
    } 
    return values;
}

const UpdatePayrollPercentage = async (values, fields) => {
    const payrollCount = values.WMS_FacilityPayroll['IsPayrollCompany'] == true ? PayrollRequiredFieldCount : PayrollRequiredFieldCountWithoutAcess;
    const count = await PayrollValuesCount(values, fields);
    const percentage = Object.values(count)?.filter((e) => e === true).length * (100 / payrollCount);
    let copyState = [...values.CompletionPercentage];
    let index = copyState.findIndex( (e) => e && e.Tab === FacilityUserForm.PAYROLL);
    if (index != -1) {
      copyState[index].Percentage = percentage;
      values.CompletionPercentage[index] = copyState[index];
    } else {
        values.CompletionPercentage.push({ Tab: FacilityUserForm.PAYROLL, Percentage: percentage, });
    } 
    return values;
}

export { UpdateDemographicsPercentage, UpdateServicesPercentage, UpdateOwnershipPercentage, UpdateInsurancePercentage, UpdatePayrollPercentage }