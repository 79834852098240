import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { onBoarding,getQuestionsServices, getOptions, getDocuments, getProjectFaciltyByPId, getDoctypesExcluded} from '../../../store/facilityProfiling/action';
import { corporateStatus, FacilityUserForm, ProviderLevel, ProviderType, radioAnswerTytpe } from '../../../util/enum';
import moment from 'moment/moment';
import { getStatesName,getFullNameText,formatPdf,
     doctypeByCategory, arrayBufferToBase64,formatFEIN,formatPhoneNumber,formatSSN,formatEIN} from '../../../util/helper';
import _ from 'lodash'; 


const PdfGeneration =(props)=>{
    const {onBoarding,projectDetails,projectFacility,getDoctypesExcluded,getQuestionsServices,getProjectFaciltyByPId,states,getOptions,ProjectId,getDocuments,categories,doctypes, showModal} = props;
    const [values, setvalues] = useState({});
    const [options, setOptions] = useState([]);
    const [documents,setDocuments]= useState([])
    const [facility, setFacility] = useState([])
    const [docCategory, setDocCategory] = useState([]);
    const [signatureURL, setSignatureURL] = useState(false);
    const [doctypesExcluded, setDoctypesExcluded] = useState([]);
    const [mandatoryDocuments, setMandatoryDocuments] = useState([])



 
    useEffect(() => {
        async function fetchData(showModal) {
            if (showModal) {
                const facility= await getProjectFaciltyByPId(ProjectId)
                setFacility(facility)
                
                const questions = await getQuestionsServices({Tab: FacilityUserForm.DOCS})
                facility.data[0].questions = questions;
                const res = await formatPdf(facility.data[0])
                setvalues(res || {});
               
                const result = doctypeByCategory(categories && _.orderBy(categories.data.filter((a)=> a.IsOnboardingDoc === false), [c => c.Title.toLowerCase()], ['asc']), doctypes && doctypes)
                setDocCategory(result) 
                const docs = await getDocuments(ProjectId)
                setDocuments(docs)
                const excludedDoctypes = await getDoctypesExcluded(ProjectId)
        setDoctypesExcluded(excludedDoctypes)
                let signatureURL = facility.data[0].AuthorizerSignBlob && arrayBufferToBase64(facility.data[0].AuthorizerSignBlob, 'image/png');
                signatureURL && setSignatureURL(signatureURL) 
                const options =  await getOptions({QuestionId:{$in:[7,8]}});
                setOptions(options)
            }
        } 
        fetchData(showModal)
    }, [showModal]); 

    useEffect(()=>{
        if(doctypesExcluded && doctypes){ 
          let mandatoryDocuments = doctypes.filter((elem) => !doctypesExcluded.find(({ DocTypeId }) => elem.ID == DocTypeId)); 
          setMandatoryDocuments(mandatoryDocuments)
        }
      },[doctypesExcluded,doctypes])


   const getcorporateStatus =(corporateId)=>{
    switch (corporateId) {
        case corporateStatus.getValueByName('LLC'):
            return 'LLC'
        case corporateStatus.getValueByName('INC'):
            return 'INC'
    }
   }

    const getProviderType = (providerTypeId) => {
        switch (providerTypeId) {
            case ProviderType.getValueByName('Congregate Living Health Facility'):
                return 'Congregate Living Health Facility'
            case ProviderType.getValueByName('Nursing Home (Skilled Nursing Facility)'):
                return 'Nursing Home (Skilled Nursing Facility)'
            case ProviderType.getValueByName('Independent Living Apartments'):
                return 'Independent Living Apartments'
            case ProviderType.getValueByName('Adult Homes'):
                return 'Adult Homes'
            case ProviderType.getValueByName('Enriched Housing'):
                return 'Enriched Housing'
            case ProviderType.getValueByName('Family-Type Homes'):
                return 'Family-Type Homes'
            case ProviderType.getValueByName('Assisted Living Program (ALP)'):
                return 'Assisted Living Program (ALP)'
            case ProviderType.getValueByName('Nursing Home (Skilled Nursing Facility)'):
                return 'Continuing Care Retirement Communities'
        }
   }

   const getProviderLevel =(providerId)=>{ 
    switch (providerId) {
        case ProviderLevel.getValueByName('Subacute Care'):
            return 'Subacute Care'
        case ProviderLevel.getValueByName('Skilled Nursing Facility'):
            return 'Skilled Nursing Facility'
        case ProviderLevel.getValueByName('Intermediate Care/Developmentally Disabled'):
            return 'Intermediate Care/Developmentally Disabled'
        case ProviderLevel.getValueByName('Congregate Living Health Facility'):
                return 'Congregate Living Health Facility'
    }
   }

   const getRadioAsnwers =(type)=>{
    switch (type){
        case radioAnswerTytpe.True:
            return 'Yes'
        case radioAnswerTytpe.False:
            return 'No'
        
    }
   }

   const header = (title) => {
    return (
        <header>
            <div className="container-fluid">
                <div className="row main-header border-0">
                    <div className="col-md-12 head"><h2 className="bg-vio">{title}</h2></div> 
                </div>
            </div>
        </header>
    )
}

const renderDocuments = (docCategory) => {
    return !_.isEmpty(docCategory) && Object.entries(docCategory).map(([k, v], index) => {
      return <div className="finances-data" id={`documents_${index}`}>
        <h5 className='fc-hd'><span>{k}</span></h5>
        {v.map((d, u) => { 
          return <>
          {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length == 0 ? 
            <h4 className='text-left'>{d[0].Title && d[0].Title}</h4>: <h4 className='text-center'>{d[0].Title && d[0].Title}</h4>}
            <div className="tab-fin">
                {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length > 0 && 
              <table className="table fin-table tab-cl">
                <thead className='text-left'>
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Document Name </th>
                    {/* <th scope="col">Uploaded Date </th> */}
                  </tr>
                </thead>
                <tbody className="fin-tb-body text-left">
                  {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).map((doc, i) => {
                    if (documents.filter(u => u.DocTypeId === doc.ID).length) {
                      let up_doc = documents.find(u => u.DocTypeId === doc.ID);
                      return <tr key={i} className=" clr-grey">
                        <td className="wt-preline" style={{color: '#000'}}>{doc.Description}
                        </td>
                        <td className='' style={{color: '#000'}}>{up_doc.DocName.split('.')[0]}</td>
                      </tr>
                    }
                    return <tr key={i}>
                      <td className="wt-preline" style={{color: '#000'}}>{doc.Description}</td>
                      <td className='clr-grey'>No document uploaded</td>
                    </tr>

                  })}
                  {/* {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length == 0 && 
                  <tr>
                    <td></td>
                    <td>No documents are required to be uploaded under this category.</td>
                  </tr>
                  } */}
                </tbody>
              </table>}
              {d.filter((elem) => mandatoryDocuments.find(({ ID }) => elem.ID == ID)).length == 0 && 
                  <tr>
                    <td></td>
                    <td>No documents are required to be uploaded under this category.</td>
                  </tr>
                  }
            </div>
          </>
        })}

      </div>
    })
  }

    return (facility && facility.data &&  !_.isEmpty(facility.data[0]) &&
        <> 
            <header class="pdf-header p-0 mt-0">
                <div class="container-fluid ">
                    <div class="row hd-container">
                        <div class="col-md-12 text-center">
                            <h1>SecondOpinionExpert</h1>
                            <h5>Point-of-Care Services</h5>
                        </div>
                        <div class="d-flex pd-con">
                            <p>Phone : (818) 707-1007</p>
                            <p>Fax : 818-450-5520</p>
                            <p>Email : info@secondopinionexpert.com</p>
                        </div>
                    </div>
                    <div class="row hd-pt">
                        <div class="col-md-6">
                            <div class="pt-name text-left">
                                <p className='d-flex'> <h5 className='ms-1'> <b>FACILITY NAME:</b>  {facility.data[0]?.BusinessName||"--"}</h5></p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {header('Demographics')}
            <div id='maincontent_demographics'>
                <section className="personal-info" id="personalinfo">
                    <div className="ips-content">
                        <div className="container-fluid  p-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Demographic Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Legal Business Name </span>
                                                                {facility.data[0]?.BusinessName || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>NPI # </span>
                                                                {facility.data[0]?.NPI && facility.data[0]?.NPI || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>City</span>
                                                                {facility.data[0]?.FacilityCity || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Fax</span>
                                                                {facility.data[0]?.FacilityFax && formatPhoneNumber(facility.data[0]?.FacilityFax) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Corporate Status</span>
                                                                {getcorporateStatus(facility.data[0]?.CorporateStatus) || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>CDPH LIC # </span>
                                                                {facility.data[0]?.CDPH || "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>State</span>
                                                                {(states && facility.data[0]?.FacilityState &&
                                                                    getStatesName(
                                                                        facility.data[0].FacilityState,
                                                                        states,)) ||'--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Phone Number</span>
                                                                {facility.data[0]?.FacilityPhone && formatPhoneNumber(facility.data[0]?.FacilityPhone) || "--"}
                                                            </h6>
                                                            
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>DBA Name</span>
                                                                {facility.data[0]?.DBAName || "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Expiry Date</span>
                                                                {facility.data[0]?.CDPHExpiryDate ? moment(facility.data[0]?.CDPHExpiryDate).format('MM-DD-YYYY') : '--'}
                                                            </h6>
                                                        <h6 className="ft-14">
                                                            <span>Zip</span>
                                                            {facility.data[0]?.FacilityPincode || '--'}
                                                        </h6>
                                                        <h6 className="ft-14">
                                                            <span>Email</span>
                                                            {facility.data[0]?.FacilityEmail || '--'}
                                                        </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>FEIN #</span>
                                                                {facility.data[0]?.FEIN && formatFEIN(facility.data[0]?.FEIN) || '--'}
                                                            </h6>
                                                            <h6 className="ft-14"><span>Facility Address</span>
                                                                {facility.data[0]?.FacilityAddress || "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Website</span>
                                                                {facility.data[0]?.WebsiteUrl || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Contact Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Name </span>
                                                                {facility.data[0]?.Contact1Name || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Title</span>
                                                                {facility.data[0]?.Contact1Title || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Phone </span>
                                                                {facility.data[0]?.Contact1Mobile && formatPhoneNumber(facility.data[0]?.Contact1Mobile) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>Email</span>
                                                                {facility.data[0]?.Contact1Email || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Case Manager Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm ">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Nurse Name </span>
                                                                {facility.data[0]?.CaseManagerName || "--"}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Phone </span>
                                                                {facility.data[0]?.CaseManagerPhone && formatPhoneNumber(facility.data[0]?.CaseManagerPhone) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Address</span>
                                                                {facility.data[0]?.CaseManagerAddress || "--"}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Fax </span>
                                                                {facility.data[0]?.CaseManagerFax && formatPhoneNumber(facility.data[0]?.CaseManagerFax) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>State </span>
                                                                {(states && facility.data[0]?.CaseManagerState &&
                                                                    getStatesName(
                                                                        facility.data[0]?.CaseManagerState,
                                                                        states,
                                                                    )) ||
                                                                    '--'}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Email </span>
                                                                {facility.data[0]?.CaseManagerEmail || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>Zip</span>
                                                                {facility.data[0]?.CaseManagerPincode || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Billing Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Name </span>
                                                                {facility.data[0]?.BillingName || "--"}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>State</span>
                                                                {(states && facility.data[0]?.BillingState &&
                                                                    getStatesName(
                                                                        facility.data[0]?.BillingState,
                                                                        states,
                                                                    )) ||
                                                                    '--'}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Email</span>
                                                                {facility.data[0]?.BillingEmail || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Address Line 1</span>
                                                                {facility.data[0]?.BillingLine1 || "--"}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Zip </span>
                                                                {facility.data[0]?.BillingPincode || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Address Line 2 </span>
                                                                {facility.data[0]?.BillingLine || "--"}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Phone </span>
                                                                {facility.data[0]?.BillingPhone && formatPhoneNumber(facility.data[0]?.BillingPhone) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>City</span>
                                                                {facility.data[0]?.BillingCity || '--'}
                                                            </h6>
                                                            <h6 className="ft-14"><span>Fax</span>
                                                                {facility.data[0]?.BillingFax && formatPhoneNumber(facility.data[0]?.BillingFax) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Mailing Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Address Line 1 </span>
                                                                {facility.data[0]?.MailingLine1 || "--"}
                                                            </h6>
                                                            <h6 className="ft-14"><span>Zip</span>
                                                                {facility.data[0]?.MailingPincode || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Address Line 2</span>
                                                                {facility.data[0]?.MailingLine2 || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>City </span>
                                                                {facility.data[0]?.MailingCity || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>State</span>
                                                            {(states && facility.data[0]?.MailingState &&
                                                                    getStatesName(
                                                                        facility.data[0]?.MailingState,
                                                                        states,
                                                                    )) ||
                                                                    '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {header('Services')}
            <div id='maincontent_services'>
                <section className="personal-info" id="personalinfo">
                    <div className="ips-content">
                        <div className="container-fluid  p-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Provider Type</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Provider Type </span>
                                                                {getProviderType(facility.data[0]?.ProviderType) || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Provider level of care </span>
                                                                {getProviderLevel(facility.data[0]?.ProviderLevel) || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Accreditation Agency</span>
                                                                {facility.data[0]?.AccreditationAgency || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Date Issued</span>
                                                                {facility.data[0]?.AccreditationIssueDate? moment(facility.data[0]?.AccreditationIssueDate).format('MM-DD-YYYY') : "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>Expiry Date</span>
                                                                {facility.data[0]?.AccreditationExpiryDate ? moment(facility.data[0]?.AccreditationExpiryDate).format('MM-DD-YYYY'): '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Ancillary Services</h5>
                                    <div className="table-responsive-sm">
                                    <table class="pdf-tbl table">
                                        <thead>
                                            <tr><th> Service Type</th>
                                                <th>Provider Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {facility.data[0]?.WMS_AncillaryServices?.map((e) => ((e.ProviderName || e.Type) &&
                                                <tr>
                                                    <td>{e.Type || '--'}</td>
                                                    <td>{e.ProviderName || '--'}</td>
                                                </tr>|| <tr><td colSpan={2}>No details available</td></tr>))}
                                                {_.isEmpty(facility.data[0]?.WMS_AncillaryServices) && <tr><td colSpan={2}>No details available</td></tr>}
                                                
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Medical Director Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span> Name </span>
                                                                {facility.data[0]?.MD1Name || "--"}
                                                            </h6>
                                                            <h6 className="ft-14" >
                                                                <span>Phone </span>
                                                                {facility.data[0]?.MD1Phone && formatPhoneNumber(facility.data[0]?.MD1Phone) || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Type of medicine practice</span>
                                                                {facility.data[0]?.MD1Type || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>FEIN # </span>
                                                                {facility.data[0]?.MD1FEIN && formatFEIN(facility.data[0]?.MD1FEIN) || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>Address</span>
                                                                {facility.data[0]?.MD1address || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Facility Hours</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Special services provided onsite </span>
                                                                {facility.data[0]?.SpecialServiceOnSiteNote || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Special services provided offsite </span>
                                                                {facility.data[0]?.SpecialServiceOffSiteNote || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Facility age range </span>
                                                                {facility.data[0]?.FacilityAgeRange || "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Behavioral Health Servicing Questions</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                            {values && values?.behaviouralQues?.map((q)=>(
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>{q.Text} </span>
                                                                {q?.Answer}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Additional Questions</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                {values && values?.additionalQues && _.map(values.additionalQues,(q)=>(
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span className='mb-2'>{q.Text}</span>     
                                                                {q?.WMS_FacilityAnswerOptions && q.WMS_FacilityAnswerOptions.map((a,i)=>(
                                                                    <>
                                                                    {a.Text && a.Text.replace('?','')+ (i < q?.WMS_FacilityAnswerOptions.length -1 ? ', ' : '') ||'--'}
                                                                    </>
                                                                ))}
                                                                {_.isEmpty(q?.WMS_FacilityAnswerOptions) && '--' } 
                                                              
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {header('Ownership')}
            <div id='maincontent_ownership'>
                <section className="personal-info" id="personalinfo">
                    <div className="ips-content">
                        <div className="container-fluid  p-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Facility Ownership</h5>
                                    <div className="table-responsive-sm">

                                    <table className="pdf-tbl table">
                                        <thead className="">
                                            <tr className="">
                                                <th>Name</th>
                                                <th className='text-left' style={{"textAlign":"left"}}>Phone</th>
                                                <th className='text-center'>Email</th>
                                                <th>Role/Title</th>
                                                <th>Percent</th>
                                            </tr>
                                        </thead>
                                        <tbody className="fnt-s-12">
                                            {facility.data[0]?.WMS_FacilityOwner?.map((e) => (
                                                <tr className="">
                                                    <td className='text-center'>{getFullNameText({ FName: e.FName, LName: e.LName, MName: e.MName }) || '--'}</td>
                                                    <td className='text-left'>{e?.Phone && formatPhoneNumber(e?.Phone) || '--'} </td>
                                                    <td className='text-center'>{e?.Email || '--'}</td>
                                                    <td className='text-center'>{e?.Title || '--'}</td>
                                                    <td className='text-center'>{e?.Percentage+"%" || '--'}</td>
                                                </tr>
                                            ))}
                                            {facility.data[0]?.WMS_FacilityOwner && _.isEmpty(facility.data[0].WMS_FacilityOwner) && (
                                                <tr className="brd-cust" style={{ 'textAlign': 'center' }}>
                                                    <td colspan="6">No details available.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                 </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Additional Entity Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                            {values && values?.additionalInfo?.map((q)=>(
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>{q.Text} </span>
                                                                {getRadioAsnwers(q?.Answer)|| q?.Answer||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">General</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                            {values && values?.generalInfo?.map((q)=>(
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>{q.Text} </span>
                                                                {getRadioAsnwers(q?.Answer)|| q?.Answer||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {header('Insurance')}
            <div id='maincontent_insurance'>
                <section className="personal-info" id="personalinfo">
                    <div className="ips-content">
                        <div className="container-fluid  p-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="fc-hd">General</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current general liability Coverage per occurrence start date</span>
                                                                {facility.data[0]?.CGLOInsuranceStart?moment(facility.data[0]?.CGLOInsuranceStart).format("MM/DD/YYYY") : '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current general liability coverage per occurrence expiration date</span>
                                                                {facility.data[0]?.CGLOInsuranceEnd?moment(facility.data[0]?.CGLOInsuranceEnd).format("MM/DD/YYYY") :'--'}                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current general liability coverage per aggregated start date</span>
                                                                {facility.data[0]?.CGLAInsuranceStart?moment(facility.data[0]?.CGLAInsuranceStart).format("MM/DD/YYYY") :'--'}

                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current general liability coverage per aggregated expiration date</span>
                                                                {facility.data[0]?.CGLAInsuranceEnd?moment(facility.data[0]?.CGLAInsuranceEnd).format("MM/DD/YYYY") :'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>General coverage is renewed </span>
                                                                {facility.data[0]?.IsCGLInsuredAnnually && facility.data[0].IsCGLInsuredAnnually === true ? 'Annually': 'Continuous'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Medical Liability</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current medical liability coverage per occurrence start date</span>
                                                                {moment(facility.data[0]?.MLOInsuredStart).format('MM-DD-YYYY') ||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current medical liability coverage per occurrence expiration date</span>
                                                                {moment(facility.data[0]?.MLOInsuranceEnd).format('MM-DD-YYYY')||'--'}                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current medical liability coverage per aggregated start date</span>
                                                                {moment(facility.data[0]?.MLAInsuranceStart).format('MM-DD-YYYY') ||'--'}

                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current medical liability coverage per aggregated expiration date</span>
                                                                {moment(facility.data[0]?.MLAInsuranceEnd).format('MM-DD-YYYY')||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>General coverage is renewed </span>
                                                                {facility.data[0]?.IsCMLInsuredAnnually && facility.data[0].IsCMLInsuredAnnually === true ? 'Annually': 'Continuous'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Liability</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current liability coverage per occurrence start date</span>
                                                                {moment(facility.data[0]?.CLOInsuranceStart).format('MM-DD-YYYY')||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current liability coverage per occurrence expiration date</span>
                                                                {moment(facility.data[0]?.CLOInsuranceEnd).format('MM-DD-YYYY')||'--'}                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current liability coverage per aggregated start date</span>
                                                                {moment(facility.data[0]?.CLAInsuranceStart).format('MM-DD-YYYY')||'--'}

                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Current liability coverage per aggregated expiration date</span>
                                                                {moment(facility.data[0]?.CLAInsuranceEnd).format('MM-DD-YYYY')||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>General coverage is renewed </span>
                                                                {facility.data[0]?.IsCLInsuredAnnually && facility.data[0].IsCLInsuredAnnually === true ? 'Annually': 'Continuous'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Malpractice Carrier Name</span>
                                                                {facility.data[0]?.MalpracticeCarrierName && facility.data[0].MalpracticeCarrierName || '--'}
                                                              
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Are physicians, practitioners and professional clinicians covered under the malpractice insurance?</span>
                                                                {facility.data[0]?.ISMalpracticeInsured === true? 'Yes': 'No'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">I Certify</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <p className='text-left'>I understand the effective date of participation is the date the application is actually approved by <span className='fw-bold'>{facility.data[0]?.CertifyField1} {' '}</span>
                                                        and is not the date the application was submitted or received.<br/></p>
                                                        <p className='text-left'>The information contained in this application is complete and accurate.</p>

                                                       <p className='text-left'> I understand that <span className='fw-bold'>{facility.data[0]?.CertifyField2}{' '} </span> may do an on‐site survey after review of this application 
                                                        to verify program compliance and to verify the accuracy of any information provided.</p>

                                                       <p className='text-left'> I understand the facility is not eligible to submit claims for payment until it is approved by <span className='fw-bold'>{facility.data[0]?.CertifyField3}{' '}</span>,{' '}
                                                        both parties sign the agreement(s), and the processing systems are updated.</p>

                                                       <p className='text-left'> I will notify <span className='fw-bold'>{facility.data[0]?.CertifyField4}{' '} </span>immediately in writing of changes affecting this data.</p>

                                                       <p className='text-left'> The authorized signer agrees that he/she has the company's designated authority to request and maintain minimum necessary Web access and is responsible for complying with all terms and conditions contained within the Provider Secured Service Use and Protection Agreement. </p>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table">
                                            <tbody className="fnt-s-12">
                                            <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Authoriser Name</span>
                                                                {facility.data[0]?.AuthorizerName||'--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Authoriser Title</span>
                                                                {facility.data[0]?.AuthorizerTitle||'--'}                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Authorised Date</span>
                                                                {moment(facility.data[0]?.AuthorizerSignedAt).format('MM-DD-YYYY')||'--'}                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Signature</span>
                                                               {signatureURL &&  <img src={signatureURL} className='esignImg mt-0' /> }
                                                             </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {header('Payroll')}
            <div id='maincontent_payroll'>
                <section className="personal-info" id="personalinfo">
                    <div className="ips-content">
                        <div className="container-fluid  p-2" >
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Owner's Info</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Name </span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && getFullNameText({ FName: facility.data[0].WMS_FacilityPayroll[0]?.FName, 
                                                                    LName: facility.data[0]?.WMS_FacilityPayroll[0]?.LName, MName: facility.data[0].WMS_FacilityPayroll[0]?.MName }) || '--'}                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Address Line 1</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.Line1 || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Email</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.Email || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Date of birth</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && moment(facility.data[0]?.WMS_FacilityPayroll[0]?.Dob).format('MM-DD-YYYY') || "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Address Line 2</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.Line2 || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Phone</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && formatPhoneNumber(facility.data[0]?.WMS_FacilityPayroll[0]?.Phone)|| "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>City</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.City|| "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>SSN #</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && formatSSN(facility.data[0]?.WMS_FacilityPayroll[0]?.SSN)|| '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>State</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && (states && facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.State &&
                                                                    getStatesName(
                                                                        facility.data[0]?.WMS_FacilityPayroll[0]?.State,
                                                                        states,
                                                                    )) ||
                                                                    '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Company Info</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Company legal name </span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.CompanyName|| '--'}                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>EIN #</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && formatEIN(facility.data[0]?.WMS_FacilityPayroll[0]?.EIN) || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Number of people that will need access</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.NumPeopleWithAccess || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Address Line 1</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.CompanyLine1|| "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>NAICS </span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.NICSCode || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Address Line 2</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.CompanyLine2|| "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>EDD</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.EDD|| "--"}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14"><span>City</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.CompanyCity|| '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>State</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && (states && facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.CompanyState &&
                                                                    getStatesName(
                                                                        facility.data[0]?.WMS_FacilityPayroll[0]?.CompanyState,
                                                                        states,
                                                                    )) ||
                                                                    '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Finances</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                                <tr>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14" >
                                                                <span>Bank name </span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.BankName|| '--'}                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Please check the current pay frequency</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.PayFrequency || '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Which holidays are considered for giving holiday pay?</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.HolidayPayDay || '--'}
                                                            </h6>
                                                            {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.IsPayrollCompany === true &&
                                                            <h6 className="ft-14">
                                                                <span>Password</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.Password|| "--"}
                                                            </h6>}
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Routing Number</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.BankRouteNum|| "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>What is your pay date?</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.PayDates ? facility.data[0]?.WMS_FacilityPayroll[0]?.PayDates : '--'}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>Do you currently have a payroll company?  </span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.IsPayrollCompany === true? 'Yes': 'No' || '--'}
                                                            </h6>
                                                        </div>
                                                    </td>
                                                    <td style={{ "width": "10%" }}>
                                                        <div className="data">
                                                            <h6 className="ft-14">
                                                                <span>Account Number</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.BankAccNum|| "--"}
                                                            </h6>
                                                            <h6 className="ft-14">
                                                                <span>What is your holiday pay?</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.PaymentAmt|| "--"}
                                                            </h6>
                                                            {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.IsPayrollCompany === true &&
                                                            <h6 className="ft-14">
                                                                <span>Login</span>
                                                                {facility.data[0]?.WMS_FacilityPayroll[0] && facility.data[0]?.WMS_FacilityPayroll[0]?.Login|| "--"}
                                                            </h6>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <h5 className="fc-hd">Additional Information</h5>
                                    <div className="tablewrap table-responsive-sm">
                                        <table className="table br-btm">
                                            <tbody className="fnt-s-12">
                                               
                                                    <tr>
                                                        <td style={{ "width": "10%" }}>
                                                            <div className="data">
                                                            {values && values?.paymentAdditionalInfo && _.map(values.paymentAdditionalInfo,(q) => (
                                                                <h6 className="ft-14" >
                                                                    <span>{q.Text} </span>
                                                                    {getRadioAsnwers(q.Answer) || '--'}
                                                                </h6>))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {header('Documents')}
            <div id='maincontent_documents'>
                <section className="personal-info" id="personalinfo">
                    <div className="ips-content">
                        <div className="container-fluid  p-2" >
                            <div className="row">
                                <div className="col-md-12">
                                {renderDocuments(docCategory)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )

}

const mapStateToProps = state => ({
    projectDetails: state.facility && state.facility.projectDetails || null,
    // projectFacility : state.facility && state.facility.projectDetails && state.facility.projectDetails.WMS_ProjectFacility[0],
    states: state.facility && state.facility.states,
    doctypes: state.facility && state.facility.doc_types,
    categories: state.facility && state.facility.doc_category
});
const mapDispatchToProps = {
    onBoarding,
    getQuestionsServices,
    getOptions,
    getDocuments,
    getProjectFaciltyByPId,
    getDoctypesExcluded
};
export default connect(mapStateToProps, mapDispatchToProps)(PdfGeneration);