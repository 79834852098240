import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import img from '../../../../assets/img';
import { logout } from '../../../../store/auth/action';
import { connect } from 'react-redux';
import { Icon } from '@iconify/react';
import Header from '../../../../layouts/Header';


const ThankYou = (props) => {
    const { logout } = props
    const navigate = useNavigate();

    const logOut = async () => {
        await logout();
        navigate("/login");
    }

    return (
        <div>
            <div className="doodle-data2"><img src={img.doodle2} /></div>
            <section className="header-section">
                <div className="container-fluid cst-fluid">
                    <div className="main-header-form-fill">
                        <Header showDashboardRedirection={true} />
                    </div>
                </div>
            </section>
            <section className="frm-maincontent" style={{ marginTop: "60px" }}>
                <div className="doodle-data"><img src={img.formBg} /></div>
                <div className="container">
                    <div className="row" >
                        <div className="form-main" style={{ padding: "30px"}}>
                            <div className="thks-data">
                                <img src={img.clap.default} />
                                <div className="text-center" >
                                    <h1>Thanks for your interest in becoming a SecondOpinionExpert, Inc. Point-of-Care Services network provider.</h1>
                                    <h5>We will review and respond within 7 – 10 business days.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </div>

    )
}

const mapDispatchToProps = { logout };
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
