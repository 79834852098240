
import _ from "lodash";
import { FacilityUserForm } from './enum';
import moment from 'moment'

export const commaSeperatedString = (diagnosticstests) => {
  //Array of objects
  return diagnosticstests.map((d) => {
    return d?.name;
  });
};
export const filterDuplicates = (array, areEqual) => {
  return array.filter((item, pos) => {
    return array.findIndex((other) => areEqual(item, other)) === pos;
  });
};

export const getStatesName = (stateId, states) => {
  if (states && _.isArray(states) && stateId) {
    let stateObj = _.find(states, e => e.ID == stateId);
    if (stateObj && _.isObject(stateObj)) {
      return stateObj.Name || '';
    }
    return '';
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  const phoneNumber = phoneNumberString?.replace(/[^0-9{10}]/g, '');
  if (phoneNumber?.length <= 3) {
    return `(${phoneNumber}`;
  } else if (phoneNumber?.length <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else if (phoneNumber?.length > 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};
export const formatFEIN = (FEINString) => {
  const FEINNumber = FEINString?.replace(/[^0-9{9}]/g, '')
  if (FEINNumber?.length <= 2) {
    return `${FEINNumber}`;
  } else if (FEINNumber?.length > 2) {
    return `${FEINNumber.slice(0, 2)}-${FEINNumber.slice(2, 9)}`;
  }
};
export const formatSSN = (SSNString) => {
  const SSNNumber = SSNString?.replace(/[^0-9{9}]/g, '')
  if (SSNNumber?.length <= 3) {
    return `${SSNNumber}`;
  } else if (SSNNumber?.length <= 5) {
    return `${SSNNumber.slice(0, 3)}-${SSNNumber.slice(3)}`;
  } else if (SSNNumber?.length > 5) {
    return `${SSNNumber.slice(0, 3)}-${SSNNumber.slice(3, 5)}-${SSNNumber.slice(5, 9)}`;
  }
};
export const formatAccountNumber = (value) => {
  if (value?.length === 12) {
    var cleaned = ("" + value)?.replace(/\D/g, "");
    var match = cleaned.match(/^(\d{8})(\d{4})$/);
    if (match) {

      return [match[1], match[2]].join("");
    }
  }
  return value;
};
export const formatRoutingNumber = (value) => {
  if (value?.length === 9) {
    var cleaned = ("" + value)?.replace(/\D/g, "");
    var match = cleaned.match(/^(\d{5})(\d{4})$/);
    if (match) {
      return [match[1], match[2]].join("");
    }
  }
  return value;
};
export const formatEIN = (EINString) => {
  const EINNumber = EINString?.replace(/[^0-9{9}]/g, '')
  if (EINNumber?.length <= 2) {
    return `${EINNumber}`;
  } else if (EINNumber?.length > 2) {
    return `${EINNumber.slice(0, 2)}-${EINNumber.slice(2, 9)}`;
  }
};
export const formatCDPHLicenseNumber = (licenseNumber) => {
  if (licenseNumber?.length === 11) {
    const cleaned = ("" + licenseNumber)?.replace(/\W/g, "");
    const regex = /^([a-zA-Z]{2})(\d{4})([a-zA-Z0-9]{5})$/;
    const match = cleaned.match(regex);
    if (match) {
      return [match[1], match[2], match[3]].join("-");
    }
  }
  return licenseNumber;
};
export const AcceptCDPH = (value) => {
  const alphanumericRegex = /^([0-9]{0,9})$/;
  return alphanumericRegex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptNaicsEdd = (value) => {
  const alphanumericRegex = /^([a-zA-Z0-9]{0,30})$/;
  return alphanumericRegex.test(value) ? value : null;
}
export const AcceptAccountNumber = (value) => {
  const regex = /^[0-9]{0,12}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptRoutingNumber = (value) => {
  const regex = /^[0-9]{0,9}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptFEIN = (value) => {
  const regex = /^[0-9]{0,9}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptEINNumbers = (value) => {
  const regex = /^[0-9]{0,9}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptPhoneNumbers = (value) => {
  const regex = /^[0-9]{0,10}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptSSNNumbers = (value) => {
  const regex = /^[0-9]{0,9}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptFEINNumbers = (value) => {
  const regex = /^[0-9]{0,9}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptAgeNumbers = (value) => {
  const regex = /^[0-9]{0,3}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const AcceptZipNumbers = (value) => {
  const regex = /^[0-9]{0,5}$/;
  return regex.test(value) ? value : value?.replace(/\D/g, '');
}
export const getDateFromISOString = (isoString, daysToAdd) => {
  const date = new Date(isoString);
  date.setDate(date.getDate() + daysToAdd);
  const year = date.getUTCFullYear();
  const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + date.getUTCDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
export const getDateFromDateString = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
export const getDaysBetweenDates = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffTime = date2.getTime() - date1.getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  return diffDays ? diffDays : 0;
}
export const calculateAge = (date) => {
  const today = new Date().getTime();
  const birthday = new Date(date).getTime();
  const age_mili = Math.abs(today - birthday);

  let days = Math.floor(age_mili / (1000 * 3600 * 24));
  let years = Math.floor(days / 365);
  days -= years * 365;
  let months = Math.floor(days / 31);
  days -= months * 31;
  return `${years} yrs`;
};
export function getGender(key) {
  switch (key) {
    case 1:
      return "Male";
    case 2:
      return "Female";
    case 3:
      return "Others";
    default:
      return "";
  }
}
export function getFullAddress(address, state) {
  const stateName =
    (_.isArray(state) &&
      _.find(
        state,
        (e) => e.id === (Number(address?.state))
      )) ||
    {};
  const fulladdress = [
    address?.line1,
    address?.line2,
    address?.city,
    stateName.name,
    address?.zip,
  ];
  return fulladdress.filter(Boolean).join(", ");
}
export const validateEmail = (email) => {
  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  return regex.test(email)
};
export const sortAlbhabetical = (a, b) => {
  // converting to uppercase to have case-insensitive comparison
  const name1 = a.label.toUpperCase();
  const name2 = b.label.toUpperCase();

  let comparison = 0;

  if (name1 > name2) {
    comparison = 1;
  } else if (name1 < name2) {
    comparison = -1;
  }
  return comparison;
}
export const getFullNameText = ({ FName = '', LName = '', MName = '' }) => {
  if (FName || LName || MName) return `${FName ? FName.charAt(0).toUpperCase() + FName.slice(1).toLowerCase() : ""} ${MName ? MName.charAt(0).toUpperCase() + MName.slice(1).toLowerCase() : ""} ${LName ? LName.charAt(0).toUpperCase() + LName.slice(1).toLowerCase() : ""}`
};
export const getDateasObject = (date) => {
  return { day: new Date(date).getDate(), month: new Date(date).getMonth() + 1, year: new Date(date).getFullYear() }
}
export const objectToDate = (date) => {
  return `${date.day.toString().padStart(2, "0")}/${date.month.toString().padStart(2, "0")}/${date.year.toString()}`
}
export const truncate = (input) => input && input?.length > 24 ? `${input.substring(0, 24)}...` : input;
export const loadYears = () => {
  let yearList = [];
  let currentYear = new Date().getFullYear();
  let earliestYear = 1970;
  while (currentYear >= earliestYear) {
    let yearoption = {};
    yearoption.label = currentYear;
    yearoption.value = currentYear;
    yearList.push(yearoption);
    currentYear -= 1;
  }
  return yearList;
}
export const adjustForTimezone = (date, adjustTimeForTask) => {
  if (date && typeof date === "boolean") {
    return null;
  }
  if (date && typeof date === "object") {
    if (adjustTimeForTask) {
      date.setHours("23");
      date.setMinutes("59");
      date.setSeconds("59");
    }
    else {
      date.setHours("17");
      date.setMinutes("30");
      date.setSeconds("00");
    }
  }
  return date
}
export const DateasObject = date => {
  if (date)
    return {
      day: new Date(date).getDate(),
      month: new Date(date).getMonth() + 1,
      year: new Date(date).getFullYear(),
    };
  return ''
};
export const formatDemographics = (data = {}) => {
  if (data && !_.isEmpty(data)) { 
    return _.pick({
      ...data,
      Tab: FacilityUserForm.DEMOGRAPHICS, 
    }, ['CDPHExpiryDate',
      'ProjectFacilityId',
      'BusinessName',
      'DBAName',
      'CorporateStatus',
      'FEIN',
      'NPI',
      'CDPH',
      'FacilityAddress',
      'FacilityCity',
      'FacilityEmail',
      'FacilityState',
      'FacilityPincode',
      'FacilityFax',
      'WebsiteUrl',
      'FacilityPhone',
      'Contact1Name',
      'Contact1Title',
      'Contact1Mobile',
      'Contact1Email',
      'Contact2Name',
      'Contact2Title',
      'Contact2Mobile',
      'Contact2Email',
      'CaseManagerName',
      'CaseManagerAddress',
      'CaseManagerState',
      'CaseManagerPincode',
      'CaseManagerPhone',
      'CaseManagerFax',
      'CaseManagerEmail',
      'MailingLine1',
      'MailingLine2',
      'MailingSuit',
      'MailingState',
      'MailingCity',
      'MailingPincode',
      'Tab',
      'BillingName',
      'BillingLine1',
      'BillingLine2',
      'BillingCity',
      'BillingState',
      'BillingPincode',
      'BillingFax',
      'BillingEmail',
      'BillingPhone',
      'ProjectId',
    ])
  } else {
    return { Tab: FacilityUserForm.DEMOGRAPHICS, ProjectId: data?.ID };
  }
}
export const formatDemographicsMandatory = (data = {}) => {
  if (data && !_.isEmpty(data)) {
    return _.pick({
      ...data,
      Tab: FacilityUserForm.DEMOGRAPHICS,
    }, ['CDPHExpiryDate',
      'BusinessName',
      'CorporateStatus',
      'FEIN',
      'NPI',
      'CDPH',
      'FacilityAddress',
      'FacilityCity',
      'FacilityEmail',
      'FacilityState',
      'FacilityFax',
      'FacilityPincode',
      'FacilityPhone',
      'Contact1Name',
      'Contact1Title',
      'Contact1Mobile',
      'Contact1Email',
      'MailingLine1',
      'MailingState',
      'MailingCity',
      'MailingPincode',
    ])
  } else {
    return { Tab: FacilityUserForm.DEMOGRAPHICS };
  }
}
export const formatServices = (data = {}) => {
  if (data && !_.isEmpty(data)) {
    return _.pick({
      ...data,
      Tab: FacilityUserForm.SERVICES,
      FacilityProfileAnswers: data.WMS_FacilityProfileAnswers || [],
      AncillaryServices: data.WMS_AncillaryServices || []
    }, [
      'ProviderType',
      'AccreditationAgency',
      'AccreditationIssueDate',
      'AccreditationExpiryDate',
      'ProviderLevel',
      'MD1Name',
      'MD1Type',
      'MD1Phone',
      'MD1address',
      'MD1FEIN',
      'MD2Name',
      'MD2Type',
      'MD2Phone',
      'MD2address',
      'MD3Name',
      'MD3Type',
      'MD3Phone',
      'MD3address',
      'MD3FEIN',
      'SpecialServiceOffSiteNote',
      'SpecialServiceOnSiteNote',
      'BehavioralQuestions',
      'AdditionalQuestions',
      'AncillaryServices',
      'Tab',
      'FacilityProfileAnswers',
      'FacilityAgeRange',
      'ProjectId',
    ]);
  } else {
    return { Tab: FacilityUserForm.SERVICES, ProjectId: data.ID, };
  }

}
export const formatServicesMandatory = (data = {}) => {
  if (data && !_.isEmpty(data)) {
    return _.pick({
      ...data,
      Tab: FacilityUserForm.SERVICES,
      FacilityProfileAnswers: data.WMS_FacilityProfileAnswers || [],
      AncillaryServices: data.WMS_AncillaryServices || [],
    }, [
      'ProviderType',
      'AccreditationAgency',
      'AccreditationIssueDate',
      'AccreditationExpiryDate',
      'ProviderLevel',
      'MD1Name',
      'MD1Type',
      'MD1Phone',
      'MD1address',
      'MD1FEIN',
      'SpecialServiceOffSiteNote',
      'SpecialServiceOnSiteNote',
      'FacilityProfileAnswers',
      'FacilityAgeRange'
    ]);
  } else {
    return { Tab: FacilityUserForm.SERVICES };
  }

}
export const formatOwnerShip = (data = {}) => { 
  if (data && !_.isEmpty(data)) {
    return _.pick({
      ...data,
      Tab: FacilityUserForm?.OWNERSHIPS, 
      OwnerShipAnswers: (data?.WMS_FacilityProfileAnswers) || [],
    }, [
      'Tab',
      'OwnerShipAnswers', 
    ])
  } else {
    return { Tab: FacilityUserForm.OWNERSHIPS };
  };
}
export const fetchFacilityOwners = (data = {}) => {
  if (data && !_.isEmpty(data)) {
    return _.pick({
      FacilityOwners: (data?.WMS_FacilityOwner) || [],
    }, [
      'FacilityOwners'
    ])
  } else {
    return { Tab: FacilityUserForm.OWNERSHIPS };
  };
}
export const doctypeByCategory = (doccategory, doctype) => {
  let types = {};
  doccategory && doccategory.map(cat => {
    return _(doctype.filter(dt => dt.DocCategoryId === cat.ID))
      .groupBy('Title')
      .forOwn(function (v, k) {
        if (!types.hasOwnProperty(cat.Title)) {
          types[cat.Title] = [_.orderBy(v, [c => c.Description.toLowerCase()], ['asc'])];
        } else {
          types[cat.Title].push(_.orderBy(v, [c => c.Description.toLowerCase()], ['asc']))
        }
        return types
      });
  })
  return types
}
export const formatInsurance = (data = {}) => { 
  if (data && !_.isEmpty(data)) {
    return _.pick({
      ...data,
      Tab: FacilityUserForm.INSURANCES,   
    }, [
      'CGLOInsuranceStart',
      'CGLOInsuranceEnd',
      'CGLAInsuranceStart',
      'CGLAInsuranceEnd',
      'MLOInsuredStart',
      'MLOInsuranceEnd',
      'MLAInsuranceStart',
      'MLAInsuranceEnd',
      'CLOInsuranceStart',
      'CLOInsuranceEnd',
      'CLAInsuranceStart',
      'CLAInsuranceEnd',
      'IsCGLInsuredAnnually',
      'IsCMLInsuredAnnually',
      'IsCLInsuredAnnually',
      'MalpracticeCarrierName',
      'ISMalpracticeInsured',
      'CertifyField1',
      'CertifyField2',
      'CertifyField3',
      'CertifyField4',
      'AuthorizerName',
      'AuthorizerSignBlob',
      'AuthorizerSignedAt',
      'AuthorizerTitle',
      'Tab',
      'ProjectId',

    ]);
  } else {
    return { Tab: FacilityUserForm.INSURANCES };
  } 
}
export const formatPayroll = (data = {}) => {
  if (data && !_.isEmpty(data?.WMS_FacilityPayroll)) {
    return _.pick({
      ...data?.WMS_FacilityPayroll[0],
      OwnerName: getFullNameText(data?.WMS_FacilityPayroll[0]) || '',
    }, [
      'ID',
      'OwnerName',
      'BankName',
      'BankRouteNum',
      'BankAccNum',
      'PayFrequency',
      'PayDates',
      'PaymentAmt',
      'HolidayPayDay',
      'IsPayrollCompany',
      'Login',
      'Password',
      'FName',
      'MName',
      'LName',
      'Dob',
      'Phone',
      'SSN',
      'Line1',
      'Line2',
      'Town',
      'City',
      'State',
      'Email',
      'CompanyName',
      'CompanyLine1',
      'CompanyLine2',
      'CompanyTown',
      'CompanyCity',
      'CompanyState',
      'EIN',
      'EDD',
      'NICSCode',
      'PaymentAmt'
    ]); 
  } else {
    return { Tab: FacilityUserForm.PAYROLL };
  } 
} 
export const formatFacilityAccess = (data = {}) => {
  if (data && !_.isEmpty(data?.WMS_FacilityAccess)) {
    return data?.WMS_FacilityAccess[0]
  }
}
export const formatProjectFacility = (data = {}) => {
  if (data && !_.isEmpty(data)) { 
    return _.pick({
      ...data,
      Tab: FacilityUserForm.PAYROLL,  
      FacilityProfileAnswers: (data?.WMS_FacilityProfileAnswers) || [],
    }, [
      'NumPeopleWithAccess',
      'Tab',
      'FacilityProfileAnswers'
    ]);
  } else {
    return { Tab: FacilityUserForm.PAYROLL };

  }
}

export const formatPdf = (data = {}) => { 
  if (data && !_.isEmpty(data)) {
    let facility = ((data) || {})
    return _.pick({
      ...facility,
      ProjectFacilityId: facility.ID || null,
      FacilityProfileAnswers: facility.WMS_FacilityProfileAnswers || [],
      AncillaryServices:facility.WMS_AncillaryServices || [],
      behaviouralQues : data && data?.questions.filter((i)=> [1,2,3,4,5,6].includes(i.ID)).map(q =>q && ({...q,...(_.find(data.WMS_FacilityProfileAnswers,a => a.QuestionId === q.ID)||{})})),
      additionalQues : data && data?.questions.filter((i)=> [7,8].includes(i.ID)).map(q =>q && ({...q,WMS_FacilityAnswerOptions: (q.WMS_FacilityAnswerOptions
        && q.WMS_FacilityAnswerOptions.length
        && q.WMS_FacilityAnswerOptions.map(e1 => ({
          ...e1,
          ...(data.WMS_FacilityProfileAnswers && (data.WMS_FacilityProfileAnswers.find(a => a.IsOption && a.OptionId === e1.ID) || {}) || { Answer: null })

        })).filter(e3 => e3.OptionId) || [])})),
      additionalInfo :data && data?.questions.filter((i)=> [9,10,11,12,13,14].includes(i.ID)).map(q =>q && ({...q,...(_.find(data.WMS_FacilityProfileAnswers,a => a.QuestionId === q.ID)||{})})),
      generalInfo : data && data?.questions.filter((i)=> [15,16,17,18,19,20].includes(i.ID)).map(q =>q && ({...q,...(_.find(data.WMS_FacilityProfileAnswers,a => a.QuestionId === q.ID)||{})})),
      paymentAdditionalInfo : data && data?.questions.filter((i)=> [21,22,23,24,25].includes(i.ID)).map(q =>q && ({...q,...(_.find(data.WMS_FacilityProfileAnswers,a => a.QuestionId === q.ID)||{})})),
    }, [
      'behaviouralQues',
      'additionalQues',
      'additionalInfo',
      'generalInfo',
      'paymentAdditionalInfo',
      'administrativeDocs',
      'clinicalDocs',
      'corporateDocs',
      'financesDocs',
      'hrDocs',
      'payrollDocs',
    ]);
  } else {
    return{Tab: FacilityUserForm.DOCS};
  } 
}


export function b64toBlob(b64Data, name = 'e-signaure.png', contentType = '', sliceSize = 512) {
  if (b64Data.indexOf('image/png') > -1) {
    contentType = 'image/png';
  } else if (b64Data.indexOf('image/jpeg') > -1) {
    contentType = 'image/jpeg';
    name = 'avatar.jpeg';
  }

  const startIndex = b64Data.indexOf('base64,') + 7;

  if (startIndex > -1) {
    b64Data = b64Data.substr(startIndex);
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters?.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice?.length);
    for (let i = 0; i < slice?.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  blob.filename = name;
  return blob;
}
export function arrayBufferToBase64(blob, type) {
  var arrayBufferView = new Uint8Array(blob);
  var blob = new Blob([arrayBufferView], { type: type });
  return URL.createObjectURL(blob);
};
export const splitName = (name) => {
  if(name)
  {
    let arr = name.trim().split(/\s+/)
    let FName = ''
    let LName = ''
    let MName = ''
    if (Array.isArray(arr) && arr?.length === 1) {
      FName = arr[0]
    }
    if (Array.isArray(arr) && arr?.length === 2) {
      FName = arr[0]
      LName = arr[1]
    }
    if (Array.isArray(arr) && arr?.length === 3) {
      FName = arr[0]
      LName = arr[2]
      MName = arr[1]
    }
    if (Array.isArray(arr) && arr?.length > 3) {
      FName = arr[0]
      MName = arr[1]
      LName = arr.filter((_, i) => i > 1).join(' ')
    }
    return { FName, MName, LName }
  }
return ''
}
export const dateTimeFormat = (date) => {
  if (date) {
    if (new Date(date).toString() === 'Invalid Date') {
      return moment(date, 'MMM DD YYYY h:mm A').toDate()
    }
    else return new Date(date)
  }
  return null;
}
export const  round = (num, decimalPlaces = 0) => {
  if (num < 0)
      return -round(-num, decimalPlaces);
  var p = Math.pow(10, decimalPlaces);
  var n = (num * p).toPrecision(15);
  return Math.round(n) / p;
}
